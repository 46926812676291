<template>
  <div v-if="!disabledAll">
    <v-card-title class="text-h5">
      {{ $t("set admin for channel") }} "{{ channel.name }}"
    </v-card-title>
    <v-card-text>
      <h3 class="mt-4 mb-2">{{ $t("current admins") }}</h3>
      <p v-if="!admins.length">{{ $t("no admin") }}</p>
      <v-simple-table v-else dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t("name") }}
              </th>
              <th class="text-left">
                {{ $t("email") }}
              </th>
              <th class="text-left">
                {{ $t('role') }}
              </th>
              <th class="text-left">
                {{ $t("status") }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in admins" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.role }}</td>
              <td>
                <v-chip small>{{ item.status }}</v-chip>
              </td>
              <td>
                <div class="d-flex justify-right">
                  <v-btn
                    class="mr-2"
                    outlined
                    x-small
                    color="error"
                    :disabled="loading || item.role === 'admin'"
                    @click="handleRevoke(item)"
                    >revoke</v-btn
                  >
                  <v-btn
                    outlined
                    x-small
                    v-if="item.status !== 'approved'"
                    :disabled="loading"
                    @click="handleResend(item)"
                    >re-send</v-btn
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <h3 class="mt-4 mb-4">{{ $t("set a new admin") }}</h3>
      <v-combobox
        dense
        v-model="pickedUser"
        :items="users"
        :search-input.sync="searchEmail"
        :label="$t('email')"
        outlined
        small-chips
        clearable
        return-object
        item-text="email"
        item-value="id"
        deletable-chips
        :hint="hint"
        persistent-hint
      />
      <v-radio-group v-model="role" :label="$t('role')" :style="{ 'text-align': $store.getters.getLanguage == 'he' ? 'right' : 'left' }">
        <v-radio :label="$t('admin')" value="admin" />
        <v-radio :label="$t('editor')" value="editor" />
      </v-radio-group>
      <p class="caption">
        {{ $t('editor explaination') }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="handleSetAdmin"
        :disabled="loading || !pickedUser"
        >{{ $t('confirm') }}</v-btn
      >
    </v-card-actions>
  </div>
</template>
  
<script>
import api from "@/api/api";

export default {
  data() {
    return {
      channel: {},
      loading: false,
      pickedUser: null,
      searchEmail: "",
      admins: [],
      users: [],
      debounce: null,
      disabledAll: false,
      role: 'editor',
    };
  },

  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.initLoad();
      }
    },
    searchEmail(val) {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.searchUsers(val);
      }, 1000);
    },
  },

  computed: {
    hint() {
      if (!this.pickedUser) {
        return "";
      }
      return typeof this.pickedUser === "string"
        ? this.$t("generate signup link")
        : this.$t("appoint admin");
    },
  },

  mounted() {
    this.initLoad();
  },

  methods: {
    async initData() {
      const channelId = this.$route.params.channel_id;
      if (!channelId) {
        return false;
      }
      await this.$store.dispatch("FETCH_CHANNEL");
      const channels = this.$store.getters.getChannels;
      const permission = channels.find(
        (channel) => channel.channel_id === Number(channelId)
      );
      if (!permission) {
        return false;
      }
      if (permission.role !== 'admin') {
        return false;
      }
      this.channel = permission.channel;
      return true;
    },
    async initLoad() {
      this.disabledAll = false;
      this.pickedUser = null;
      if (!(await this.initData())) {
        this.disabledAll = true;
        this.$router.replace({ path: `/channels/${this.$route.params.channel_id}/dashboard` });
        return;
      }
      this.loading = true;
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .getChannelAdmins(this.channel.id)
        .then((res) => {
          if (res.data) {
            const { admins, invitees } = res.data;
            this.admins = [
              ...admins.map((item) => ({
                ...item.user,
                role: item.role,
                status: "approved",
              })),
              ...invitees.map((item) => ({
                ...item,
                invitation_id: item.id,
                shortcode: item.shortcode,
                status: "sent invitation",
              })),
            ];
          } else {
            this.admins = [];
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
    handleSetAdmin() {
      this.loading = true;
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      if (typeof this.pickedUser === "string") {
        api
          .generateSignupLink(this.channel.id, this.pickedUser, this.role)
          .then((res) => {
            console.log(res.data.shortcode);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.initLoad();
          });
      } else {
        api
          .appointAdmin(this.channel.id, this.pickedUser.id, this.role)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.initLoad();
          });
      }
    },
    handleRevoke(item) {
      this.loading = true;
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      if (item.invitation_id) {
        api
          .revokeInvitation(item.invitation_id)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.initLoad();
          });
      } else {
        api
          .revokeAdmin(this.channel.id, item.id)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.initLoad();
          });
      }
    },
    searchUsers(val) {
      this.loading = true;
      const params = {
        limit: 20,
        offset: 0,
        email: val,
      };
      api
        .getUsers(params)
        .then((res) => {
          if (res.data && res.data.users.length > 0) {
            const adminIds = this.admins.map((admin) => admin.id);
            this.users = res.data.users.filter(
              (user) => !adminIds.includes(user.id)
            );
          } else {
            this.users = [];
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
