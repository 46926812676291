<template>
  <v-dialog :value="true" width="600" persistent>
    <v-card class="container">
      <v-card-title>
        <span class="headline">Video Picker</span>
      </v-card-title>
      <v-card-text>
        <div class="chip-box">
          <v-chip
            v-for="item in selected"
            small
            close
            :key="item.id"
            class="chip"
            @click:close="handleRemoveChip(item.id)"
          >
            {{ item.title }}
          </v-chip>
        </div>
        <v-text-field
          label="Search video"
          clearable
          v-model="search"
          :loading="isLoading"
          placeholder="Type to search more"
        ></v-text-field>
        <div class="scrollable">
          <v-list dense>
            <v-list-item
              v-for="video in videos"
              :key="video.id"
              avatar
              three-line
            >
              <v-list-item-icon large class="v-img__placeholder">
                <img :src="video.thumb_url" height="56" width="86" class="thumbnails" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{
                  video.channel.name
                }}</v-list-item-title>
                <v-list-item-title>{{ video.title }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  $helpers.lengthInMinutes(video.duration)
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-checkbox v-model="selected" :value="video"></v-checkbox>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="addVideos(false)"
          >Cancel</v-btn
        >
        <v-btn color="primary" text @click="addVideos(true)">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
<script>
import api from "@/api/api";

export default {
  props: {
    channelId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      search: "",
      videos: [],
      selected: [],
      isLoading: false,
      timeout: null,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    search() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.getData();
      }, 500);
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      const params = {
        limit: 10,
        offset: 0,
        channel_id: this.channelId,
        filter: 1,
        cache: false,
        show_comments: false,
        term: this.search,
      };

      api
        .getChannelVideos(params, "all")
        .then((res) => {
          if (res.data.videos.length > 0) {
            console.log(res.data.videos);
            this.videos = res.data.videos;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    addVideos(confirm) {
      // do something with selected videos
      this.$emit("onPick", confirm ? this.selected : null);
    },
    handleRemoveChip(id) {
      this.selected = this.selected.filter((o) => o.id !== id);
    },
  },
};
</script>
<style scoped>
/* Define custom CSS classes */
.container {
  position: relative;
  overflow-y: hidden;
}
.chip-box {
  max-height: 100px;
  overflow-y: auto;
}
.scrollable {
  min-height: 300px;
  max-height: calc(100vh - 560px);
  overflow-y: auto;
}

.thumbnails {
  border-radius: 5px;
  background-color: #000;
}

.chip {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>