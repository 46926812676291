<template>
  <v-container fluid>
    <v-row class="pa-1">
      <v-col xl=6 lg=8 md=10 sm=12 xs=12>
        <!-- place_type -->
        <div v-if="!placeId">
          <span class="text-subtitle-2 font-weight-bold">
            *{{$t("type of the event")}}
          </span>
          <div class="d-flex py-2">
            <v-select
              class="middle-input"
              v-model="selectedPlaceType"
              :items="placeTypes"
              item-text="title"
              item-value="type"
              :label="$t('choose')"
              dense
              outlined
              single-line
              return-object
            >
            </v-select>
            <div class="mx-5 mark-img" />
          </div>
        </div>
         <v-form class="main-row" ref="form" v-model="valid" lazy-validation> 
            <!-- name -->
            <span class="text-subtitle-2 font-weight-bold">*{{$t("event name")}}</span>
            <div class="d-flex pt-2">
              <v-text-field
                dense
                class="input"
                v-model="name"
                :label="$t('what will happen at the end of days')"
                single-line
                outlined
              />

              <div class="mx-5 mark-img">
                <v-icon v-if="this.name" size="30" color="black"
                  >mdi-check-bold</v-icon
                >
              </div>
            </div>
            <span class="text-subtitle-2 font-weight-bold">
              {{ $t('time') }}
            </span>
            <template v-if="!initing">
              <event-form
                v-for="(event, index) in eventDatas"
                :key="index"
                :index="index"
                :eventData="event"
                :audiences="audiences"
                :hideRemoveBtn="eventDatas.length === 1"
                @update="handleUpdateEvent"
                @remove="handleRemoveEventData"
                :isEdit="!!placeId"
              />
            </template>
            <v-btn
              v-if="!placeId"
              outlined
              color="primary"
              class="d-block mb-4"
              @click="handleAddEventData"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t('add a date') }}
            </v-btn>
            <!-- venue -->
            <span class="text-subtitle-2 font-weight-bold">
            {{$t("name of the place")}}
            </span>
            <div class="d-flex pt-2">
              <v-text-field
                dense
                class="middle-input input"
                v-model="locationName"
                :label="$t('for example the glory of israel')"
                single-line
                outlined
              />
            </div>
            <!-- address -->
            <span class="text-subtitle-2 font-weight-bold">
               *{{$t("address of the event")}}
            </span>
            <div class="d-flex pt-2 pb-6">
              <v-autocomplete
                v-model="address"
                :loading="locationLoading"
                :items="locations"
                :search-input.sync="searchLocation"
                class="input"
                hide-no-data
                hide-details
                :label="$t('address example')"
                single-line
                outlined
                dense
              />
              <div class="mx-5 mark-img">
                <v-icon v-if="this.address" size="30" color="black">mdi-check-bold</v-icon>
              </div>
            </div>
            <!-- audience -->
            <span class="text-subtitle-2 font-weight-bold">
            *{{$t("who is the event for")}}
            </span>
            <div class="d-flex py-2">
              <v-select
                class="middle-input"
                v-model="selectedAudience"
                :items="audiences"
                item-text="name"
                item-value="value"
                :label="$t('choose')"
                dense
                outlined
                single-line
                return-object
              >
              </v-select>
              <div class="mx-5 mark-img" />
            </div>
            <!-- channel -->
            <div v-if="!placeId">
              <span class="text-subtitle-2 font-weight-bold">
                {{$t("search by name")}}
              </span>
              <div class="d-flex py-2">
                <v-autocomplete
                  v-model="selectedChannel"
                  :items="channelEntries"
                  :loading="isChannelLoading"
                  :search-input.sync="channelSearch"
                  color=""
                  class="input"
                  clearable
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  placeholder="הקלד לחיפוש"
                  return-object
                ></v-autocomplete>
                <div class="mx-5 mark-img" />
              </div>
            </div>
            
            <!-- collector_phone -->
            <span class="text-subtitle-2 font-weight-bold">
            {{$t("contact phone number")}}
            </span>
            <div class="d-flex py-2">
              <v-text-field
                dense
                class="middle-input input"
                single-line
                outlined
                v-model="nationalNumber"
                type="tel"
                @keypress="filterNumber"
              />
              <v-select
                class="country-input mr-2"
                v-model="selectedCountry"
                :items="countries"
                item-text="name"
                item-value="code"
                :label="$t('choose')"
                dense
                outlined
                single-line
                return-object
              >
              </v-select>
              <div class="mx-5 mark-img">
                <v-icon v-if="this.phoneValid" size="30" color="black"
                  >mdi-check-bold</v-icon
                >
              </div>
            </div>
            <span class="text-subtitle-2 font-weight-bold">
              {{$t("event image")}}
            </span>
            <div class="d-flex pb-5 mt-2">
              <div class="main-row">
                <v-btn
                  color="#616161"
                  class="white--text"
                  tile
                  width="140"
                  @click="$refs.file.click()"
                  :disabled="this.loading"
                  >{{$t("select a file")}}</v-btn
                >
                <input
                  type="file"
                  ref="file"
                  accept="image/*"
                  @change="selectFile"
                  style="display: none"
                />
                <span class="caption px-5">{{ this.fileName }}</span>
                <v-btn
                  v-if="this.selectedFile"
                  icon
                  color="pink"
                  :disabled="this.loading"
                  @click="clearFile()"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
              <div class="mx-5 mark-img">
                <v-icon v-if="this.selectedFile" size="30" color="black"
                  >mdi-check-bold</v-icon
                >
              </div>
            </div>
            <!-- Saving -->
            <div class="d-flex py-2">
              <div class="d-flex justify-end main-row ">
                <v-btn
                  tile
                  color="#388E3C"
                  width="140"
                  class="white--text"
                  @click="this.onPressComplete"
                  :loading="this.loading"
                  :disabled="this.loading || !this.uploadValid"
                >
                {{placeId ? $t('save') : $t('bringup')}}
                </v-btn>
              </div>
              <div class="mx-5 mark-img" />
            </div>
            <div class="d-flex py-2" v-if="invalidMsg">
              <div class="d-flex justify-end main-row red--text">
                {{ invalidMsg }}
              </div>
              <div class="mx-5 mark-img" />
            </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/api/api";
import moment from "moment";
import timezones from "../../config/timezone.js";
import allCountries from "../../config/data.js";
import {parsePhoneNumber} from "awesome-phonenumber";
import axios from "axios";
import EventForm from '../event/EventForm.vue';

export default {
  components: { EventForm },
  data() {
    return {
      valid: true,
      loading: false,
      initing: true,
      placeId: this.$route.params.place_id,
      fileName: null,
      selectedFile: null,
      signedUrl: null,
      placeTypes: [],
      selectedPlaceType: null,
      name: null,
      eventDatas: [{
        timeItems: [
          { start: null, end: null, event_id: null }
        ],
        date: moment(new Date()).format('YYYY-MM-DD'),
        recurring: '0',
        selectedTimezone: timezones[0],
      }],
      backupEvents: null,
      locationName: null,
      recurring: "0",
      locations: [],
      locationLoading: false,
      address: null,
      debounce: null,
      searchLocation: null,
      selectedAudience: { name: this.$t('everyone'), value: "everyone" },
      audiences: [
        { name: this.$t('everyone'), value: "everyone" },
        { name: this.$t('male'), value: "male" },
        { name: this.$t('female'), value: "female" },
        { name: this.$t('boys'), value: "boys" },
        { name: this.$t('girls'), value: "girls" },
      ],
      selectedCountry: {
        name: "Israel (‫ישראל‬‎)",
        iso2: "il",
        dialCode: "972",
        priority: 0,
        areaCodes: null,
      },
      nationalNumber: null,
      countries: allCountries,
      phoneValid: false,
      currentChannelId: null,
      selectedChannel: undefined,
      channelSearch: null,
      channelEntries: [],
      isChannelLoading: false,
      refreshKey: Date.now(),
      interval: null,
      invalidMsg: '',
    }
  },
  watch: {
    currentRouteName() {
      this.initLoad();
    },
    $route(to) {
      if (to.params.place_id) {
        this.placeId = to.params.place_id;
        this.initLoad();
      }
    },
    searchLocation(val) {
      if (val === this.address) {
        return;
      }
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.queryLocations(val);
      }, 1000);
    },
    nationalNumber(val) {
      const result = parsePhoneNumber(val || '', this.selectedCountry.iso2).toJSON();
      this.phoneValid = result.valid;
    },
    channelSearch(val) {
      if (this.isChannelLoading) return;
      this.isChannelLoading = true;
      api.searchChannels(val)
        .then((res) => {
          this.isChannelLoading = false
          this.channelEntries = res.data.items;
        })
        .catch((error) => {
          this.isChannelLoading = false
          console.log(error);
        });
    }
  },
  mounted() {
    this.initLoad();
    this.interval = setInterval(() => {
      this.refreshKey = Date.now();
    }, 30000);
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  methods: {
    initLoad() {
      if (!this.placeId) {
        this.getPlaceTypes();
      }
      this.name = null;
      const event = this.eventDatas[0];
      this.address = null;
      this.signedUrl = null;
      this.selectedFile = null;
      this.fileName = null;
      if (this.placeId) {
        this.$store.dispatch("SET_TOOLBARLOAD", true);
        this.initing = true;
        const params = this.placeId.split('g');
        api
          .getPlace(params[0] || 1, { place_type: 'place', group: params[1] })
          .then((res) => {
            if (res.data) {
              console.log(res.data);
              const commonInfo = res.data[0] || res.data;
              this.name = commonInfo.name;
              this.locations = [commonInfo.address];
              this.address = commonInfo.address;
              event.selectedTimezone = commonInfo.tz ? timezones.find((item) => item.zone === commonInfo.tz) : timezones[0];
              const utcDate = moment(commonInfo.taking_place).tz(event.selectedTimezone.zone);
              event.date = utcDate.format("YYYY-MM-DD");

              if (Array.isArray(res.data)) {
                const items = res.data.sort((a, b) => moment(a.taking_place).tz(a.tz || timezones[0].zone) - moment(b.taking_place).tz(b.tz || timezones[0].zone));
                const groupedEvents = {};
                for (let item of items) {
                  const date_tz = `${item.taking_place.slice(0, 10)}-tz-${item.tz || event.selectedTimezone.zone}`;
                  if (!groupedEvents[date_tz]) {
                    groupedEvents[date_tz] = [];
                  }
                  groupedEvents[date_tz].push(item);
                }
                this.eventDatas = Object.keys(groupedEvents).map((date_tz) => ({
                  date: date_tz.split('-tz-')[0],
                  recurring: groupedEvents[date_tz][0].recurring ? String(groupedEvents[date_tz][0].recurring_interval || '1') : '0',
                  timeItems: groupedEvents[date_tz].map(({ taking_place, end_time, id }) => ({
                    start: taking_place,
                    end: end_time,
                    event_id: id,
                  })),
                  selectedTimezone: groupedEvents[date_tz][0].tz ? timezones.find((item) => item.zone === groupedEvents[date_tz][0].tz) : event.selectedTimezone,
                }));
              } else {
                event.timeItems = [{
                  start: utcDate.toISOString(),
                  end: commonInfo.end_time ? moment(commonInfo.end_time).tz(event.selectedTimezone.zone).toISOString() : null,
                  event_id: commonInfo.id
                }];
                event.recurring = commonInfo.recurring ? String(commonInfo.recurring_interval || '1') : "0";
                this.$set(this.eventDatas, 0, event);
              }
              this.backupEvents = JSON.parse(JSON.stringify(this.eventDatas));
              this.selectedAudience = this.audiences.find((item) => item.value === commonInfo.audience);
              this.locationName = commonInfo.venue;
              // Check country code is valid or not
              const cc = this.$helpers.guessCountryCode(commonInfo.collector_phone);
              const pn = parsePhoneNumber(cc + (commonInfo.collector_phone || ""));
              if (pn && pn.isValid()) {
                const countryCode = pn.getCountryCode().toString();
                this.nationalNumber = pn.getNumber("significant");
                this.selectedCountry = this.countries.filter(
                  (item) => item.dialCode === countryCode
                )[0];
              } 

              this.selectedPlaceType = this.placeTypes.find((e) => e.value === commonInfo.place_type);
              this.currentChannelId = commonInfo.channel_id;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("SET_TOOLBARLOAD", false);
            this.initing = false;
          });
      } else {
        this.initing = false;
      }
    },
    getPlaceTypes() {
      api
        .getPlaceTypes()
        .then((res) => {
          if (res.data && res.data.length > 0) {
            if (this.currentRouteName === 'AddEvents') {
              this.placeTypes = res.data.filter((e) => (e.type === 'event' || e.type === 'hevruta_place')).map((e) => {
                if (e.type === 'event') {
                  e.title = this.$t('tora lessons') 
                } else {
                  e.title = this.$t('personal study')
                }
                return e
              })
            } else {
              this.placeTypes = res.data;
            }
            this.selectedPlaceType = this.placeTypes[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearFile() {
      this.selectedFile = null;
      this.fileName = null;
    },
    selectFile() {
      this.selectedFile = this.$refs.file.files[0];
      this.fileName = this.$refs.file.files[0].name;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    queryLocations(strLocation) {
      this.locationLoading = true;
      const params = { location: strLocation };
      api
        .getEventLocationSearch(params)
        .then((res) => {
          this.locationLoading = false;
          if (res.data && res.data.locations) {
            this.locations = res.data.locations;
          } else {
            this.locations = [];
          }
        })
        .catch((err) => {
          console.log(err);
          this.locations = [];
          this.locationLoading = false;
        });
    },
    filterNumber(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    vailidateScheduledDate(event) {
      if (!event.date) return false;
      if (this.placeId) {
        this.invalidMsg = '';
        return true;
      }
      let valid = true;
      for (let item of event.timeItems) {
        if (!item.start) {
          valid = false;
          this.invalidMsg = this.$t('start time is required');
          break;
        }
        const nowInEventTimezone = moment.tz(event.selectedTimezone.zone).toDate();
        if (nowInEventTimezone > this.getScheduleDate({ ...event, time: item.start })) {
          this.invalidMsg = this.$t('start time should be in the future');
          valid = false;
          break;
        }
        if (item.end) {
          if (this.getScheduleDate({ ...event, time: item.start }) > this.getScheduleEndDate({ ...event, endTime: item.end })) {
            this.invalidMsg = this.$t('end time should be after start time');
            valid = false;
            break;
          }
        }
      }
      if (valid) {
        this.invalidMsg = '';
      }
      return valid;
    },
    getScheduleDate(event) {
      if (event.time.length > 8) {
        event.time = moment(event.time).tz(event.selectedTimezone.zone).format('HH:mm:ss');
      }
      const strISO8061 =  moment.tz(`${event.date} ${event.time}`, event.selectedTimezone.zone).toISOString();
      return new Date(strISO8061);
    },
    getScheduleEndDate(event) {
      if (event.endTime.length > 8) {
        event.endTime = moment(event.endTime).tz(event.selectedTimezone.zone).format('HH:mm:ss');
      }
      const strISO8061 =  moment.tz(`${event.date} ${event.endTime}`, event.selectedTimezone.zone).toISOString();
      return new Date(strISO8061);
    },
    async getSignedUrl() {
      return new Promise((resolve) => {
        if (!this.activeChannelId) resolve(null);
        api
          .getVideoStorageSignedUrl(
            this.activeChannelId,
            this.selectedFile.type
          )
          .then((res) => {
            if (res.data) {
              resolve(res.data.url);
            }
          })
          .catch(() => {
            resolve(null);
          });
      });
    },
    async onPressComplete() {
      this.loading = true;
      if (this.selectedFile) {
        const signedUrl = await this.getSignedUrl();
        if (!signedUrl) {
          this.loading = false;
          return;
        }
        axios
          .put(signedUrl, this.selectedFile, {
            headers: { "Content-Type": this.selectedFile.type },
          })
          .then(() => {
            this.postPlace(signedUrl);
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      } else {
        this.postPlace(null);
      }
    },
    async postPlace(signedUrl) {
      let body = {};
      const requestkey = this.selectedPlaceType ? this.selectedPlaceType.type : 'place';
      body[requestkey] = {
        name: this.name,
        address: this.address,
        audience: this.selectedAudience.value,
        venue: this.locationName,
        channel_id: this.selectedChannel ? this.selectedChannel.id : undefined
      }
      if (signedUrl) {
        body[requestkey]["image"] = signedUrl.split("?")[0];
      }
      if (this.phoneValid) {
        const pn = parsePhoneNumber(this.nationalNumber, this.selectedCountry.iso2).toJSON();
        const formattedPhone = pn.number.e164;
        body[requestkey]["collector_phone"] = formattedPhone;
      }
      if (this.placeId) {
        const originalEventIds = this.backupEvents.map((item) => item.timeItems.map((t) => t.event_id)).flat(1);
        const newEventIds = this.eventDatas.map((item) => item.timeItems.map((t) => t.event_id)).flat(1);
        const deletedEventIds = originalEventIds.filter((id) => !newEventIds.includes(id));
        
        const flatEvents = this.eventDatas.map((parent) => parent.timeItems.map((child) => {
          const taking_place = this.getScheduleDate({
            date: parent.date,
            time: child.start,
            selectedTimezone: parent.selectedTimezone
          }).toISOString();

          const obj = {
            taking_place,
            recurring: parent.recurring !== "0",
            recurring_interval: parent.recurring === "0" ? null : Number(parent.recurring),
            tz: parent.selectedTimezone.zone,
          };

          obj['end_time'] = child.end ? this.getScheduleEndDate({
            date: parent.date,
            endTime: child.end,
            selectedTimezone: parent.selectedTimezone,
          }).toISOString() : null;
          return {
            ...body[requestkey],
            ...obj,
            id: child.event_id,
          };
        })).flat(1);

        if (deletedEventIds.length) {
          const deletePromises = deletedEventIds.map((id) =>
            new Promise((resolve, reject) => {
              api
                .deletePlace(id, { place_type: requestkey })
                .then(() => {
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject(error);
                });
            })
          );
          await Promise.allSettled(deletePromises);
        }

        const editedEvents = flatEvents.filter((item) => item.id);
        const newEvents = flatEvents.filter((item) => !item.id);

        const tempGrp = String(Date.now());

        if (editedEvents.length) {
          await Promise.allSettled(editedEvents.map((item) =>
            new Promise((resolve, reject) => {
              const payload = { [requestkey]: item };
              if (newEventIds.length > 0 && !this.placeId.startsWith('g')) {
                payload[requestkey]['group'] = tempGrp;
              }
              api
                .editPlace(item.id, payload, { place_type: requestkey })
                .then(() => {
                  resolve();
                })
                .catch((err) => {
                  console.log(err);
                  reject(err);
                });
            })
          ))
        }
        if (newEvents.length) {
          await Promise.allSettled(newEvents.map((item) =>
            new Promise((resolve, reject) => {
              const payload = {
                [requestkey]: {
                  ...item,
                  group: this.placeId.startsWith('g') ? this.placeId.replace('g', '') : tempGrp,
                }
              };
              api
                .postPlace(payload, { place_type: requestkey })
                .then(() => {
                  resolve();
                })
                .catch((err) => {
                  console(err);
                  reject(err);
                });
            })
          ))
        }
        this.loading = false;
        this.uploaded = true;
        if (this.currentRouteName === 'EditEvents') {
          this.$router.push({ path: `/events` });
        } else {
          this.$router.push({ path: `/places` });
        }
      } else {
        if (this.eventDatas.length > 1 || this.eventDatas[0]?.timeItems?.length > 1) {
          const items = [];
          this.eventDatas.forEach((item) => {
            item.timeItems.forEach(({ start, end }) => {
              const taking_place = this.getScheduleDate({
                date: item.date,
                time: start,
                selectedTimezone: item.selectedTimezone
              }).toISOString();
              const obj = {
                taking_place,
                recurring: item.recurring !== "0",
                recurring_interval: item.recurring === "0" ? null : Number(item.recurring),
                tz: item.selectedTimezone.zone,
              };
              if (end) {
                obj['end_time'] = this.getScheduleEndDate({
                  date: item.date,
                  endTime: end,
                  selectedTimezone: item.selectedTimezone,
                }).toISOString();
              }
              items.push(obj);
            })
          });
          body[requestkey]['items'] = items;
        } else {
          const event = { ...this.eventDatas[0] };
          event.time = event.timeItems[0].start;
          event.endTime = event.timeItems[0].end;
          body[requestkey]['taking_place'] = this.getScheduleDate(event).toISOString();
          body[requestkey]['recurring'] = event.recurring !== "0";
          body[requestkey]['recurring_interval'] = event.recurring === "0" ? null : Number(event.recurring);
          body[requestkey]['tz'] = event.selectedTimezone.zone;
          if (event.endTime) {
             body[requestkey]["end_time"] = this.getScheduleEndDate(event).toISOString();
          }
        }
        this.loading = true;
        api
          .postPlace(body, { place_type: requestkey })
          .then(() => {
            if (this.currentRouteName === 'AddEvents') { 
              this.$router.push({ path: `/events` });
            } else {
              this.$router.push({ path: `/places` });
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
            this.uploaded = true;
          });
      }
    },
    handleUpdateEvent({ index, key, value }) {
      const newData = {
        ...this.eventDatas[index],
        [key]: value,
      };
      this.$set(this.eventDatas, index, newData);
      this.refreshKey = Date.now();
    },
    handleAddEventData() {
      this.eventDatas.push({
        timeItems: [
          { start: null, end: null }
        ],
        date: moment(new Date()).format('YYYY-MM-DD'),
        recurring: "0",
        selectedTimezone: timezones[0],
      });
    },
    handleRemoveEventData(index) {
      if (this.eventDatas.length === 1) {
        return;
      }
      this.eventDatas.splice(index, 1);
    }
  },
  computed: {
    uploadValid() {
      this.refreshKey;
      let isValid = this.placeId ? (this.name && this.address) : (this.address && this.phoneValid && this.name);
      this.eventDatas.forEach((item) => {
        isValid = isValid && this.vailidateScheduledDate(item);
      });
      return isValid;
    },
    activeChannelId() {
      if (this.currentChannelId) return this.currentChannelId;
      if (this.selectedChannel) return this.selectedChannel.id;
      return this.$store.getters.getActiveChannelId;
    },
    currentRouteName() {
        return this.$route.name;
    }
  }
}

</script>

<style scoped>
.main-row {
  flex: 1;
}
.select-input {
  border-radius: 0px;
}
.input {
  border-radius: 0px;
  font-size: 12px;
}
.middle-input {
  border-radius: 0px;
  min-width: 250px;
  max-width: 300px;
}
.country-input {
  border-radius: 0px;
  font-size: 12px;
  min-width: 100px;
  max-width: 150px;
}
.date-input {
  border-radius: 0px;
  font-size: 12px;
  min-width: 100px;
  max-width: 150px;
  margin-left: 10px !important;
}
.timezone-input {
  border-radius: 0px;
  max-width: 200px;
  font-size: 12px;
}
</style>