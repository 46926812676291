<template>
  <v-container fluid>
    <h4>{{$t('recommended')}}</h4>
    <v-row class="pa-4 d-flex flex-column">
      <v-row class="d-flex flex-wrap py-4" no-gutters>
        <v-col
          xl="4"
          lg="4"
          md="6"
          sm="12"
          xs="12"
          cols="12"
          v-for="video in videos"
          :key="video.id"
        >
          <video-item
            :video="video"
            :showDownload="true"
            :showHotOptions="true"
            @refresh="getData"
          />
        </v-col>
      </v-row>
    </v-row>
    <div class="text-center">
      <v-pagination
        color="red"
        v-model="page"
        total-visible="6"
        @input="getData"
        :length="Math.ceil(total / limit)"
      ></v-pagination>
    </div>
    <v-snackbar v-model="showMessage" :timeout="2000" top>
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  mounted() {
    this.getData();
  },
  watch: {},
  methods: {
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .getRecommended(
          this.term,
          this.limit,
          (this.page - 1) * this.limit,
          1,
          null
        )
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          this.videos = res.data.videos;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(err);
        });
    },
  },

  data() {
    return {
      videos: [],
      limit: 15,
      total: 0,
      publishedPage: 1,
      term: null,
      message: null,
      showMessage: false,
      page: 1,
    };
  },
};
</script>

<style scoped></style>
