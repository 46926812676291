<template>
    <v-container fluid>
        <v-row class="d-flex justify-end pa-5">
            <v-btn
                color="#ff0000"
                class="white--text"
                tile
                width="120"
                @click="$router.push({name: 'AddVersion'})"
            >
                + {{$t('new version')}}
            </v-btn>
        </v-row>
        <v-row class="px-5 flex-column">
            <span v-if="versions && !versions.length" class="text-subtitle-2">
            {{"No Data"}}
            </span>
            <v-col v-else xl="10" lg="10" md="12" sm="12" xs="12" cols="12">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <td>
                                    <span class="text-subtitle-2 font-weight-bold">{{$t('version')}}</span>
                                </td>
                                <td>
                                    <span class="text-subtitle-2 font-weight-bold">{{$t('view update')}}</span>
                                </td>
                                <td>
                                    <span class="text-subtitle-2 font-weight-bold">{{$t('operations')}}</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="version.id" v-for="version in versions">
                                <td>
                                    <span >
                                     {{ version.version }}
                                    </span>
                                </td>
                                <td>
                                    <div>
                                        <v-switch
                                            @change="onSwitchUpdate(version)"
                                            dense
                                            color="success"
                                            inset
                                            class="mt-0"
                                            v-model="version.force_update"
                                            :label="version.force_update ? $t('active') : $t('inactive')"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex justify-right">
                                        <v-btn text @click="onShowDialog(version)">
                                            <span class="text-subtitle-2 font-weight-bold">
                                                <u>{{$t('delete')}}</u>
                                            </span>
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-dialog v-model="showDialog" max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                מחיקה
                </v-card-title>
                <v-card-text>
                האם אתה בטוח?
                </v-card-text>
                <v-card-actions>
                <v-spacer />
                <v-btn color="green darken-1" text @click="this.onCancelDelete">
                    חזור
                </v-btn>
                <v-btn color="green darken-1" text @click="this.onConfirmDelete">
                    מחק
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import api from "@/api/api";

export default {
    data() {
        return {
            versions: null,
            showDialog: false,
            loading: false,
            selectedVersion: null,
        }
    },
    mounted() {
        this.initLoad();
    },
    methods: {
        initLoad() {
            this.getData();
        },
        getData() {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            api.getAppVersions()
            .then((res) => {
                this.$store.dispatch("SET_TOOLBARLOAD", false);
                if (res.data && res.data.app_versions.length > 0) {
                   this.versions = res.data.app_versions;
                } else {
                    this.versions = [];
                }
            })
            .catch((error) => {
                this.$store.dispatch("SET_TOOLBARLOAD", false);
                console.log(error);
            })
        },
        onShowDialog(version) {
            this.showDialog = true;
            this.selectedVersion = version;
        },
        onCancelDelete() {
            this.showDialog = false;
            this.selectedVersion = null;
        },
        onConfirmDelete() {
            this.showDialog = false;
            api.deleteAppVersion(this.selectedVersion.id)
                .then(() => {
                    this.selectedVersion = null;
                    this.getData();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onSwitchUpdate(val) {
            const body = {
                app_version: {
                    version: val.version,
                    force_update: val.force_update,
                }
            };
            api.editAppVersion(val.id, body)
                .then(() => {
                    this.getData();
                })
                .catch((err) => {
                    val.force_update = !val.force_update;
                    console.log(err);
                });
        },
    }
}

</script>

<style scoped>

</style>