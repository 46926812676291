<template>
  <v-container fluid>
    <add-video-view @onPressAdd="navigateAdd" :add-text="`+ ${$t('new video')}`" />
    <v-row class="d-flex flex-column mt-10" no-gutters>
      <v-col xl="4" lg="6" md="8" sm="12" xs="12" cols="12" no-gutters>
        <div class="text-subtitle-2 font-weight-bold ">
          הערוץ שלך ב"כולל" מקושר לחשבונות הבאים
        </div>
        <v-row
          class="mb-10 d-flex align-center justify-space-between"
          no-gutters
        >
          <div class="d-flex align-center">
            <img src="@/assets/youtube.png" alt="" height="30" />
            <div
              class="
                text-caption                
                px-4
              "
            >
              {{ this.ytName }}
            </div>
          </div>
        </v-row>
        <div class="text-subtitle-2 font-weight-bold ">
          הפעל פרסום אוטומטי של התוכן מיוטיוב ב״כולל״
        </div>
        <v-row>
          <v-switch
            class=""
            @change="onUpdateSetting(true)"
            v-model="ytEnabled"
            :loading="ytLoading"
            :disabled="ytLoading"
          />
        </v-row>
        <!-- <v-row class="d-flex  align-center justify-space-between" no-gutters>
                    <div class="d-flex align-center">
                        <img
                            src="@/assets/facebook.png"
                            alt="John"
                            height="30"
                        >
                        <div class="text-caption font-weight-bold text-decoration-underline px-4">
                        {{this.fbName}}
                        </div>
                    </div>
                    
                    <v-switch class="mr-10" @change="onUpdateSetting(false)" v-model="fbEnabled" :loading="fbLoading" :disabled="fbLoading" />
                </v-row> -->
      </v-col>
    </v-row>
    <v-row>
      <span class="text-caption grey--text"
        >*לוקח לתכנים שעולים בפלטפורמות אחרות מספר שעות להופיע ב״כולל״.</span
      >
    </v-row>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  data() {
    return {
      ytEnabled: false,
      ytId: null,
      fbEnabled: false,
      fbId: null,
      ytLoading: false,
      fbLoading: false,
      channelId: this.$route.params.channel_id,
    };
  },

  mounted() {
    this.initLoad();
  },

  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.channelId = to.params.channel_id;
        this.initLoad();
      }
    },
  },

  computed: {
    ytName() {
      return this.ytId ? `www.youtube.com/channel/${this.ytId}` : "לא מחובר";
    },
    fbName() {
      return this.fbId ? `www.facebook.com/${this.ytId}` : "לא מחובר";
    },
  },

  methods: {
    navigateAdd() {
      this.$router.push({ path: `/channels/${this.channelId}/video/add` });
    },
    initLoad() {
      this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .getChannelInfo(this.channelId)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data) {
            this.ytEnabled = res.data.channels.yt_enabled;
            this.ytId = res.data.channels.yt_id;
            this.fbEnabled = res.data.channels.fb_enabled;
            this.fbId = res.data.channels.fb_id;
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },

    onUpdateSetting(isYt) {
      if (isYt) this.ytLoading = true;
      else this.fbLoading = true;

      const params = {
        yt_enabled: this.ytEnabled,
        fb_enabled: this.fbEnabled,
      };
      api
        .editChannel(this.channelId, params)
        .then(() => {
          if (isYt) this.ytLoading = false;
          else this.fbLoading = false;
        })
        .catch((error) => {
          console.log(error);
          if (isYt) this.ytLoading = false;
          else this.fbLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
