<template>
  <v-dialog max-width="480" v-model="show" persistent>
    <v-card class="pa-8">
      <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
        {{ $t("add to channel") }}
      </v-card-title>
      <div class="d-flex align-center">
        <v-autocomplete
          class="select-input"
          v-model="selected"
          :items="channels"
          :label="$t('channels')"
          item-text="name"
          item-value="id"
          chips
          small-chips
          deletable-chips
          outlined
          dense
          clearable
          persistent-hint
          return-object
          :search-input.sync="search"
          :loading="isLoading"
          placeholder="Type to search more"
        />
      </div>
      <v-card-actions class="d-flex justify-space-between px-0 mt-4">
        <v-btn
          class="white--text flex-grow-1"
          color="#616161"
          tile
          x-large
          @click="handleAction(false)"
        >
          <span class="text-h5">{{ $t("cancel") }}</span>
        </v-btn>
        <v-btn
          class="white--text flex-grow-1"
          color="#ff0000"
          tile
          x-large
          @click="handleAction(true)"
          :disabled="disabled"
        >
          <span class="text-h5">{{ $t("add") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
        
<script>
import api from "@/api/api";

export default {
  props: {
    channelId: {
      type: String,
      default: null,
    },
    exceptChannelId: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      show: true,
      selected: null,
      search: null,
      channels: [],
      defaultChannels: [],
      timeout: null,
      isLoading: false,
    };
  },
  computed: {
    disabled() {
      return !this.selected;
    },
    validChannels() {
      if (this.exceptChannelId) {
        return this.channels.filter((o) => o.id !== Number(this.exceptChannelId));
      }
      return this.channels;
    }
  },
  watch: {
    selected() {
      this.search = null;
    },
    search(val) {
      if (!val) {
        this.channels = this.defaultChannels;
        if (this.defaultChannels.length < 3) {
          this.fetchChannels(null);
        }
        return;
      }
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.fetchChannels(val);
      }, 500);
    }
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      if (this.channelId) {
        this.channels = this.$store.getters.getChannels?.map((o) => o.channel);
        this.selected = this.channels.find((o) => o.id === Number(this.channelId));
        this.search = this.selected?.name;
      } else {
        this.fetchChannels(null);
      }
    },
    async handleAction(isConfirmed) {
      this.$emit("onDone", isConfirmed ? this.selected : null);
    },
    onSelected(channel) {
      this.selected = channel;
    },

    fetchChannels(term) {
      this.isLoading = true;
      if (term) {
        api
          .searchChannels(term, true)
          .then((res) => {
            if (res.data && res.data.items.length > 0) {
              this.channels = res.data.items;
            } else {
              this.channels = [];
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        const params = { offset: 0, limit: 5, o_n_o: true };
        api
          .getChannelsCatalog(params)
          .then((res) => {
            if (res.data && res.data.channels.length > 0) {
              this.defaultChannels = res.data.channels;
              this.channels = res.data.channels;
            } else {
              this.channels = [];
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  },
};
</script>
  
<style scoped>
.select-input {
  border-radius: 0px;
}
</style>
