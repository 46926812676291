
<template>
  <v-container fluid>
    <add-video-view :add-text="null" />
    <v-row class="px-5 flex-column">
      <span v-if="comments && !comments.length" class="text-subtitle-2">{{$t('no comments')}}</span>
      <v-col v-else xl="10" lg="10" md="12" sm="12" xs="12" cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('user')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('video')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('comment')}}</span>
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold"> 
                    {{$t('response date')}}
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('operations')}}</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr :key="comment.id" v-for="comment in comments">
                <td>
                  <div class="d-flex justify-right">
                    {{ comment.user.email }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <router-link :to="showVideo(comment.video)">
                      {{ comment.video.title }}
                    </router-link>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ comment.text }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    {{ moment(comment.created_at).format("DD/MM/YYYY") }}
                  </div>
                </td>
                <td>
                  <v-btn text @click="remove(comment)" outlined small>
                    <span class="text-subtitle-2 font-weight-bold"> {{$t('delete')}} </span>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-pagination
        color="red"
        v-model="page"
        total-visible="6"
        @input="getData"
        :length="Math.ceil(total / limit)"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import api from "@/api/api";

export default {
  data() {
    return {
      comments: null,
      moment: moment,
      channelId: this.$route.params.channel_id,
      total: 0,
      limit: 10,
      page: 1,
    };
  },
  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.channelId = to.params.channel_id;
        this.initLoad();
      }
    },
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    showVideo(video) {
      return `/channels/${this.channelId}/videos/${video.id}`;
    },
    initLoad() {
      this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        channel_id: this.channelId,
      };
      api
        .getComments(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.comments.length > 0) {
            this.comments = res.data.comments;
            this.total = res.data.total;
          } else {
            this.comments = [];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
    remove(comment) {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api.removeComment(comment).then(() => {
        this.getData();
      });
    },
  },
};
</script>
<style scoped>
</style>