<template>
  <v-container fluid>
    <v-row class="pa-1">
      <v-col lg="7" md="8" sm="8" xs="12">
        <div v-if="uploadingStatus==0">
          <v-select
            class="lang-select my-2"
            v-model="selectedLang"
            :items="languages"
            item-text="name"
            item-value="val"
            single-line
            outlined
            dense
            hide-details
          />
          <div v-if="isAdmin" class="d-flex">
            <span class="text-subtitle-2 font-weight-bold red--text">
              {{$t("select video upload type")}}
            </span>
          </div>
          <div v-if="isAdmin" class="d-flex">
            <v-radio-group v-model="uploadType" row>
              <v-radio color="black" value="internal">
                <template v-slot:label>
                  <span class="text-caption">{{$t("file")}}</span>
                </template>
              </v-radio>
              <v-radio color="black" value="youtube">
                <template v-slot:label>
                  <span class="text-caption">{{$t("link")}}</span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div v-if="uploadType==='internal'" class="d-flex py-5">
            <div class="main-row">
              <div
                @dragenter.prevent="dropActive = true"
                @dragover.prevent="dropActive = true"
                @dragleave.prevent="dropActive = false"
                @drop="handleDrop"
                :data-drop-active="dropActive"
              >
                <v-btn
                  :color="dropActive ? 'red' : '#616161'"
                  class="white--text"
                  tile
                  width="140"
                  @click="$refs.file.click()"
                >
                  {{ dropActive ? 'Drop Here' : $t("select a video") }}
                </v-btn>
              </div>
              <input
                type="file"
                ref="file"
                accept="video/*"
                @change="selectFile"
                style="display: none"
              />
              <span v-if="fileName" class="caption mr-5">{{ fileName }}</span>
            </div>
            <div class="mx-5 mark-img">
              <v-icon v-if="this.selectedFile" size="30" color="black">mdi-check-bold</v-icon>
            </div>
          </div>
          <div v-if="uploadType==='youtube'">
            <span class="text-subtitle-2 font-weight-bold"> {{$t("link to YouTube")}} </span>
            <div class="d-flex py-2">
              <v-text-field
                dense
                class="input"
                v-model="ytUrl"
                :label="$t('YouTube link here')"
                single-line
                outlined
                required
                v-debounce:1000ms="onSearchYTTitle"
                :rules="youtubeRules"
              ></v-text-field>
              <div class="mx-5 mark-img">
                <v-icon v-if="this.youtubeRegEx.test(this.ytUrl)" size="30" color="black"
                  >mdi-check-bold</v-icon
                >
              </div>
            </div>
          </div>
          <div class="d-flex pt-5" >
              <span class="text-subtitle-2 font-weight-bold">
                {{$t("select thumbnail")}}
              </span>
            </div>
          <div  class="d-flex pt-2 pb-5">
            <div class="main-row">
              <div
                @dragenter.prevent="dropActiveThumbnail = true"
                @dragover.prevent="dropActiveThumbnail = true"
                @dragleave.prevent="dropActiveThumbnail = false"
                @drop="handleDropThumnail"
                :data-drop-active="dropActiveThumbnail"
              >
                <v-btn
                  :color="dropActiveThumbnail ? 'red' : '#616161'"
                  class="white--text"
                  tile
                  @click="$refs.thumbnail.click()"
                >
                {{ dropActiveThumbnail ? 'Drop Here' : $t("select an image") }}
                </v-btn>
              </div>
              <input
                type="file"
                ref="thumbnail"
                accept="image/*"
                @change="onPickThumbnail"
                style="display: none"
              />
              </div>
            <div class="mx-5 mark-img">
              <v-icon v-if="this.pickedThumbnail" size="30" color="black">mdi-check-bold</v-icon>
            </div>
          </div>
          <div v-if="pickedThumbnail" class="d-flex" >
              <img class="video-image" :src="selectedThumnailUrl" />
              <v-btn icon outlined class="mx-2" @click="clearThumbnail">
                <v-icon>mdi-close</v-icon>
              </v-btn>
          </div>
            <div v-if="thumbnailFilename" style="margin-bottom: 5%;">
            <span  class="caption mr-5" >{{ thumbnailFilename }} </span>
          </div>
          <span v-if="isAdminAllVideo" class="text-subtitle-2 font-weight-bold">{{$t("channel")}} </span>
          <div v-if="isAdminAllVideo" class="d-flex py-2">
            <v-autocomplete
              v-model="selectedChannels"
              :loading="channelsLoading"
              :items="channels"
              :search-input.sync="searchChannel"
              class="select-input"
              hide-no-data
              hide-details
              label=""
              outlined
              dense
              return-object
              chips
              deletable-chips
              multiple
              small-chips
              item-text="name"
              item-value="id"
              :placeholder="$t('type to search')"
            />
            <div class="mx-5 mark-img">
              <v-icon v-if="this.selectedChannels.length > 0" size="30" color="black"
                >mdi-check-bold</v-icon
              >
            </div>
          </div>

          <v-form class="main-row" ref="form" v-model="valid" lazy-validation>
            <span class="text-subtitle-2 font-weight-bold">{{$t("the title of the video")}}</span>
            <div class="d-flex py-2">
              <v-text-field
                dense
                class="input"
                v-model="title"
                :rules="titleRules"
                :label="$t('what will happen at the end of days')"
                single-line
                outlined
                required
                clearable
              ></v-text-field>
              <div class="mx-5 mark-img">
                <v-icon v-if="this.title" size="30" color="black"
                  >mdi-check-bold</v-icon
                >
              </div>
            </div>

            <span class="text-subtitle-2 font-weight-bold">{{$t("add to playlist")}} </span>
            <div class="d-flex py-2">
              <playlist-picker @onSelected="onSelected" :channelId="channelId" :filteredChannels="filteredChannels" />
              <div class="mx-5 mark-img">
                <v-icon v-if="this.selectedPlaylist.length > 0" size="30" color="black"
                  >mdi-check-bold</v-icon
                >
              </div>
            </div>
            <span class="text-subtitle-2 font-weight-bold"> {{$t("posting time")}} </span>
            <v-radio-group v-model="postSchedule">
              <div class="d-flex flex-column">
                <div class="d-flex align-center">
                  <v-radio
                    :key="1"
                    :value="1"
                    color="black"
                    class="custom-radio"
                  />
                  <span class="text-caption font-weight-bold">{{$t("now")}}</span>
                </div>
                <div class="d-flex mr-8">
                    <span class="text-caption"> {{$t("this video will be published in a few minutes")}} </span>
                </div>
              </div>
              <div class="d-flex flex-column mt-5">
                <div class="d-flex align-center">
                  <v-radio
                    :key="2"
                    :value="2"
                    color="black"
                    class="custom-radio"
                  />
                  <span class="text-caption font-weight-bold">{{$t("timing")}} </span>
                </div>
                <div class="d-flex mr-8">
                    <span class="text-caption">{{$t("determine when the video will be published")}}</span>
                </div>
              </div>
            </v-radio-group>

            <div v-if="postSchedule === 2" class="d-flex">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    class="date-input"
                    append-icon="mdi-menu-down"
                    persistent-hint
                    dense
                    outlined
                    single-line
                    v-bind="attrs"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    class="date-input"
                    append-icon="mdi-menu-down"
                    readonly
                    dense
                    single-line
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeMenu"
                  v-model="time"
                  full-width
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
              <v-select
                  v-model="selectedTimezone"
                  :items="timezoneLits"
                  item-text="name"
                  item-value="zone"
                  :label="$t('choose')"
                  dense
                  outlined
                  single-line
                  return-object
                  class="timezone-input"
              />
            </div>

            <span class="text-subtitle-2 font-weight-bold">{{$t("tags")}} </span>
            <div class="d-flex py-2">
              <v-text-field
                dense
                class="input"
                v-model="currentTag"
                single-line
                outlined
                required
                hide-details
                :disabled="selectedTags.length>2"
                v-on:keyup="tagKeydown"
              ></v-text-field>
              <div class="mx-5 mark-img">
              </div>
            </div>
            <span class="text-caption grey--text pb-2">{{$t("example tags")}}</span>
            <div class="d-flex flex-wrap">
                <v-chip
                class="ma-2"
                close
                v-for="(item, index) in selectedTags"
                :key="index"
                @click:close="onCloseTag(index)"
                >
                {{item}}
                </v-chip>
            </div>
            <span v-if="isAdminAllVideo" class="text-subtitle-2 font-weight-bold">{{$t("category")}} </span>
            <div v-if="isAdminAllVideo" class="d-flex py-2">
              <v-select
                class="select-input"
                v-model="selectedCategory"
                :items="categories"
                item-text="name"
                item-value="id"
                :label="$t('choose')"
                dense
                outlined
                single-line
                return-object
              >
              </v-select>
              <div class="mx-5 mark-img">
                <v-icon v-if="this.selectedCategory.id" size="30" color="black"
                  >mdi-check-bold</v-icon
                >
              </div>
            </div>
            <span v-if="isAdminAllVideo" class="text-subtitle-2 font-weight-bold">{{$t("location")}} </span>
            <div v-if="isAdminAllVideo" class="d-flex py-2">
              <v-select
                class="select-input"
                v-model="selectedPosition"
                :items="positions"
                item-text="name"
                item-value="id"
                :label="$t('choose')"
                dense
                outlined
                single-line
                return-object
              />
              <div class="mx-5 mark-img">
                <v-icon v-if="this.selectedPosition" size="30" color="black"
                  >mdi-check-bold</v-icon
                >
              </div>
            </div>
            <div class="d-flex py-2">
              <div class="d-flex justify-end mt-4">
                <v-btn
                  tile
                  color="#388E3C"
                  width="140"
                  class="white--text"
                  @click="this.prepareVideoUpload"
                  :loading="this.loading"
                  :disabled="this.loading || !this.uploadValid">
                  {{$t("routine")}}!
                </v-btn>
              </div>
              <div class="mx-5 mark-img" />
            </div>
            </v-form>
            <!--
              <span class="text-caption grey--text">
                *{{$t("it takes several hours for content")}}.
              </span>
            -->
        </div>
        <div class="py-5" v-else>
          <div v-if="uploadingStatus==1" class="main-row mb-5 d-flex flex-column">
            <div>
              <span class="h4">{{$t('video')}} </span>
              <span class="h4 font-weight-bold">״ {{ this.title }} ״</span>
              <span class="h4">{{$t("will rise")}}</span>
            </div>
            <span class="h4"> {{$t("in the next few minutes")}}  !</span>
          </div>
          <div v-if="uploadingStatus==2" class="main-row mb-5 d-flex flex-column">
            <span class="h4"> {{$t("the video exists in the system")}} </span>
          </div>
          <div v-if="uploadingStatus==3" class="main-row mb-5 d-flex flex-column">
            <span class="h4 red--text">שגיאה בהעלאת הוידאו, נא לנסות שוב יותר מאוחר</span>
          </div>
          <v-btn
            color="#616161"
            class="white--text"
            tile
            width="140"
            @click="this.onNextVideo">
            {{$t("upload another video")}}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/api/api";
import axios from "axios";
import moment from 'moment';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import timezones from "../../config/timezone.js";
import PlaylistPicker from '../playlist/PlaylistPicker.vue';

export default {
  components: { PlaylistPicker },
  data() {
    let languages = [
        {name: this.$t("hebrew"), val: "he"},
        {name: this.$t("english"), val: "en"},
    ];
    return {
      youtubeRegEx: /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
      valid: true,
      fileName: "",
      thumbnailFilename: "",
      title: "",
      ytThumbUrl: "",
      duration: 0,
      publishedAt: "",
      titleRules: [(v) => !!v || "כותרת נדרשת"],
      youtubeRules: [(v) => !!v || "נדרשת כתובת אתר של YouTube",
        (v) => this.youtubeRegEx.test(v) || "Invalid Youtube URL" 
      ],
      postSchedule: 1,
      time: null,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      timeMenu: false,
      dateMenu: false,
      selectedFile: undefined,
      pickedThumbnail: undefined,
      selectedThumnailUrl: undefined,
      signedUrl: undefined,
      thumbnailSignedUrl: undefined,
      error: false,
      showResult: false,
      result: "",
      loading: false,
      uploadingStatus: 0, // 0: Prepare, 1: Success, 2: Fail
      uploadType: "internal", // Available values - youtube, internal
      channelId: this.$route.params.channel_id,
      categories: [],
      selectedCategory: {id: null, name: 'Other'},
      ytUrl: "",
      positions: [...Array(25).keys()].map(p => p + 1),
      selectedPosition: undefined,
      channels: [],
      selectedChannels: [],
      currentTag: null,
      selectedTags: [],
      timezoneLits: timezones,
      selectedTimezone: timezones[0],
      channelsLoading: false,
      selectedPlaylist: [],
      languages: languages,
      selectedLang: languages[0].val,
      searchChannel: null,
      dropActive: false,
      dropActiveThumbnail: false,
    };
  },

  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.channelId = to.params.channel_id;
        this.initLoad();
      }
    },
    date () {
      this.dateFormatted = this.formatDate(this.date)
    },
    selectedLang: {
      handler: function() {
        this.initLoad();
      }
    },
    searchChannel(val) {
      if (this.selectedChannels.includes(val)) {
        return;
      }
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.queryChannel(val);
      }, 1000);
    },
  },

  mounted() {
    this.initLoad();
  },

  computed: {
    uploadValid() {
      let isValid = false;
      if (this.channelId) {
        isValid = (this.selectedFile || this.youtubeRegEx.test(this.ytUrl)) && this.title != "" && this.vailidateScheduledDate();
      } else {
        isValid = (this.selectedFile || this.youtubeRegEx.test(this.ytUrl)) && this.title != "" && this.vailidateScheduledDate() && this.selectedChannels.length > 0;
      }
      // if (this.uploadType === 'internal') {
      //     isValid = isValid && this.pickedThumbnail;
      //   }
      return isValid;
    },
    isAdmin() {
      return this.$store.getters.getUser.is_admin
    },
    isAdminAllVideo() {
      return this.$store.getters.getUser.is_admin && !this.channelId
    },
    filteredChannels() {
      return this.channelId ? [{ id: Number(this.channelId) }] : this.selectedChannels;
    }
  },

  methods: {
    initLoad() {
      if (this.channelId) {
        this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      }
      this.selectedFile = undefined;
      this.title = "";
      this.fileName = "";
      this.ytThumbUrl = "";
      this.duration = "";
      this.publishedAt = "";

      this.pickedThumbnail = undefined;
      this.thumbnailFilename = "";
      this.getCategories();
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    vailidateScheduledDate () {
      if (this.postSchedule === 1) return true;
      if (!this.date || !this.time) return false;
      if (new Date() > this.getScheduleDate()) return false
      
      return true;
    },
    async convertToMp4(file) {
      const supportedTypes = ['video/mp4', 'video/mov', 'video/m4v'];
      if (supportedTypes.includes(file.type)) {
        return;
      }
      const ffmpeg = createFFmpeg({ log: true, corePath: 'https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js', });
      if (!ffmpeg.isLoaded()) {
        await ffmpeg.load();
      }
      const fileName = file.name;
      const outputFileName = 'output.mp4';

      ffmpeg.FS('writeFile', fileName, await fetchFile(file));
      await ffmpeg.run('-i', fileName, outputFileName);
      const data = ffmpeg.FS('readFile', outputFileName);
      this.selectedFile = new Blob([data.buffer], { type: 'video/mp4' });
    },
    async handleDrop(e) {
      e.preventDefault();
      this.dropActive = false;
      this.selectedFile = e.dataTransfer.files[0];
      this.fileName = e.dataTransfer.files[0].name;
      // this.title = this.$helpers.removeExtension(this.fileName);
    },
    async selectFile() {
      this.selectedFile = this.$refs.file.files[0];
      this.fileName = this.$refs.file.files[0].name;
      // this.title = this.$helpers.removeExtension(this.fileName);
    },
    handleDropThumnail(e) {
      e.preventDefault();
      this.dropActiveThumbnail = false;
      this.pickedThumbnail = e.dataTransfer.files[0];
      this.thumbnailFilename = e.dataTransfer.files[0].name;
    },
    onPickThumbnail() {
      this.pickedThumbnail = this.$refs.thumbnail.files[0];
      this.thumbnailFilename = this.$refs.thumbnail.files[0].name;
      this.selectedThumnailUrl = URL.createObjectURL(this.$refs.thumbnail.files[0]);
    },
    async getCategories() {
      await api.getVideoCategories({ sort: 'name' }, this.selectedLang)
        .then((res) => {
          this.categories = res.data.categories;
          this.categories.unshift({id: null, name: 'אַחֵר'});
        })
        .catch((err) => {
          console.log(err);
        })
    },
    async queryChannel(val) {
      this.channelsLoading = true;
      api
        .searchChannels(val)
        .then((res) => {
          this.channels = [...this.selectedChannels, ...res.data.items];
          this.channelsLoading = false;
        })
        .catch((error) => {
          this.channelsLoading = false;
          console.log(error);
        });
    },
    async getSignedUrl() {
      // TODO
      let _channelId = this.channelId ? this.channelId : -1;
      await api
        .getVideoStorageSignedUrl(_channelId, this.selectedFile.type)
        .then((res) => {
          this.signedUrl = res.data.url;
        })
        .catch((err) => {
            console.log(err);
            this.uploadingStatus = 3;
            throw err;
        });
    },
    async uploadFileToSignedUrl(signedUrl, selectedFile) {
      try {
        await axios.put(signedUrl, selectedFile, {
          headers: { "Content-Type": selectedFile.type },
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getImageSignedUrl() {
      await api
        .getImageStorageSignedUrl(this.channelId,this.pickedThumbnail.type)
        .then((res) => {
          this.thumbnailSignedUrl = res.data.url;
        })
        .catch((err) => {
            console.log(err);
            throw err;
        });
    },

    tagKeydown(e) {
      if (e.keyCode === 13) {
        if (this.currentTag && this.selectedTags.length < 3) {
          this.selectedTags.push(this.currentTag);
          this.currentTag = null;
        }
      }
    },

    onCloseTag(index) {
      this.selectedTags.splice(index, 1)
    },

    getScheduleDate() {
      const strISO8061 =  moment.tz(`${this.date} ${this.time}`, this.selectedTimezone.zone).toISOString();
      return new Date(strISO8061);
    },

    // limitChannelSelector() {
    //   if (this.selectedChannels.length > 3) this.selectedChannels.pop();
    // },

    async prepareVideoUpload() {
      this.loading = true;
      try {
        if (this.uploadType === 'internal') {
          // await this.convertToMp4(this.selectedFile);
          await this.getSignedUrl();
          await this.uploadFileToSignedUrl(this.signedUrl, this.selectedFile);
        }
        if (this.pickedThumbnail) {
          await this.getImageSignedUrl();
          await this.uploadFileToSignedUrl(this.thumbnailSignedUrl, this.pickedThumbnail); 
        }
      
        this.addVideo();
      } catch (error) {
        console.error('Error uploading video', error);
        this.loading = false;
        this.uploadingStatus = 3;
      }
    },

    addVideo() {
      const params = {
        video: {
          title: this.title,
          channel_ids: this.selectedChannels.length > 0 ? this.selectedChannels.map((e) => e.id) : [Number(this.channelId)],
          platform: this.uploadType,
          position: this.selectedPosition,
          language: this.selectedLang,
        },
      };
      if (this.uploadType === 'internal') {
        params.video['link'] = this.signedUrl.split("?")[0];
        // params.video['is_big_size'] = this.selectedFile.size > 20971520;
        // > 300MB
        params.video['is_big_size'] = this.selectedFile.size > 314572800;
        
      } else {
        params.video['link'] = this.ytUrl;
        params.video['yt_thumb_url'] = this.ytThumbUrl;
        params.video['duration'] = this.duration;
        params.video['published_at'] = this.publishedAt;
      }
      if (this.pickedThumbnail) { 
        params.video['thumb_url'] = this.thumbnailSignedUrl.split("?")[0];
      }
      if (this.postSchedule === 2) {
        params.video['scheduled_at'] = this.getScheduleDate().toISOString();
      }
      if (this.selectedCategory.id) {
        params.video['category_id'] = this.selectedCategory.id;
      }
      if (this.selectedTags.length > 0) {
        params.video['tags'] = this.selectedTags;
      }
      if (this.selectedPlaylist.length > 0) {
        params.video['playlist_ids'] = this.selectedPlaylist.map((o) => o.id);
      }
      api
        .postVideo(params)
        .then((res) => {
          if (res.data) {
            if (res.data.status == "ok") {
              this.uploadingStatus = 1;
            } else {
              this.uploadingStatus = 2;
            }
          }
          this.loading = false;
        })
        .catch((err) => {
            console.log(err);
            this.loading = false;
            this.uploadingStatus = 3;
            throw err;
        });
    },
    onNextVideo() {
      this.uploadingStatus = 0;
      this.signedUrl = null;
      this.thumbnailSignedUrl = null;
      this.selectedFile = undefined;
      this.pickedThumbnail = undefined;
      this.selectedThumnailUrl = undefined;
      this.title = "";
      this.ytThumbUrl = "";
      this.duration = 0;
      this.publishedAt = "";
      this.fileName = "";
      this.thumbnailFilename = "";
      this.ytUrl = ""
    },
    onSelected(playlist) {
      this.selectedPlaylist = playlist;
    },
    async onSearchYTTitle() {
      try {
        const videoId = this.extractVideoId(this.ytUrl);
        if (!videoId) {
          console.error('Invalid YouTube link');
          return;
        }

        const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
          params: {
            key: process.env.VUE_APP_YOUTUBE_KEY,
            part: 'snippet,contentDetails,status',
            id: videoId,
          },
        });

        if (response.data.items.length > 0) {
          const video = response.data.items[0]
          // Check if the video is processed
          // console.error(video, "video");
          if (video.status.uploadStatus != 'processed'){
            console.error('Video is not ready');
            return;
          }
        
          if (video){
            this.title = video.snippet.title;
            this.duration = video.contentDetails.duration;
            this.publishedAt =video.snippet.publishedAt;
            if(video.snippet.thumbnails.standard){
              this.ytThumbUrl = video.snippet.thumbnails.standard.url;
            }else{
              if( video.snippet.thumbnails.default){
                this.ytThumbUrl = video.snippet.thumbnails.default.url;
              }
            }
          }
          
        } else {
          console.error('Video not found');
          return
        }
      } catch (error) {
        console.error('Error fetching video title', error);
      }
    },
    extractVideoId(url) {
      const match = url.match(/(?:\/|v=)([a-zA-Z0-9_-]{11})(?:\?|$)/);
      return match ? match[1] : null;
    },
    clearThumbnail() {
      this.pickedThumbnail = undefined;
      this.selectedThumnailUrl = null;
      this.thumbnailFilename = "";
      this.$refs.thumbnail.value = "";
    }
  },
};
</script>

<style scoped>
.main-row {
  flex: 1;
}
.input {
  border-radius: 0px;
  font-size: 12px;
}
.select-input {
  border-radius: 0px;
}
.date-input {
  border-radius: 0px;
  font-size: 12px;
  min-width: 100px;
  max-width: 150px;
  margin-left: 20px !important;
}
.mark-img {
  width: 70px;
  justify-content: center;
  display: flex;
  align-items: flex-start;
}
.custom-radio{
  margin-bottom: 0px !important;
}
.test{
  border: 1px solid red;
}
.timezone-input {
  border-radius: 0px;
  max-width: 200px;
  font-size: 12px;
  margin-left: 20px !important;
}
.lang-select {
  border-radius: 0px;
  max-width: 200px;
}
.video-image {
  height: 120px;
  aspect-ratio: 16 / 9;
  background: black;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
}
</style>