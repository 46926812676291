
<template>
  <v-container fluid>
    <v-row class="px-5 flex-column">
      <v-col xl="10" lg="10" md="12" sm="12" xs="12" cols="12">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              outlined
              dense
              v-model="searchEmail"
              v-debounce="getData"
              :label="$t('search by email')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <span v-if="users && !users.length" class="text-subtitle-2">
      {{$t('there are no users')}}</span>
      <v-col v-else xl="12" lg="12" md="12" sm="12" xs="12" cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('id')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('username')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('anonymous')}}?</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('email')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('join')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('gender')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('device')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('through registration')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('app version')}}</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold"> {{$t('follow up channels')}}</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold"> {{$t('views')}}</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('operations')}}</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr :key="user.id" v-for="user in users">
                <td>
                  <div class="d-flex justify-right">
                    {{ user.id }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ user.name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ user.is_anonymous ? "כן" : "" }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{
                      user.email.replace(
                        /^(.)(.*)(.@.*)$/,
                        (_, a, b, c) => a + b.replace(/./g, "*") + c
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ moment(user.created_at).format("DD/MM/YYYY") }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ user.gender }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ user.device }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ user.sso_provider }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ user.app_version }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ user.follows }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ user.views }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <v-btn
                      outlined
                      small
                      @click="
                        $router.push({
                          name: 'EditUser',
                          params: { id: user.id },
                        })
                      "
                    >
                      {{$t('edit')}}
                    </v-btn>
                     <v-btn
                      class="mx-2"
                      outlined
                      small
                      @click="onShowNotificationDlg(user)"
                    >
                      {{$t('send a message')}}
                    </v-btn>
                    <v-btn
                      class="mx-2"
                      outlined
                      small
                      @click="onShowDialogResetPassword(user)"
                    >
                      {{$t('reset password')}}
                    </v-btn>
                    <v-btn
                      v-if="user.id !== $store.getters.getUser.id"
                      outlined
                      small
                      @click="onShowDialogDeleteUser(user)"
                    >
                      {{$t('delete')}}
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showNotificationDlg"
      persistent
      max-width="480"
    >
      <v-card class="pa-10">
        <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
           {{$t('send a message')}}
        </v-card-title>
        <div>
          <div class="d-flex align-center">
              <span class="ml-5 text-subtitle-2">{{$t('title')}}</span>
              <v-text-field
                v-model="messageTitle"
                dense
                class="input"
                hide-details
                no-gutters
                single-line
                outlined
              />
          </div>
          <div class="d-flex align-center mt-5">
            <span class="ml-8 text-subtitle-2">{{$t('content')}}</span>
            <v-textarea
              v-model="messageBody"
              class="input"
              no-gutters
              hide-details
              outlined
              rows="2"
            />
          </div>
        </div>
        
        <v-card-actions class="d-flex justify-space-between px-0 mt-2">
          <v-btn
            class="white--text flex-grow-1"
            color="#616161"
            tile
            x-large
            @click="this.onHideNotificationDlg"
          >
            <span class="text-h5">{{$t('cancel')}}</span>
          </v-btn>
          <v-btn
            class="white--text flex-grow-1"
            color="#ff0000"
            tile
            x-large
            @click="this.onMessageSend"
            :loading="this.messageLoading"
            :disabled="this.messageLoading || (!this.messageTitle || !this.messageBody)"
          >
            <span class="text-h5">{{$t('ok')}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="true" v-if="showDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h5">
          {{$t('reset password')}}
        </v-card-title>
        <v-card-text>
          {{$t('we will send the reset link to user email')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="onCancelResetPassword" :disabled="requestLoading">
            {{$t('cancel')}}
          </v-btn>
          <v-progress-circular v-if="requestLoading" indeterminate color="secondary" size="20" />
          <v-btn v-else color="green darken-1" text @click="onConfirmResetPassword" :disabled="requestLoading">
            {{$t('ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="true" v-if="showDialogDeleteUser" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{$t('delete')}}
        </v-card-title>
        <v-card-text>
          {{$t('are you sure')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1" text @click="this.onCancelDelete">
            {{$t('cancel')}}
          </v-btn>

          <v-btn color="green darken-1" text @click="this.onConfirmDelete">
            {{$t('delete')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="text-center">
      <v-pagination
        color="red"
        v-model="page"
        total-visible="6"
        @input="getData"
        :length="Math.ceil(total / limit)"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  data() {
    return {
      users: null,
      searchEmail: "",
      limit: 20,
      total: 0,
      total_android: 0,
      total_ios: 0,
      total_web: 0,
      page: 1,
      showNotificationDlg: false,
      selectedUser: null,
      messageTitle: '',
      messageBody: '',
      messageLoading: false,
      showDialog: false,
      requestLoading: false,
      showDialogDeleteUser: false,
    };
  },
  watch: {
    
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        email: this.searchEmail,
      };
      api
        .getUsers(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.users.length > 0) {
            this.users = res.data.users;
            this.total = res.data.total;
            this.total_android = res.data.total_android;
            this.total_ios = res.data.total_ios;
            this.total_web = res.data.total_web;
          } else {
            this.users = [];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
    onShowNotificationDlg(user) {
      this.showNotificationDlg = true;
      this.selectedUser = user;
    },
    onHideNotificationDlg() {
      this.showNotificationDlg = false;
      this.selectedUser = null;
      this.messageTitle = '';
      this.messageBody = '';
      this.messageLoading = false;
    },
    onMessageSend() {
      if (!this.selectedUser) {
        this.onHideNotificationDlg();
        return;
      }
      this.messageLoading = true;
      const body = {
        title: this.messageTitle,
        body: this.messageBody
      };
      this.messageLoading = true;
      api.sendNotification(this.selectedUser.id, body)
        .then(() => {
          this.messageLoading = false;
          this.onHideNotificationDlg();
        })
        .catch((err) => {
          console.log(err);
          this.messageLoading = false;
          this.onHideNotificationDlg();
        });
    },
    onShowDialogResetPassword(user) {
      this.showDialog = true;
      this.selectedUser = user;
    },
    onCancelResetPassword() {
      this.showDialog = false;
      this.selectedUser = null;
    },
    onConfirmResetPassword() {
      if (!this.selectedUser) {
        this.showDialog = false;
        return;
      }
      this.requestLoading = true;
      api.resetPassword(this.selectedUser.email)
        .then(() => {
          console.log('ok')
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.onCancelResetPassword();
          this.requestLoading = false;
        });
    },
    onShowDialogDeleteUser(user) {
      this.showDialogDeleteUser = true;
      this.selectedUser = user;
    },
    onCancelDelete() {
      this.showDialogDeleteUser = false;
      this.selectedUser = null;
    },
    onConfirmDelete() {
      if (!this.selectedUser) {
        this.showDialogDeleteUser = false;
        return;
      }
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .deleteUser(this.selectedUser.id)
        .then(() => {
          this.users = this.users.filter((user) => user.id !== this.selectedUser.id);
        })
        .catch((error) => {
          console.log(error)
          this.getData();
        })
        .finally(() => {
          this.selectedUser = null;
          this.showDialogDeleteUser = false;
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
  },
};
</script>
