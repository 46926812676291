<template>
    <v-container fluid>
        <v-row class="pa-2">
            <v-col lg="5" md="5" sm="5" xs="12">
                <v-form class="mt-4">
                     <span>גרסא</span>
                    <v-text-field
                        dense
                        class="input"
                        v-model="version"
                        :rules="nameRules"
                        label="שם הגרסא"
                        single-line
                        outlined
                        required
                    ></v-text-field>

                    <div>
                        <span>הצג עדכון?</span>
                        <v-switch
                        dense
                        color="success"
                        inset
                        class="mt-0"
                        v-model="force_update"
                        :label="force_update ? 'פעיל' : 'כבוי' "
                        ></v-switch>
                    </div>
                </v-form>
                 <div class="d-flex justify-end mt-6">
                    <v-btn
                        tile
                        color="#388E3C"
                        width="140"
                        class="white--text"
                        @click="addVersion()"
                        :loading="this.loading"
                        :disabled="this.loading || !this.version"
                        >שמור</v-btn
                    >
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from "@/api/api";

export default {
    data() {
        return {
            version: "",
            force_update: false,
            nameRules: [(v) => !!v || "Version is required"],
            loading: false,
        }
    },

    methods: {
        addVersion() {
            this.loading = true;
            const body = {
                app_version: {
                    version: this.version,
                    force_update: this.force_update,
                }
            };
            api.createAppVersion(body)
                .then(() => {
                    this.loading = false;
                    this.$router.push({name: 'Versions'})
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
        }
    }
}

</script>

<style scoped>

</style>