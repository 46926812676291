import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("new playlist"))+" ")]),_c(VCardText,[_c('div',[_c('span',{staticClass:"label text-caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("title"))+" (*) ")]),_c(VTextField,{attrs:{"single-line":"","outlined":"","required":"","dense":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('div',[_c('span',{staticClass:"label text-caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("description"))+" ")]),_c(VTextarea,{attrs:{"outlined":"","dense":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c(VAutocomplete,{attrs:{"items":_vm.filteredChannels,"label":_vm.$t('channels'),"item-text":"name","item-value":"id","chips":"","small-chips":"","deletable-chips":"","outlined":"","dense":"","clearable":"","persistent-hint":"","return-object":"","search-input":_vm.search,"filter":_vm.customFilter,"loading":_vm.isLoading,"placeholder":"Type to search more"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.selectedChannel),callback:function ($$v) {_vm.selectedChannel=$$v},expression:"selectedChannel"}})],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","disabled":_vm.disabled,"loading":_vm.saving},on:{"click":function($event){return _vm.confirm(true)}}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.confirm(false)}}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }