
import api from '@/api/api'

const Channel = {
    state: {
        channels: [],
        activeChannelId: null,
    },

    mutations: {
        setChannels: function (state, channels) {
            state.channels = channels
        },
        setActiveChannelId: function (state, id) {
            state.activeChannelId = id
        }
    },

    getters: {
        getChannels: function (state) {
            return state.channels
        },
        getActiveChannelId: function (state) {
            if (!state.activeChannelId && state.channels.length > 0) {
                return state.channels[0].channel_id;
            }
            return state.activeChannelId
        },
        getActiveChannel: function (state) {
            if (state.channels.length > 0 && state.activeChannelId) {
                return state.channels.find(channel => channel.channel_id == state.activeChannelId)
            }
            return null
        },

    },

    actions: {
        FETCH_CHANNEL: function ({ commit }) {
            return new Promise((resolve, reject) => {
                api.getChannels()
                    .then(res => {
                        if (res.data.channels.length > 0) {
                            commit('setChannels', res.data.channels)
                            resolve(res)
                        } else {
                            localStorage.removeItem('token')
                            reject("not found")
                        }
                    })
                    .catch(err => {
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        SET_ACTIVE_CHANNEL_ID: function (context, id) {
            context.commit('setActiveChannelId', id)
        },

        SET_CHANNELS: function (context, channels) {
            context.commit('setChannels', channels)
        },

        LOGOUT: function (context) {            
            context.commit('setChannels', [])
        }
    }
}

export { Channel as default }