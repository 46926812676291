
<template>
  <v-container fluid>
    <v-row class="d-flex justify-end pa-5">
      <v-btn color="#ff0000" class="white--text" tile  @click="$router.push({ path: `/channels/add` })">
        + {{$t('new channel')}} 
      </v-btn>
    </v-row>
    <v-row class="px-5 flex-column">
      <!-- <span v-if="channels && !channels.length" class="text-subtitle-2">
      {{$t('no channel') }}
      </span> -->
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6" md="6" class="d-flex">
            <v-autocomplete v-model="selectedChannel" :items="items" :loading="isLoading" :search-input.sync="search"
              color="" clearable hide-no-data hide-selected item-text="name" item-value="id" dense :label="$t('search by name')"
              outlined :placeholder="$t('type to search')" return-object></v-autocomplete>
            <v-select item-text="key" item-value="value" dense outlined :items="isHotOptions" :label="$t('hot')"
              v-model="filterHot"></v-select>
            <v-select item-text="key" item-value="value" dense outlined :items="isOnoOptions" label="O&O?"
              v-model="filterOno"></v-select>
          </v-col>
        </v-row>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td>
                  <span class="text-subtitle-2 font-weight-bold"></span>
                </td>
                <td class="row-pointer" @click="changeSort('title')">
                  <span class="text-subtitle-2 font-weight-bold">
                    {{$t('channel name')}}
                  </span>
                  <v-icon color="red"
                    v-if="sortBy == 'title' && sortDir == 'asc'">mdi-arrow-up-bold-box-outline</v-icon>
                  <v-icon color="red"
                    v-else-if="sortBy == 'title' && sortDir == 'desc'">mdi-arrow-down-bold-box-outline</v-icon>
                </td>
                <td class="row-pointer" @click="changeSort('followers')">
                  <span class="text-subtitle-2 font-weight-bold"> {{$t('followers')}} </span>
                  <v-icon color="red"
                    v-if="sortBy == 'followers' && sortDir == 'asc'">mdi-arrow-up-bold-box-outline</v-icon>
                  <v-icon color="red"
                    v-else-if="sortBy == 'followers' && sortDir == 'desc'">mdi-arrow-down-bold-box-outline</v-icon>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">O & O</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">OG?</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">{{$t('hot')}}</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">{{$t('videos')}}</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">{{$t('last publication')}}</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">{{$t('production date')}} </span>
                </td>
                <td class="row-pointer" @click="changeSort('views')">
                  <span class="text-subtitle-2 font-weight-bold">
                     {{$t('video views')}}
                  </span>
                  <v-icon color="red"
                    v-if="sortBy == 'views' && sortDir == 'asc'">mdi-arrow-up-bold-box-outline</v-icon>
                  <v-icon color="red"
                    v-else-if="sortBy == 'views' && sortDir == 'desc'">mdi-arrow-down-bold-box-outline</v-icon>
                </td>
                <td class="row-pointer" @click="changeSort('visits')">
                  <span class="text-subtitle-2 font-weight-bold">
                     {{$t('page views')}}
                  </span>
                  <v-icon color="red"
                    v-if="sortBy == 'visits' && sortDir == 'asc'">mdi-arrow-up-bold-box-outline</v-icon>
                  <v-icon color="red"
                    v-else-if="sortBy == 'visits' && sortDir == 'desc'">mdi-arrow-down-bold-box-outline</v-icon>
                </td>
                <td class="row-pointer" @click="changeSort('shares')">
                  <span class="text-subtitle-2 font-weight-bold">
                    {{$t('video sharing')}} 
                  </span>
                  <v-icon color="red"
                    v-if="sortBy == 'shares' && sortDir == 'asc'">mdi-arrow-up-bold-box-outline</v-icon>
                  <v-icon color="red"
                    v-else-if="sortBy == 'shares' && sortDir == 'desc'">mdi-arrow-down-bold-box-outline</v-icon>
                </td>
                <td class="row-pointer" @click="changeSort('user_duration')">
                  <span class="text-subtitle-2 font-weight-bold">
                    {{$t('average viewing time')}}
                  </span>
                  <v-icon color="red"
                    v-if="sortBy == 'user_duration' && sortDir == 'asc'">mdi-arrow-up-bold-box-outline</v-icon>
                  <v-icon color="red"
                    v-else-if="sortBy == 'user_duration' && sortDir == 'desc'">mdi-arrow-down-bold-box-outline</v-icon>
                </td>
                <td class="row-pointer" @click="changeSort('duration')">
                  <span class="text-subtitle-2 font-weight-bold">
                    {{$t('total viewing time')}}
                  </span>
                  <v-icon color="red"
                    v-if="sortBy == 'duration' && sortDir == 'asc'">mdi-arrow-up-bold-box-outline</v-icon>
                  <v-icon color="red"
                    v-else-if="sortBy == 'duration' && sortDir == 'desc'">mdi-arrow-down-bold-box-outline</v-icon>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold"> {{$t('connecting to networks')}} </span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">{{$t('turn on')}}</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">{{$t('operations')}}</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr :key="channel.id" v-for="channel in channels" :class="channel.hidden ? 'hidden-row' : ''">
                <td>
                  <div class="d-flex justify-right">
                    <v-img :src="channel.image" class="channel-image" height="50" width="50" contain />
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ channel.name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ channel.followers_count }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <v-switch
                      @change="toggleOnOSetting(channel)"
                      v-model="channel.o_n_o"
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <span>{{ channel.og ? 'yes' : 'no' }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <v-switch
                      @change="toggleHotSetting(channel)"
                      v-model="channel.is_hot"
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <router-link :to="`/channels/${channel.id}/library`">{{
                      channel.videos_count
                    }}</router-link>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ moment(channel.last_published).fromNow() }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ moment(channel.created_at).format("DD/MM/YYYY") }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ channel.views }} 
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ channel.channel_visits }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ channel.channel_shares }}
                  </div>
                </td>
                <td>
                  <div v-if="channel.watch_duration && channel.watch_duration.length > 1" class="d-flex justify-right">
                    {{ channel.watch_duration[1].toString().toHHMMSS(true) }}
                  </div>
                </td>
                <td>
                  <div v-if="channel.watch_duration && channel.watch_duration.length > 0" class="d-flex justify-right">
                    {{ channel.watch_duration[0].toString().toHHMMSS(true) }}
                  </div>
                </td>
                <td>
                  <div v-if="channel.yt_id" class="d-flex justify-right">
                    {{ channel.yt_id }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <v-switch
                      @change="toggleYtSetting(channel)"
                      v-model="channel.yt_enabled"
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <v-btn outlined small @click="
                      $router.push({ path: `/channels/${channel.id}/edit` })
                    ">
                      {{$t('edit')}}
                    </v-btn>
                    <v-btn outlined small @click="toggleHideChannel(channel)">
                      {{ channel.hidden ? $t('show') : $t('hide') }}
                    </v-btn>
                    <v-btn outlined small @click="openSetAdminDialog(channel)">
                      {{$t('channel permissions')}}
                    </v-btn>
                    <v-btn class="mr-2" outlined small @click="destroyChannel(channel)">
                      {{ $t('delete') }}
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-pagination color="red" v-model="page" total-visible="6" @input="getData"
        :length="Math.ceil(total / limit)"></v-pagination>
    </div>
    <v-dialog :value="true" v-if="setAdminDialog.open" max-width="1024" persistent>
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('set admin for channel') }} "{{ setAdminDialog.channel.name }}"
        </v-card-title>
        <v-divider />
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            top
            color="red"
        ></v-progress-linear>
        <v-card-text>
          <h3 class="mt-4 mb-2">{{ $t('current admins') }}</h3>
          <p v-if="!admins.length">{{ $t('no admin') }}</p>
          <v-simple-table v-else dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('name') }}
                  </th>
                  <th class="text-left">
                    {{ $t('email') }}
                  </th>
                  <th class="text-left">
                    {{ $t('role') }}
                  </th>
                  <th class="text-left">
                    {{ $t('status') }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in admins"
                  :key="item.id"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.role }}</td>
                  <td>
                    <v-chip small>{{ item.status }}</v-chip>
                  </td>
                  <td>
                    <div class="d-flex justify-right">
                    <v-btn class="mr-2" outlined x-small color="error" :disabled="loading" @click="handleRevoke(item)">revoke</v-btn>
                    <v-btn outlined x-small v-if="item.status !== 'approved'" :disabled="loading" @click="handleResend(item)">re-send</v-btn>
                  </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <h3 class="mt-4 mb-4">{{ $t('set a new admin') }}</h3>
          <v-combobox
            dense
            v-model="pickedUser"
            :items="users"
            :search-input.sync="searchEmail"
            :label="$t('email')"
            outlined
            small-chips
            clearable
            return-object
            item-text="email"
            item-value="id"
            deletable-chips
            :hint="hint"
            persistent-hint
          />
          <v-radio-group v-model="role" :label="$t('role')" :style="{ 'text-align': $store.getters.getLanguage == 'he' ? 'right' : 'left' }">
            <v-radio :label="$t('admin')" value="admin" />
            <v-radio :label="$t('editor')" value="editor" />
          </v-radio-group>
          <p class="caption">
            {{ $t('editor explaination') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="closeSetAdminDialog" :disabled="loading">{{ $t('cancel') }}</v-btn>
          <v-btn color="blue darken-1" text @click="handleSetAdmin" :disabled="loading || !pickedUser">{{ $t('confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="true" v-if="showDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{$t('delete')}}
        </v-card-title>
        <v-card-text>
          {{$t('are you sure')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1" text @click="this.onCancelDelete">
            {{$t('cancel')}}
          </v-btn>

          <v-btn color="green darken-1" text @click="this.onConfirmDelete">
            {{$t('delete')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  data() {
    return {
      isLoading: false,
      selectedChannel: null,
      search: null,
      entries: [],
      nameLimit: 50,
      isHotOptions: [
        { key: "הכל", value: null },
        { key: "כן", value: true },
        { key: "לא", value: false },
      ],
      isOnoOptions: [
        { key: "הכל", value: null },
        { key: "כן", value: true },
        { key: "לא", value: false },
      ],
      channels: null,
      limit: 10,
      total: 0,
      filterHot: null,
      filterOno: null,
      sortBy: "title",
      sortDir: "asc",
      page: 1,
      showDialog: false,
      setAdminDialog: {
        open: false,
        channel: null,
      },
      channelToDelete: null,
      users: [],
      pickedUser: null,
      role: 'admin',
      searchEmail: '',
      admins: [],
      debounce: null,
      loading: false,
    };
  },
  computed: {
    items() {
      return this.entries.map((entry) => {
        const name =
          entry.name.length > this.nameLimit
            ? entry.name.slice(0, this.nameLimit) + "..."
            : entry.name;

        return Object.assign({}, entry, { name });
      });
    },
    hint() {
      if (!this.pickedUser) {
        return '';
      }
      return (typeof this.pickedUser === 'string' ? this.$t('generate signup link') : this.$t('appoint admin'))
    }
  },
  watch: {
    search(val) {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.queryChannels(val);
      }, 1000);
    },
    selectedChannel: {
      handler: function () {
        this.getData();
      },
    },
    filterHot: {
      handler: function () {
        this.getData();
      },
    },
    filterOno: {
      handler: function () {
        this.getData();
      },
    },
    sortBy: {
      handler: function () {
        this.getData();
      },
    },
    sortDir: {
      handler: function () {
        this.getData();
      },
    },
    searchEmail(val) {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.searchUsers(val);
      }, 1000);
    },
  },
  mounted() {
    this.initLoad();
  },
  beforeRouteLeave (to, from, next) {
    if (to.path.startsWith('/channels/') && to.path.endsWith('/library')) {
      this.$store.dispatch("RESET_VIDEO_DATA");
    }
    next();
  },
  methods: {
    queryChannels(val) {
      if (this.isLoading) return;
      if (!val) {
        this.getData();
        return;
      }
      this.isLoading = true;
      this.page = 1
      api.searchChannels(val)
        .then((res) => {
          this.isLoading = false
          this.entries = res.data.items;
          if (res.data.items.length === 0) {
            this.channels = [];
            this.total = 0;
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error);
        });
    },
    changeSort(field) {
      if (this.sortBy == field)
        this.sortDir = this.sortDir == "desc" ? "asc" : "desc";
      this.sortBy = field;
    },
    initLoad() {
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        o_n_o: this.filterOno,
        is_hot: this.filterHot,
        channel_id: this.selectedChannel?.id,
        sort_by: this.sortBy,
        sort_dir: this.sortDir,
      };
      api
        .getChannelsCatalog(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.channels.length > 0) {
            this.channels = res.data.channels;
            this.total = res.data.total;
          } else {
            this.channels = [];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
    toggleHideChannel(channel) {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = {
        hidden: !channel.hidden,
      };
      api
        .editChannel(channel.id, params)
        .then((res) => {
          this.channels.filter((c) => c.id == channel.id)[0].hidden = res.data.channel.hidden;
        })
        .catch((error) => {
          console.log(error);
          this.selectedChannel = null;
          this.getData();
        })
        .finally(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
    toggleYtSetting(channel) {
      const params = {
        yt_enabled: channel.yt_enabled,
      };
      this.toggleSetting(params, channel);
    },
    toggleOnOSetting(channel) {
      const params = {
        o_n_o: channel.o_n_o,
      };
      this.toggleSetting(params, channel);
    },
    toggleHotSetting(channel) {
      const params = {
        is_hot: channel.is_hot,
      };
      this.toggleSetting(params, channel);
    },
    toggleSetting(params, channel) {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .editChannel(channel.id, params)
        .then(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
    destroyChannel(channel) {
      this.channelToDelete = channel;
      this.showDialog = true;
    },
    onConfirmDelete() {
      if (!this.channelToDelete) {
        return;
      }
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .deleteChannel(this.channelToDelete.id)
        .then(() => {
          this.channels = this.channels.filter((c) => c.id !== this.channelToDelete.id);
        })
        .catch((error) => {
          console.log(error)
          this.selectedChannel = null;
          this.getData();
        })
        .finally(() => {
          this.channelToDelete = null;
          this.showDialog = false;
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
    onCancelDelete() {
      this.showDialog = false;
      this.channelToDelete = null;
    },
    openSetAdminDialog(channel) {
      this.loading = true;
      this.pickedUser = null;
      if (!channel) {
        channel = this.setAdminDialog.channel;
      }
      api
        .getChannelAdmins(channel.id)
        .then((res) => {
          if (res.data) {
            const { admins, invitees } = res.data;
            this.admins = [
              ...admins.map((item) => ({
                ...item.user,
                role: item.role,
                status: 'approved'
              })),
              ...invitees.map((item) => ({
                ...item,
                invitation_id: item.id,
                shortcode: item.shortcode,
                status: 'sent invitation'
              }))
            ];
          } else {
            this.admins = [];
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
      this.setAdminDialog = {
        open: true,
        channel,
      };
    },
    handleSetAdmin() {
      this.loading = true;
      const { channel } = this.setAdminDialog;
      if (typeof this.pickedUser === 'string') {
        api
          .generateSignupLink(channel.id, this.pickedUser, this.role)
          .then((res) => {
            console.log(res.data.shortcode);
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.openSetAdminDialog();
          })
      } else {
        api
          .appointAdmin(channel.id, this.pickedUser.id, this.role)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.openSetAdminDialog();
          })
      }
    },
    closeSetAdminDialog() {
      this.setAdminDialog = {
        open: false,
        channel: null,
      };
      this.pickedUser = null;
    },
    handleResend(item) {
      this.loading = true;
      api
        .resendInvitation(this.setAdminDialog.channel.id, item.invitation_id)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.openSetAdminDialog();
        })
    },
    handleRevoke(item) {
      this.loading = true;
      if (item.invitation_id) {
        api
          .revokeInvitation(item.invitation_id)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.openSetAdminDialog();
          })
      } else {
        api
          .revokeAdmin(this.setAdminDialog.channel.id, item.id)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.openSetAdminDialog();
          })
      }
    },
    searchUsers(val) {
      this.loading = true;
      const params = {
        limit: 20,
        offset: 0,
        email: val,
      };
      api
        .getUsers(params)
        .then((res) => {
          if (res.data && res.data.users.length > 0) {
            const adminIds = this.admins.map((admin) => admin.id);
            this.users = res.data.users.filter((user) => !adminIds.includes(user.id));
          } else {
            this.users = [];
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
};
</script>
<style scoped>
.channel-image {
  margin: 0.5em 0;
}

.hidden-row {
  background-color: #f29090;
}

.row-pointer {
  cursor: pointer;
}
</style>