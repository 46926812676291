<template>
  <div class="container">
    <div
      class="d-flex justify-center preview-container"
      :style="{ 'background-color': '#000000' }"
      no-gutters
    >
      <img
        v-if="mediaType === 'image'"
        :src="media"
        alt=""
        class="story-img"
      />
      <video
        v-if="mediaType === 'video'"
        class="story-video"
        v-bind:src="media"
        preload="metadata"
      />
      <div
        class="story-title-container"
        :style="{
          top:
            titlePosition === 'top'
              ? '20%'
              : titlePosition === 'middle'
              ? '50%'
              : '80%',
        }"
      >
        <span
          class="text-subtitle-2 font-weight-bold white--text"
          :style="{ 'text-align': 'center' }">
          {{ title }}
        </span>
      </div>
      <div
        v-if="!mediaType"
        class="story-background"
        :style="{ 'background-color': backgroundColor }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mediaType: String,
    media: String,
    titlePosition: String,
    title: String,
    backgroundColor: String,
  },
  methods: {},
};
</script>

<style scoped>
.container {
  width: 200px;
  padding: 10px 0px !important;
}
.preview-container {
  position: relative;
  min-height: 400px;
}
.story-img {
  max-width: 200px;
  object-fit: contain;
}
.story-title-container {
  position: absolute;
  background-color: #00000080;
  padding: 5px;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.story-background {
  flex: 1;
}
</style>
