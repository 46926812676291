<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="videos"
      :server-items-length="total"
      :options.sync="options"
      item-key="id"
      show-select
      :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Videos In Processing</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="showRetrySelected"
            outlined
            small
            color="default"
            @click="retrySelected"
          >
            Retry Selected
          </v-btn>
          <v-btn outlined small color="default" @click="retryAll" class="mx-2">
            Retry All
          </v-btn>
          <v-btn
            v-if="selected.length"
            icon
            color="primary"
            @click="showDeleteDialog = true"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <v-row align="center" class="py-5">
          <div class="thumbnail">
            <v-img
              :src="item.thumb_url || item.yt_thumb_url"
              :height="50"
              :aspect-ratio="16 / 9"
              contain
              class="mr-2"
            ></v-img>
          </div>
          <span class="truncate">{{ item.title }}</span>
        </v-row>
      </template>
      <template v-slot:item.channels="{ item }">
        <div v-for="{ id, name } in item.channels" :key="id">
          {{ name }}
        </div>
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ moment(item.updated_at).format("DD/MM/YYYY HH:mm:ss") }}
      </template>
      <template v-slot:item.error="{ item }">
        <span class="red--text" v-if="item.error">{{ item.error }}</span>
        <span v-else>--</span>
      </template>
    </v-data-table>

    <v-dialog :value="true" v-if="showDeleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("delete") }}
        </v-card-title>
        <v-card-text>
          {{ $t("are you sure") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1" text @click="showDeleteDialog = false">
            {{ $t("cancel") }}
          </v-btn>

          <v-btn color="green darken-1" text @click="confirmedDelete">
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "@/api/api";

export default {
  props: {
    term: String,
  },
  data() {
    return {
      channelId: this.$route.params.channel_id,
      selected: [],
      headers: [
        {
          text: "Video Info",
          align: "start",
          sortable: false,
          value: "name",
          width: 550,
        },
        { text: "Platform", value: "platform", width: 100 },
        { text: "Channels", value: "channels", sortable: false, width: 150 },
        { text: "Last try", value: "updated_at", width: 100 },
        { text: "Error", value: "error" },
      ],
      videos: [],
      total: 0,
      options: {
        itemsPerPage: 5,
        page: 1,
        sortBy: ["updated_at"],
        sortDesc: [true],
      },
      showDeleteDialog: false,
      timeout: null,
    };
  },
  watch: {
    $route(to) {
      this.channelId = to.params.channel_id;
      this.initLoad();
    },
    options: {
      handler() {
        this.initLoad();
        this.selected = [];
      },
      deep: true,
    },
    term() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.options = { ...this.options, page: 1 };
      }, 300);
    },
  },
  computed: {
    showRetrySelected() {
      return this.selected.some((item) => item.error);
    },
  },
  mounted() {
    this.initLoad(true);
  },
  methods: {
    removeDuplicates(array, key) {
      const uniqueArray = [];
      const encountered = {};

      for (const obj of array) {
        const value = obj[key];

        if (!encountered[value]) {
          encountered[value] = true;
          uniqueArray.push(obj);
        }
      }

      return uniqueArray;
    },
    initLoad(retrieveFromLastData) {
      if (this.channelId) {
        this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      }
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      if (retrieveFromLastData) {
        const data = this.$store.getters.getVideoProcessingData;
        this.videos = data.videos;
        this.total = data.total;
        this.options = { ...data.options, page: 1 };
      }
      const { page, itemsPerPage, sortBy } = this.options;

      const params = {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        channel_id: this.channelId,
        cache: false,
        sort_by: sortBy[0],
        term: this.term || undefined,
      };
      api
        .getProcessingVideos(params)
        .then((res) => {
          if (res.data.videos.length > 0) {
            const videos = res.data.videos;
            this.videos = this.removeDuplicates(videos, "id");
            this.total = res.data.total;
            this.$store.dispatch("SET_PROCESSING_VIDEO_DATA", {
              videos: this.videos,
              totalPocessingVideos: this.total,
              options: this.options,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
    retrySelected() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .retryProcessingVideos({ ids: this.selected.map((item) => item.id) })
        .then(() => {
          this.initLoad();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          this.selected = [];
        });
    },
    retryAll() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .retryProcessingVideos({ all: true })
        .then(() => {
          this.initLoad();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          this.selected = [];
        });
    },
    confirmedDelete() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .deleteProcessingVideos({ ids: this.selected.map((item) => item.id).join(",") })
        .then(() => {
          this.initLoad();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          this.selected = [];
          this.showDeleteDialog = false;
        });
    },
  },
};
</script>
<style scoped>
.thumbnail {
  margin-right: 5px;
  height: 50px;
  aspect-ratio: 16 / 9;
  background: black;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
}
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px !important;
  white-space: normal; /* Change to normal to allow wrapping */
}
</style>
