<template>
  <v-dialog max-width="480" v-model="show" persistent>
    <v-card class="pa-8">
      <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
        {{ $t("add to playlist") }}
      </v-card-title>
      <div class="d-flex align-center">
        <playlist-picker @onSelected="onSelected" :channelId="channelId" />
      </div>
      <v-card-actions class="d-flex justify-space-between px-0 mt-4">
        <v-btn
          class="white--text flex-grow-1"
          color="#616161"
          tile
          x-large
          @click="handleAction(false)"
        >
          <span class="text-h5">{{ $t("cancel") }}</span>
        </v-btn>
        <v-btn
          class="white--text flex-grow-1"
          color="#ff0000"
          tile
          x-large
          @click="handleAction(true)"
          :disabled="disabled"
        >
          <span class="text-h5">{{ $t("add") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
        
  <script>
import PlaylistPicker from '../playlist/PlaylistPicker.vue';
export default {
  components: { PlaylistPicker },
  props: {
    channelId: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      show: true,
      selected: [],
    };
  },
  computed: {
    disabled() {
      return !this.selected?.length;
    },
  },
  methods: {
    async handleAction(isConfirmed) {
      this.$emit("onDone", isConfirmed ? this.selected : null);
    },
    onSelected(playlist) {
      console.log({ playlist });
      this.selected = playlist;
    },
  },
};
</script>
    