<template>
    <v-autocomplete
        class="select-input"
        v-model="selected"
        :items="filteredPlaylist"
        :label="$t('playlist')"
        item-text="title"
        item-value="id"
        multiple
        chips
        small-chips
        deletable-chips
        outlined
        dense
        clearable
        persistent-hint
        return-object
        :search-input.sync="search"
        :filter="customFilter"
        :loading="isLoading"
        :disabled="filteredChannels !== null && filteredChannels.length === 0"
    >
      <template #prepend-item>
        <v-btn
          text
          color="primary"
          retain-focus-on-click
          @click="createNewPlaylist"
          style="margin-bottom: 8px"
        >
          <v-icon small>mdi-plus</v-icon>
          &nbsp;
          {{ $t("new playlist") }}
        </v-btn>
        <v-divider />
      </template>
    </v-autocomplete>
</template>

<script>
import Fuse from 'fuse.js';
import { uniqBy } from 'lodash';

export default {
  props: {
    channelId: {
      type: String,
      default: null,
    },
    filteredChannels: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selected: [],
      search: null,
      playlists: this.$store.getters.getPlaylists,
      isLoading: false,
      timeout: null,
    };
  },
  mounted() {
    this.initLoad();
  },
  computed: {
    filteredPlaylist () {
      let combined = uniqBy([...this.playlists, ...this.selected], (o) => o.id);
      if (this.filteredChannels) {
        const channelIds = this.filteredChannels.map((o) => o.id);
        combined = combined.filter((o) => channelIds.includes(o.channel_id));
      }
      const fuse = new Fuse(combined, {
        keys: ['title', 'description'], // specify which keys to search
        threshold: 0.3 // specify how fuzzy the search should be
      })
      if (!this.search) return combined;
      return uniqBy(fuse.search(this.search).map(result => result.item).concat(this.selected), (o) => o.id);
    }
  },
  watch: {
    selected(val) {
      this.search = null;
      this.$emit("onSelected", val || []);
    },
    // '$store.getters.getPlaylists'() {
    //   this.playlists = this.$store.getters.getPlaylists.filter((o) => this.channelId ? o.channel_id === Number(this.channelId) : true);
    // },
    search() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.initLoad();
      }, 500);
    },
    '$store.getters.getIsNewPlaylistCreated'(val) {
      if (val) {
        this.initLoad(true);
      }
    }
  },
  methods: {
    async initLoad(autoPick) {
      // const channels = this.$store.getters.getChannels.map((o) => o.channel);
      // if (this.channelId) {
      //   const res = await this.$store.dispatch('FETCH_PLAYLISTS', channels);
      //   this.playlists = res.filter((o) => this.channelId ? o.channel_id === Number(this.channelId) : true);
      // } else {
      this.playlists = this.$store.getters.getAllPlaylists;
      const params = {
        offset: 0,
        limit: 20,
        with_video: true,
        term: this.search || '',
      };
      if (this.channelId) {
        params.channel_id = Number(this.channelId);
      }
      const { playlists } = await this.$store.dispatch('FETCH_ALL_PLAYLISTS', params);
      this.playlists = playlists;
      // }
      if (autoPick && playlists.length > 0) {
        this.selected = [playlists[0]];
        this.$store.dispatch("REFRESH_PLAYLIST_AND_PICK", false);
      }
    },
    customFilter (item, queryText) {
      // create a fuse.js instance with some options
      const fuse = new Fuse([item], {
        keys: ['title', 'description'], // specify which keys to search
        threshold: 0.3 // specify how fuzzy the search should be
      });
      return fuse.search(queryText).length > 0
    },
    createNewPlaylist() {
      this.$store.dispatch('SET_SHOW_PLAYLIST_DIALOG', true);
    },
  },
};
</script>

<style scoped>
.select-input {
  border-radius: 0px;
}
</style>
