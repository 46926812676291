<template>
  <v-container fluid>
    <v-row class="d-flex justify-end pa-5">
      <v-btn color="#ff0000" class="white--text" tile width="120" @click="navigateAdd">
        + {{$t('new video')}} 
      </v-btn>
    </v-row>
    <v-row class="pa-4 d-flex flex-column">
      <div class="d-flex search-box align-center pl-2 mb-2">
        <v-text-field
          v-model="searchKeyword" 
          v-on:keyup="searchKeydown"
          :label="`${$t('search')}`"
          hide-no-data 
          hide-details 
          dense
          flat
          solo
        />
        <v-btn icon  @click="initVariables">
          <v-icon color="black">mdi-magnify</v-icon>
        </v-btn>
      </div>
      <v-row no-gutters class="justify-space-between">
        <v-col cols="auto" no-gutters>
          <v-btn text @click="selectTab(0)">
            <span
              :class="{'font-weight-bold': tab === 0, 'text-subtitle-2': true}"
              v-if="tab === 0 || tab !== 0">
              {{$t('published content')}}
            </span>
            <span class="text-subtitle-2 mr-1"> ({{ publishedVideos.length }}) </span>
          </v-btn>
          <v-btn text @click="selectTab(1)">
            <span
              :class="{'font-weight-bold': tab === 1, 'text-subtitle-2': true}"
            >
              {{ $t('awaiting publication') }}
            </span>
            <span class="text-subtitle-2 red--text mr-1">
              ({{ unPublishedVideos.length }})
            </span>
          </v-btn>
          <v-btn text @click="selectTab(2)">
            <span
              :class="{'font-weight-bold': tab === 2, 'text-subtitle-2': true}"
            >
              {{ $t('processing') }}
            </span>
            <span class="text-subtitle-2 mr-1"> ({{ processingCount }}) </span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          v-if="tab !== 2"
          class="d-flex align-center"
          xl="2"
          lg="2"
          md="3"
          sm="4"
          xs="12"
          cols="12">
          <v-select
            class="lang-select mx-2 my-2"
            v-model="selectedLang"
            :items="languages"
            item-text="name"
            item-value="val"
            single-line
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="auto" class="d-flex align-center ml-1" v-if="tab !== 2">
          <span class="text-caption mx-2">  {{$t('sort by')}} :</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                tile
                class="pa-2"
                min-width="120"
                min-height="40"
                v-bind="attrs"
                v-on="on"
              >
                <span class="text-caption mx-2"> {{ activeSort.name }} </span>
                <v-icon class="mx-2">mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, index) in this.sortOptions"
                  :key="index"
                  @click="updateSortby(item)"
                >
                  <v-list-item-title class="subtitle-2">{{
                    item.name
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <VideoInProcessing v-if="tab === 2" :term="searchKeyword" />
      <v-row class="d-flex flex-wrap  py-4" no-gutters>
        <v-col
          xl="4"
          lg="4"
          md="6"
          sm="12"
          xs="12"
          cols="12"
          v-for="video in tab === 0 ? publishedVideos : unPublishedVideos"
          :key="video.id"
        >
          <new-video-item
            v-if="tab === 1"
            :video="video"
            :showAddSection="$store.getters.getUser.is_admin"
            @refresh="initLoad"
            @onPublished="onPublished"
            @addVideoToSection="onShowVideoSectionDlg"
            :isProcessing="!!video.channel_ids"
          />
          <video-item
            v-if="tab === 0"
            :video="video"
            :showAddSection="$store.getters.getUser.is_admin"
            @refresh="initLoad"
            @addVideoToSection="onShowVideoSectionDlg"
            @addVideoToPlaylist="onShowPlaylist"
            :isProcessing="!!video.channel_ids"
          />
        </v-col>
      </v-row>
      <v-row class="justify-center" v-if="showLoadMore && tab !== 2">
        <v-btn text color="red" @click="this.loadMore"> — {{$t('show more')}} — </v-btn>
      </v-row>
    </v-row>
    <v-dialog
        v-model="showVideoSectionDlg"
        max-width="480"
      >
      <v-card class="pa-8">
        <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
        {{$t('add to session')}}
        </v-card-title>
        <div class="d-flex align-center">
          <span class="text-caption mx-2">{{$t('category')}}</span>
          <v-select
              class="category-select"
              v-model="videoPosition['category_id']"
              :items="categories"
              item-text="name"
              item-value="id"
              single-line
              outlined
              dense
              hide-details
          />
        </div>
        <div class="d-flex align-center mt-4">
          <span class="text-caption mx-2">{{$t('location')}}</span>
          <v-select
              class="category-select"
              v-model="videoPosition['position']"
              :items="positions"
              single-line
              outlined
              dense
              hide-details
          />
        </div>

        <div class="d-flex justify-center mt-4 red--text">
          <span v-if="showError" class="text-caption">{{showError}}</span>
        </div>

        <v-card-actions class="d-flex justify-space-between px-0 mt-4">
          <v-btn
            class="white--text flex-grow-1"
            color="#616161"
            tile
            x-large
            @click="showVideoSectionDlg = false"
          >
            <span class="text-h5">חזור</span>
          </v-btn>
          <v-btn
            class="white--text flex-grow-1"
            color="#ff0000"
            tile
            x-large
            @click="onAddVideoToSection()"
          >
            <span class="text-h5">הוסף</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showMessage" :timeout="2000" top>
      {{ message }}
    </v-snackbar>
    <AddToPlaylistDlg v-if="showPlaylistDlg" @onDone="handleAddToPlaylist" :channelId="channelId" />
  </v-container>
</template>

<script>
import api from "@/api/api";
import AddToPlaylistDlg from "./AddToPlaylistDlg.vue";
import VideoInProcessing from "./VideoInProcessing.vue";


export default {
  components: {
    AddToPlaylistDlg,
    VideoInProcessing,
  },
  mounted() {
    this.initLoad(true);
    this.getCategories()
  },
  watch: {
    $route(to) {
      this.channelId = to.params.channel_id;
      this.initLoad();
    },
    selectedLang: {
      handler: function() {
        this.$store.dispatch("SET_LANGUAGE_VIDEO", this.selectedLang);
        this.initLoad(true);
        this.getCategories();
      }
    }
  },
  methods: {
    navigateAdd() {
      if (this.channelId) {
        this.$router.push({ path: `/channels/${this.channelId}/video/add` });
      } else {
        this.$router.push({ path: `/library/video/add` });
      }
    },
    initLoad(retrieveFromLastData) {
      if (this.channelId) {
        this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      }
      this.filterVideos = [];
      if (retrieveFromLastData) {
        const lastData = this.$store.getters.getLastVideoData;
        this.activeSort = lastData.activeSort;
        this.selectedLang = lastData.selectedLang;
        this.publishedVideos = lastData.publishedVideos[this.selectedLang];
        this.unPublishedVideos = lastData.unPublishedVideos[this.selectedLang];
        this.totalPublished = lastData.totalPublished[this.selectedLang];
        this.totalUnpublished = lastData.totalUnpublished[this.selectedLang];
        this.publishedPage = lastData.publishedPage[this.selectedLang];
        this.unPublishedPage = lastData.unPublishedPage[this.selectedLang];
        this.tab = lastData.tab;
        if (this.tab === 0) {
          this.showLoadMore =
            this.totalPublished > this.publishedVideos.length;
        } else {
          this.showLoadMore =
            this.totalUnpublished > this.unPublishedVideos.length;
        }
      } else {
        this.publishedVideos = [];
        this.unPublishedVideos = [];
        this.totalPublished = 0;
        this.totalUnpublished = 0;
        this.publishedPage = 1;
        this.unPublishedPage = 1;
        this.tab = 0;
      }
      if (!this.publishedVideos?.length) {
        this.getData(0);
      }
      if (!this.unPublishedVideos?.length) {
        this.getData(1);
      }
      api.countProcessingVideos({ channel_id: this.channelId })
        .then((res) => {
          this.processingCount = res.data.count;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCategories() {
      api.getCategories(this.selectedLang)
        .then((res) => {
            this.categories = res.data.categories;
        })
        .catch((err) => {
            console.log(err);
        })
		},

    selectTab(t) {
      this.tab = t;
      this.$store.dispatch("SET_TAB", t);
      if (t === 0) {
        this.showLoadMore = this.totalPublished > this.publishedVideos.length;
      } else {
        this.showLoadMore =
          this.totalUnpublished > this.unPublishedVideos.length;
      }
    },

   removeDuplicates(array, key) {
      const uniqueArray = [];
      const encountered = {};

      for (const obj of array) {
        const value = obj[key];

        if (!encountered[value]) {
          encountered[value] = true;
          uniqueArray.push(obj);
        }
      }

      return uniqueArray;
    },

    getData(t, force = false) {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      if (force) {
        this.publishedPage = 1;
        this.unPublishedPage = 1;
      }
      const page = t === 0 ? this.publishedPage : this.unPublishedPage;
      const params = {
        limit: this.limit,
        offset: (page - 1) * this.limit,
        channel_id: this.channelId,
        filter: t === 0 ? 1 : 3,
        cache: false,
        sort_by: this.activeSort.key,
        show_comments: true,
        term: this.searchKeyword,
        include_processing: true,
      };
      api.getChannelVideos(params, this.selectedLang)
        .then((res) => {
          if (res.data.videos.length > 0) {
            if (t === 0) {
              const combinedVideos = this.publishedVideos.concat(
                res.data.videos
              );
              
              this.publishedVideos = this.removeDuplicates(combinedVideos, 'id');
              this.totalPublished = res.data.total;
              this.publishedPage += 1;
              this.$store.dispatch("SET_PUBLISHED_VIDEO_DATA", {
                publishedVideos: {
                  [this.selectedLang]: this.publishedVideos,
                },
                totalPublished: {
                  [this.selectedLang]: this.totalPublished,
                },
                publishedPage: {
                  [this.selectedLang]: this.publishedPage,
                },
              });
            } else {
              const combinedVideos = this.unPublishedVideos.concat(
                res.data.videos
              );
              this.unPublishedVideos = this.removeDuplicates(combinedVideos, 'id');
              this.totalUnpublished = res.data.total;
              this.unPublishedPage += 1;
              this.$store.dispatch("SET_UNPUBLISHED_VIDEO_DATA", {
                unPublishedVideos: {
                  [this.selectedLang]: this.unPublishedVideos,
                },
                totalUnpublished: {
                  [this.selectedLang]: this.totalUnpublished,
                },
                unPublishedPage: {
                  [this.selectedLang]: this.unPublishedPage,
                },
              });
            }
          }
          if (t === 0) {
            this.showLoadMore =
              this.totalPublished > this.publishedVideos.length;
          } else {
            this.showLoadMore =
              this.totalUnpublished > this.unPublishedVideos.length;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },

    updateSortby(data) {
      this.activeSort = data;
      this.$store.dispatch("SET_ACTIVE_SORT", data);
      this.initVariables();
    },

    initVariables() {
      this.publishedPage = 1;
      this.unPublishedPage = 1;
      this.publishedVideos = [];
      this.unPublishedVideos = [];
      this.getData(0);
      this.getData(1);
    },

    searchKeydown(e) {
      if (e.keyCode === 13) {
        this.initVariables();
      }
    },

    loadMore() {
      this.getData(0);
      this.getData(1);
    },

    onPublished() {
      this.initLoad();
      this.message = "פורסם בהצלחה!";
      this.showMessage = true;
    },

    onShowVideoSectionDlg(video) {
      this.showVideoSectionDlg = true;
      this.selectedVideo = video;
    },

    onAddVideoToSection() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      this.showError = "";
      const data = {
        video_id: this.selectedVideo.id,
        category_id: this.videoPosition.category_id,
        position: this.videoPosition.position
      };
      api.createVideoPosition(data)
        .then(() => {
            this.$store.dispatch("SET_TOOLBARLOAD", false);
            this.selectedVideo = null;
            this.showVideoSectionDlg = false;
        })
        .catch((err) => {
            this.$store.dispatch("SET_TOOLBARLOAD", false);
            if (err.response) {
              this.showError = err.response.data.errors.length > 0 ? err.response.data.errors[0] : ""
            }
        })
    },
    onShowPlaylist(video) {
      this.showPlaylistDlg = true;
      this.selectedVideo = video;
    },
    async handleAddToPlaylist(list) {
      if (list) {
        const promise = list.map((o) => api.addVideoToPlayList({
          channelId: o.channel_id,
          videoId: this.selectedVideo.id,
          playlistId: o.id,
          position: !o.playlist_items.length ? 1 : Math.max(...o.playlist_items.map((item) => item.position)) + 1,
        }));
        await Promise.allSettled(promise);
      }
      this.selectedVideo = null;
      this.showPlaylistDlg = false;
    },
  },

  data() {
    let languages = [
        {name: this.$t("hebrew"), val: "he"},
        {name: this.$t("english"), val: "en"},
    ];
    return {
      publishedVideos: [],
      unPublishedVideos: [],
      totalPublished: 0,
      totalUnpublished: 0,
      filterVideos: [],
      limit: 15,
      total: 0,
      publishedPage: 1,
      unPublishedPage: 1,
      tab: 0,
      sortOptions: [
        { name: "תאריך העלאה", key: "date" },
        { name: "הכי נצפים", key: "views" },
      ],
      activeSort: { name: "תאריך העלאה", key: "date" },
      channelId: this.$route.params.channel_id,
      message: "",
      showMessage: false,
      showLoadMore: false,
      showVideoSectionDlg: false,
      videoPosition: {},
			categories: [],
      positions: [...Array(25).keys()].map(p => p + 1),
      selectedVideo: null,
      showError: "",
      searchKeyword: null,
      showPlaylistDlg: false,
      languages: languages,
      selectedLang: languages[0].val,
      processingCount: 0,
    };
  },
};
</script>

<style scoped>
@media (max-width:600px) {
  .search-box {
    width: 100%;
    border: 1px solid black
  }
}

@media (min-width: 600px) and (max-width: 1264px) {
  .search-box {
    width: 40%;
    border: 1px solid black
  }
}

@media (min-width: 1264px) {
  .search-box {
    width: 30%;
    border: 1px solid black
  }
}


.lang-select {
  border-radius: 0px;
  max-width: 250px;
}

</style>
