<template>
  <v-container fluid>
    <v-row >
      <v-col xl="5" lg="6" md="8" sm="12" xs="12" cols="12" class="d-flex justify-space-between pa-5">
        <v-select
          class="lang-select mx-2"
          v-model="selectedLang"
          :items="languages"
          item-text="name"
          item-value="val"
          single-line
          outlined
          dense
          hide-details
        />
        <v-btn color="#ff0000" class="white--text" tile width="150" @click="navigateAdd">
          + {{$t('new category')}} 
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="px-5 flex-column">
      <v-col xl="5" lg="6" md="8" sm="12" xs="12" cols="12">
        <draggable
          v-model="categories"
          group="people"
          @end="onDragEnd"
          :delayOnTouchOnly="true"
          :delay="500"
          :touchStartThreshold="5"
        >
          <v-card tile v-for="element in categories" :key="element.id"  >
            <v-list-item dense>
              <v-list-item-content >
                <v-list-item-title >
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <v-chip small class="mx-2" color="warning">
                        {{ element.order }} 
                      </v-chip>{{ element.name }}
                      <v-switch
                        class="mt-0 mx-2"
                        dense
                        color="success"
                        inset
                        v-model="element.active"
                        :label="element.active ? $t('active') : $t('inactive')"
                        hide-details
                        @change="onSave()"
                        :disabled="loading"
                      />
                    </div>
                    <div>
                      <v-btn
                        class="mx-2"
                        outlined
                        small
                        @click="onDeleteAlert(element)"
                      >
                        {{$t('delete')}}
                      </v-btn>
                      <v-btn
                        outlined
                        small
                        @click="
                          $router.push({
                            name: 'EditCategory',
                            params: { category_id: element.id },
                          })
                        "
                      >
                        {{$t('edit')}}
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </draggable>
        <!-- <v-row no-gutters class="d-flex justify-center">
          <v-btn
            tile
            color="#616161"
            width="140"
            class="white--text ma-2"
            @click="onRevert()"
          >
            {{$t('return')}}
          </v-btn>
          <v-btn
            tile
            color="#388E3C"
            width="140"
            class="white--text ma-2"
            @click="onSave()"
            :loading="this.loading"
            :disabled="this.loading"
          >
            {{$t('save')}}
          </v-btn>
        </v-row> -->
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> {{ $t('delete') }} </v-card-title>
        <v-card-text> {{ $t('are you sure') }}? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDialog = false">
           {{ $t('cancel') }}
          </v-btn>
          <v-btn color="green darken-1" text @click="this.deleteCategory">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import api from "@/api/api";

export default {
  data() {
    let languages = [
        {name: this.$t("hebrew"), val: "he"},
        {name: this.$t("english"), val: "en"},
    ];
    return {
      savedCategories: [],
      categories: [],
      loading: false,
      languages: languages,
      selectedLang: languages[0].val,
      showDialog: false,
      tempCategory: null
    };
  },
  watch: {
    selectedLang: {
        handler: function () {
            this.getData();
        }
    }
  },
  components: {
    draggable,
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api.getVideoCategories({}, this.selectedLang).then((res) => {
        this.$store.dispatch("SET_TOOLBARLOAD", false);
        this.categories = res.data.categories.map((cat) => {
          return {
            name: cat.name,
            order: cat.order,
            active: !cat.hidden,
            fixed: false,
            created_at: cat.created_at,
            image: cat.image,
            id: cat.id,
          };
        });
        this.savedCategories = JSON.parse(JSON.stringify(this.categories));
      });
    },
    onDragEnd(evt) {
        if (evt.oldIndex === evt.newIndex) return;
        const newCategories = this.categories.map((cat, index) => {
          cat.order = index + 1;
          return cat;
        });
        this.categories = newCategories;
    },
    onDeleteAlert(evt) {
      this.tempCategory = evt;
      this.showDialog = true
    },
    deleteCategory() {
      this.showDialog = false;
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api.deleteCategory(this.tempCategory.id)
        .then(() => {
          this.categories = this.categories.filter((e) => e.id != this.tempCategory.id);
          this.tempCategory = null
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        })
    },
    onSave() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      this.loading = true;
      const newCategories = this.categories.map((cat, index) => {
        return {
          hidden: !cat.active,
          order: index + 1,
          id: cat.id,
        };
      });
      api
        .saveVideoCategories({"categories": newCategories})
        .then(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
    onRevert() {
      this.categories = JSON.parse(JSON.stringify(this.savedCategories));
    },
    navigateAdd() {
      this.$router.push({ path: `/categories/add` });
    },
  },
};
</script>
<style scoped>
  .lang-select {
      border-radius: 0px;
      max-width: 250px;
  }
</style>
