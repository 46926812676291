<template>
    <v-app-bar app >
        <div class="d-flex justify-space-between  flex-grow-1">
            <div class="d-flex">
                <v-app-bar-nav-icon @click="toggleNavigationBar" ></v-app-bar-nav-icon>
                <div class="d-none d-sm-flex">
                    <div class="d-flex align-center">
                        <span class="text-caption mx-2">{{$t('language')}}:</span>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn outlined tile class="switch-btn" min-width="100"  v-bind="attrs" v-on="on">
                                    <span class="text-caption mx-2"> {{activeLanguage.name}} </span>
                                    <v-icon class="">mdi-menu-down </v-icon>
                                </v-btn>
                            </template>
                            <v-list class="overflow-y-auto" max-height="300" min-width="100"  >
                                <v-list-item-group>
                                    <v-list-item
                                    v-for="(item, index) in this.languages"
                                    :key="index"
                                    @click="onChangeLanguage(item.val)"
                                    >
                                        <v-list-item-title class="subtitle-2">{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </div>
            <div class="logo d-none d-md-flex">
                <v-img src="@/assets/logo.png" aspect-ratio="1" width="50" height="50" contain/>
            </div>
            <div v-if="this.$route.params.channel_id" class="d-flex align-center justify-end pl-4"  >
                <span class="text-caption mx-2">{{$t('edit channel')}}:</span>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined tile class="switch-btn" min-width="120"  v-bind="attrs" v-on="on">
                            <span class="text-caption mx-2"> {{activeChannelName}} </span>
                            <v-icon class="mx-2">mdi-menu-down </v-icon>
                        </v-btn>
                    </template>
                    <v-list class="overflow-y-auto" max-height="800" min-width="300">
                        <v-list-item-group>
                            <v-list-item
                            v-for="(item, index) in this.$store.getters.getChannels"
                            :key="index"
                            @click="onChangeChannel(index)"
                            >
                                <v-list-item-avatar>
                                    <v-img :src="item.channel.image" class="bg-avatar"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-title class="subtitle-2">{{ item.channel.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                        <v-divider></v-divider>
                        <v-list-item class="justify-end">
                            <v-btn text>{{$t('closed')}}</v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <v-progress-linear
            :active="this.$store.getters.getToolbarLoad"
            :indeterminate="this.$store.getters.getToolbarLoad"
            absolute
            bottom
            color="red"
        ></v-progress-linear>
    </v-app-bar>
</template>

<script>

export default {
    methods: {
        toggleNavigationBar() {
            this.$store.dispatch('TOGGLE_DRAWER')
        },
        onChangeChannel(index) {
            const newChannelId = this.$store.getters.getChannels[index].channel.id;
            const currentPath = window.location.hash.split('#')[1];
            if (currentPath) {
                const newPath = currentPath.replace(this.$route.params.channel_id, newChannelId)
                this.$router.push({path:newPath});
            }
        },
        onChangeLanguage(lan) {
            this.$store.dispatch("SET_LANGUAGE", lan);
            window.location.reload();
        }
    },
    data() {
        return {
            loading: true,
            languages: [
                {name: "עברית", val: "he"},
                {name: "English", val: "en"},
            ],
        }
    },
    watch: {
      $route(to) {
        const channelId = to?.params?.channel_id;
        if (channelId) {
          this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", channelId);
        }
      },
    },
    mounted() {
      this.$store.dispatch('FETCH_CHANNEL');
    },
    computed: {
        activeChannelName () {
           return this.$store.getters.getActiveChannel ? this.$store.getters.getActiveChannel.channel.name : ""
        },
        activeLanguage () {
            return this.languages.find((e) => e.val == this.$store.getters.getLanguage)
        }
    }

}
</script>

<style>
    .switch-btn {
        text-transform: none;
    }
    .bg-avatar {
        background-color: darkgrey;
    }
    .lan-btn {
        width: 45px;
        height: 30px;
        cursor: pointer;
    }
    .lan-btn-selected {
        border: 1px solid black;
    }
    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>