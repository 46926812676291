<template>
  <v-container fluid>
    <v-row class="pa-1">
      <v-col lg="7" md="8" sm="8" xs="12">
        <v-select
          class="lang-select my-2"
          v-model="selectedLang"
          :items="languages"
          item-text="name"
          item-value="val"
          single-line
          outlined
          dense
          hide-details
        />
        <v-form ref="form" lazy-validation>
          <span class="text-subtitle-2 font-weight-bold">{{ $t("name") }}</span>
          <div class="d-flex py-2">
            <v-text-field
              dense
              class="input"
              v-model="name"
              :rules="nameRules"
              single-line
              outlined
              required
              hide-details
            />
          </div>
          <span class="text-subtitle-2 font-weight-bold"
            >{{ $t("tags") }}
          </span>
          <div class="d-flex py-2">
            <v-text-field
              dense
              class="input"
              v-model="currentTag"
              single-line
              outlined
              required
              hide-details
              :placeholder="$t('type the text here')"
              v-on:keyup="tagKeydown"
            />
          </div>
          <div class="d-flex flex-wrap">
            <v-chip
              class="ma-2"
              close
              small
              v-for="(item, index) in computedTags"
              :key="index"
              @click:close="onCloseTag(index)"
            >
              {{ item }}
            </v-chip>
          </div>
          <div v-if="!isEdit">
            <span class="text-subtitle-2 font-weight-bold"
              >{{ $t("select channels") }}
            </span>
            <div class="d-flex py-2">
              <v-autocomplete
                v-model="selectedChannels"
                :loading="channelsLoading"
                :items="channels"
                :search-input.sync="searchChannel"
                class="input"
                hide-no-data
                hide-details
                label=""
                outlined
                dense
                return-object
                chips
                deletable-chips
                multiple
                small-chips
                item-text="name"
                item-value="id"
                :placeholder="$t('type to search')"
              />
            </div>
          </div>
          <span class="text-subtitle-2 font-weight-bold"
            >{{ $t("order") }}
          </span>
          <div class="d-flex py-2">
            <v-select
              class="input"
              v-model="selectedPosition"
              :items="positions"
              item-text="name"
              item-value="id"
              :label="$t('choose')"
              dense
              outlined
              single-line
              return-object
            />
          </div>
          <div class="d-flex">
            <v-switch
              dense
              color="success"
              inset
              v-model="active"
              :label="active ? $t('active') : $t('inactive')"
            />
          </div>
        </v-form>
        <div class="d-flex justify-end mt-6">
          <v-btn
            tile
            color="#388E3C"
            width="140"
            class="white--text"
            @click="onSave()"
            :loading="this.saving"
            :disabled="this.saving || !this.valid"
            >{{ $t("save") }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="showResult" :timeout="2000" top>
      {{ result }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    let languages = [
      { name: this.$t("hebrew"), val: "he" },
      { name: this.$t("english"), val: "en" },
    ];
    return {
      category: {},
      isEdit: false,
      languages: languages,
      selectedLang: languages[0].val,
      name: "",
      nameRules: [(v) => !!v || ""],
      active: false,
      currentTag: null,
      selectedTags: [],
      channelsLoading: false,
      selectedChannels: [],
      channels: [],
      searchChannel: null,
      debounce: null,
      selectedPosition: undefined,
      positions: [...Array(50).keys()].map((p) => p + 1),
      saving: false,
      result: "",
      showResult: false
    };
  },
  mounted() {
    this.initLoad();
  },
  watch: {
    $route(to, from) {
      if (to != from) this.initLoad();
    },
    searchChannel(val) {
      if (this.selectedChannels.includes(val)) {
        return;
      }
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.queryChannel(val);
      }, 1000);
    },
  },
  computed: {
    valid() {
      return this.name
    },
    computedTags() {
      return this.selectedTags.filter((e) => !e.delete).map((e) => e.word)
    }
  },
  methods: {
    initLoad() {
      this.isEdit = !!(this.$route.params.category_id);
      if (this.isEdit) {
        this.getData(this.$route.params.category_id);
      }
    },
    getData(categoryId) {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api.getCategory(categoryId)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          const category = res.data.category;
          this.selectedLang = category.language;
          this.name = category.name;
          this.selectedPosition = category.order;
          this.selectedTags = category.category_words.map((e) => ({word: e.word, delete: false}));
          this.active = !category.hidden;
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
          this.$router.push({ path: `/categories` });
        })
    },
    tagKeydown(e) {
      if (e.keyCode === 13) {
        if (this.currentTag) {
          this.selectedTags.push({word: this.currentTag, delete: false});
          this.currentTag = null;
        }
      }
    },
    onCloseTag(index) {
      this.selectedTags[index].delete = true
    },
    async queryChannel(val) {
      this.channelsLoading = true;
      api
        .searchChannels(val)
        .then((res) => {
          this.channels = res.data.items;
          this.channelsLoading = false;
        })
        .catch((error) => {
          this.channelsLoading = false;
          console.log(error);
        });
    },
    onSave() {
      const vm = this;
      vm.saving = true;
      let params = {
        name: vm.name,
        hidden: !vm.active,
        order: vm.selectedPosition,
        language: vm.selectedLang,
        category_words: vm.selectedTags,
      }
      if (!this.isEdit) {
        params.include = vm.selectedChannels.map((c) => c.id)
      }
      const operation = this.isEdit 
        ? api.editCategory(this.$route.params.category_id, params) 
        : api.createCategory(params)
      operation
        .then((res) => {
          vm.saving = false
          vm.result = vm.isEdit ? "עודכן בהצלחה!" : "נוצר בהצלחה!";
          vm.showResult = true;
          if (!vm.isEdit) {
            vm.$router.push({
              path: `/categories/${res.data.category.id}/edit`,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          vm.saving = false
          vm.result = "לא יכול לעדכן!";
          vm.showResult = true;
        });
    },
  },
};
</script>

<style scoped>
.lang-select {
  border-radius: 0px;
  max-width: 200px;
}
.input {
  border-radius: 0px;
  font-size: 12px;
}
</style>
