<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height>
        <v-row class="justify-center align-center">
          <v-col xs="12" sm="8" md="4" lg="4" xl="4">
            <v-card class="pa-3" outlined tile elevation="2">
              <v-card-text>
                <div>
                  <v-img
                    src="@/assets/logo.png"
                    aspect-ratio="1"
                    height="100"
                    contain
                  />
                </div>
                <v-form>
                  <v-text-field
                    append-icon="person"
                    name="login"
                    label="Login"
                    type="text"
                    single-line
                    v-model="userEmail"
                    :error="error"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'visibility_off' : 'visibility'
                    "
                    name="password"
                    label="Password"
                    id="password"
                    single-line
                    :rules="[rules.required]"
                    v-model="password"
                    :error="error"
                    @click:append="hidePassword = !hidePassword"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions class="d-flex flex-column">
                <v-btn
                  block
                  x-large
                  color="#EE220C"
                  class="white--text font-weight-bold"
                  @click="login"
                  :loading="loading"
                  >התחבר</v-btn
                >
                <span class="text-subtitle-1 font-weight-bold py-5"
                  >כניסה מהירה
                </span>
                <v-btn icon class="btn ma-0" @click="onClickGoogleLogin">
                  <v-img
                    src="@/assets/social_google.png"
                    aspect-ratio="1"
                    contain
                    width="40"
                    height="40"
                  />
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="showResult" :timeout="2000" top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import api from "@/api/api";

export default {
  data() {
    return {
      googleClient: null,
      loading: false,
      userEmail: "",
      password: "",
      hidePassword: true,
      error: false,
      showResult: false,
      result: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },

  mounted() {
    window.addEventListener("load", () => this.initGoogleClient());
  },

  methods: {
    initGoogleClient() {
      if (!window.google) return;
      this.googleClient = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/userinfo.profile \
              https://www.googleapis.com/auth/userinfo.email',
        prompt: 'select_account',
        callback: (tokenResponse) => {
          this.loginWithGoogle(tokenResponse.access_token)
        },
      });
    },
    loginWithGoogle (token) {
      const params = {
        access_token: token,
        device: "web",
      };
      this.loading = true;
      api
        .loginWithGoogle(params)
        .then((res) => {
          this.loading = false;
          if (res.data.user.is_publisher) {
            this.$store.dispatch("LOGIN_SUCCESS", res.data);
            this.$router.push({ name: "Dashboard" });
            this.getChannels();
          } else {
            this.result = "אינך מורשה. אנא פנה לצוות כולל";
            this.showResult = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch("LOGIN_FAILED");
          this.result = "Invalid account!";
          this.showResult = true;
        });
    },
    onClickGoogleLogin() {
      if (this.googleClient) this.googleClient.requestAccessToken()
    },
    login() {
      const vm = this;
      if (!vm.userEmail || !vm.password) {
        vm.result = "Email and Password can't be null.";
        vm.showResult = true;
        return;
      }
      vm.loading = true;
      const params = {
        email: vm.userEmail,
        password: vm.password,
      };
      api
        .login(params)
        .then((res) => {
          vm.loading = false;
          if (res.data.user.is_publisher) {
            vm.$store.dispatch("LOGIN_SUCCESS", res.data);
            vm.getChannels();
          } else {
            vm.error = true;
            vm.result = "אינך מורשה. אנא פנה לצוות כולל";
            vm.showResult = true;
          }
        })
        .catch((error) => {
          vm.loading = false;
          console.log(error);
          this.$store.dispatch("LOGIN_FAILED");
          vm.error = true;
          vm.result = "Invalid account!";
          vm.showResult = true;
        });
    },
    getChannels() {
      api
        .getChannels()
        .then((res) => {
          this.$store.dispatch("SET_CHANNELS", res.data.channels);
          if (res.data.channels.length > 0) {
            this.$store.dispatch(
              "SET_ACTIVE_CHANNEL_ID",
              res.data.channels[0].channel_id
            );
            this.$router.push({
              path: `/channels/${res.data.channels[0].channel_id}/dashboard`,
            });
          }
        })
        .catch((error) => {
          localStorage.removeItem("token");
          console.log(error);
        });
    },
  },
};
</script>

<style>
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.test {
  border: 1px solid red;
}
.btn {
  min-width: 70px;
  min-height: 70px;
  padding: 0;
}
</style>