<template>
  <v-row class="d-flex justify-space-between pa-5">
    <div class="d-flex">
      <v-avatar>
        <v-img :src="activeChannelImage" class="bg-avatar"></v-img>
      </v-avatar>
      <div class="d-flex flex-column">
        <span class="text-center text-subtitle-1 font-weight-bold">{{
          activeChannelName
        }}</span>
        <v-btn @click="onEditChannel" plain small>
          <span class="text-subtitle-2 black--text">{{$t('edit')}}</span>
        </v-btn>
      </div>
    </div>
    <v-btn
      v-if="addText"
      color="#ff0000"
      class="white--text"
      tile
      width="120"
      @click="onPressAdd"
    >
      {{ this.addText }}
    </v-btn>
  </v-row>
</template>

<script>
export default {
  props: {
    addText: {
      default: ``,
      type: String,
    },
  },
  methods: {
    onPressAdd() {
      return this.$emit("onPressAdd");
    },
    onEditChannel() {
      return this.$router.push({
        path: `/channels/${this.activeChannelId}/edit`,
      });
    },
  },
  computed: {
    activeChannelImage() {
      return this.$store.getters.getActiveChannel
        ? this.$store.getters.getActiveChannel.channel.image
        : "";
    },
    activeChannelName() {
      return this.$store.getters.getActiveChannel
        ? this.$store.getters.getActiveChannel.channel.name
        : "";
    },
    activeChannelId() {
      return this.$store.getters.getActiveChannelId;
    },
  },
};
</script>

<style scoped></style>
