import api from '@/api/api'

const Playlist = {
    state: {
        allPlaylists: [],
        total: 0,
        playlist: [],
        activePlaylistId: null,
        showDialog: false,
        isNewPlaylistCreated: false,
    },

    mutations: {
        setPlaylist: function (state, playlist) {
            state.playlist = playlist
        },
        setAllPlaylists: function (state, data) {
            state.allPlaylists = data.playlists
            if (data.total) {
                state.total = data.total
            }
        },
        setActivePlaylistId: function (state, id) {
            state.activePlaylistId = id
        },
        setShowDialog: function (state, status) {
            state.showDialog = status
        },
        setIsNewOneCreated: function (state, status) {
            state.isNewPlaylistCreated = status
        }
    },

    getters: {
        getPlaylists: function (state) {
            return state.allPlaylists
        },
        getAllPlaylists: function (state) {
            return state.allPlaylists
        },
        getActivePlaylistId: function (state) {
            if (!state.activePlaylistId && state.playlist.length > 0) {
                return state.playlist[0].id;
            }
            return state.activePlaylistId
        },
        getActivePlaylist: function (state) {
            if (state.playlist.length > 0 && state.activePlaylistId) {
                return state.playlist.find(p => p.id == state.activePlaylistId)
            }
            return null
        },
        getShowPlaylistDialog: function (state) {
            return state.showDialog;
        },
        getIsNewPlaylistCreated: function (state) {
            return state.isNewPlaylistCreated;
        },
    },

    actions: {
        FETCH_PLAYLISTS: function ({ commit }, channels) {
            // console.log(channels)
            return new Promise((resolve, reject) => {
                api
                    .getPlaylists(channels.map((o) => o.id))
                    .then((res) => {
                        const data = res.map((item) => ({
                          ...item,
                          channels: [channels.find((channel) => channel.id === item.channel_id)],
                        }))
                        commit('setPlaylist', data);
                        resolve(data);
                    })
                    .catch((e) => {
                        console.log(e);
                        reject(e)
                    });
            })
        },
        FETCH_ALL_PLAYLISTS: function ({ commit }, params) {
            return new Promise((resolve, reject) => {
                api
                    .getAllPlaylists(params)
                    .then((res) => {
                        const playlists = res.data.playlists.map((item) => ({
                          ...item,
                          channels: [item.channel],
                          channel: undefined,
                        }))
                        const data = { total: res.data.total, playlists }
                        commit('setAllPlaylists', data);
                        resolve(data);
                    })
                    .catch((e) => {
                        console.log(e);
                        reject(e)
                    });
            })
        },
        SET_PLAYLIST: function (context, playlist) {
            context.commit('setAllPlaylists', { playlists: playlist })
        },
        CONCAT_PLAYLIST: function ({ state, commit }, playlist) {
            commit('setPlaylist', [...state.playlist, ...playlist]);
        },
        SET_ACTIVE_PLAYLIST_ID: function (context, id) {
            context.commit('setActivePlaylistId', id)
        },
        SET_SHOW_PLAYLIST_DIALOG: function (context, status) {
            context.commit('setShowDialog', status)
        },
        REFRESH_PLAYLIST_AND_PICK: function (context, status) {
            context.commit('setIsNewOneCreated', status)
        },
    }
}

export { Playlist as default }