<template>
  <v-container fluid>
    <v-row>
      <v-col sm="12" md="4">
        <h4>{{ isCreate ? $t('new playlist') : $t('playlist details') }}</h4>
      </v-col>
      <v-col sm="12" md="8" :align="$vuetify.rtl ? 'left' : 'right'">
        <v-btn
          v-if="!enableYTLink"
          color="info"
          class="white--text"
          width="100"
          @click="handleSave"
          :loading="saving"
          :disabled="disabledSave"
        >
          {{ $t('save') }} 
        </v-btn>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col sm="12" md="4">
        <div class="col-container" style="max-height: calc(100vh - 176px);">
          <div>
            <span class="label text-caption font-weight-bold">
              {{ $t("title") }} (*)
            </span>
            <v-text-field v-if="activePlaylist" v-model="activePlaylist.title" single-line outlined required dense />
          </div>
          <div>
            <span class="label text-caption font-weight-bold">
              {{ $t("description") }}
            </span>
            <v-textarea v-if="activePlaylist" v-model="activePlaylist.description" outlined dense />
          </div>
          <div class="d-flex align-center">
            <v-autocomplete
              v-if="isCreate"
              v-model="selectedChannels"
              :items="filteredChannels"
              :label="$t('channels')"
              item-text="name"
              item-value="id"
              multiple
              chips
              small-chips
              deletable-chips
              outlined
              dense
              clearable
              persistent-hint
              return-object
              :search-input.sync="search"
              :filter="customFilter"
              :loading="isLoading"
              placeholder="Type to search more"
            />
            <div v-else>
              <span class="label text-caption font-weight-bold">
                {{ $t("channel") }}
              </span>
              <div>
                <v-chip small @click="handleClickChannel">{{ selectedChannels.name }}</v-chip>
              </div>
            </div>
          </div>
          <div v-if="!channelId && isCreate">
            <v-checkbox :label="$t('youtube playlist')" v-model="enableYTLink" color="info" />
            <div v-if="enableYTLink">
              <span class="label text-caption font-weight-bold">
                {{ $t("YouTube link here") }} (*)
              </span>
              <v-text-field
                v-model="youtubeLink"
                single-line outlined
                required
                dense
                :rules="youtubeRules"
              />
            </div>
          </div>
          <div v-if="!isCreate && activePlaylist && activePlaylist.external_id" style="margin-top: 30px;">
            <span class="label text-caption font-weight-bold">
              {{ $t('youtube playlist id') }}:&nbsp;
            </span>
            <span class="label text-caption">
              {{ activePlaylist && activePlaylist.external_id }}
            </span>
          </div>
          <div v-if="isCreate && enableYTLink">
            <v-btn color="info" class="white--text" width="100" @click="handleSave" :loading="saving" :disabled="disabledSave">
              {{ $t('save') }} 
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col sm="12" md="8">
        <v-list-item v-if="!enableYTLink">
          <v-list-item-icon>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="margin-left: 40px;"
                  color="info"
                  text
                  width="120"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>mdi-plus</v-icon>
                  &nbsp;
                  {{ $t('add video') }} 
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="handleCreateVideo" v-if="!isCreate">
                  <v-list-item-title>New Video</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openVideoPicker">
                  <v-list-item-title>Pick Existing Videos</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-icon>
        </v-list-item>
        <div class="col-container">
          <v-list three-line>
            <draggable v-model="videos">
              <transition-group>
                <v-list-item v-for="(v) in videos" :key="`${v.id}_${Math.random(30)}`">
                  <v-list-item-icon>
                    <v-icon class="grabbable" x-large>mdi-drag-vertical</v-icon>
                    <v-img :src="v.thumb_url" height="80" width="120" class="thumbnails" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ v.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ moment(v.updated_at).format("DD/MM/YYYY") }}</v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-chip color="#00000055" class="mb-3 mr-1 white--text" small label>
                        {{ $helpers.lengthInMinutes(v.duration) }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn icon @click="handleRemoveVideo(v)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </transition-group>
            </draggable>
          </v-list>
        </div>
      </v-col>
    </v-row>
    <video-picker v-if="showVideoPicker" @onPick="handlePickVideos" :channelId="channelId" />
  </v-container>
</template>
  
  <script>
  import Fuse from 'fuse.js';
  import { uniqBy } from 'lodash';
  import draggable from 'vuedraggable';
  import api from '@/api/api';
  import VideoPicker from './VideoPicker.vue';

  export default {
    components: {
        draggable,
        VideoPicker,
    },
    mounted() {
      this.initLoad();
    },
    watch: {
      $route(to) {
        this.channelId = to.params.channel_id;
        this.playlistId = to.params.playlist_id;
        this.initLoad();
      },
      selectedChannels() {
        this.search = null;
      },
      async playlistItems(items) {
        try {
          if (!items) return;
          this.videos = items
            .filter((o) => o.video)
            .map(({ id, video }) => ({
              ...video,
              item_id: id,
            })).flat(1);
        } catch (e) {
          console.log(e);
        }
      },
      search(val) {
        if (!val) {
          this.channels = this.defaultChannels;
          if (this.defaultChannels.length < 3) {
            this.fetchChannels(null);
          }
          return;
        }
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
        this.timeout = setTimeout(() => {
          this.fetchChannels(val);
        }, 500);
      },
    },
    methods: {
      async initLoad() {
        this.$store.dispatch("SET_TOOLBARLOAD", true);
        const channels = this.$store.getters.getChannels.map((o) => o.channel);
        if (this.isCreate) {
          if (this.channelId) {
            this.channels = channels;
            const thisChannel = this.channels.find((o) => o.id === Number(this.channelId));
            this.selectedChannels = thisChannel ? [thisChannel] : [];
          } else {
            this.fetchChannels(null);
          }
          this.resetActivePlaylist();
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          return;
        }
        let list = this.$store.getters.getAllPlaylists;
        const params = { offset: 0, limit: 21, with_video: true };
        if (this.channelId) {
          params.channel_id = Number(this.channelId);
        }
        try {
          const { playlists } = await this.$store.dispatch('FETCH_ALL_PLAYLISTS', params);
          list = playlists;
        } catch (e) {
          list = [];
        }

        this.activePlaylist = this.$store.getters.getActivePlaylist;
        if (!this.activePlaylist) {
          this.activePlaylist = list.find((o) => o.id === Number(this.playlistId));
          if (!this.activePlaylist) {
            try {
              const response = await api.showPlaylist(this.playlistId);
              if (response?.data?.id) {
                this.activePlaylist = {
                  ...response.data,
                  channels: [response.data.channel],
                  channel: undefined,
                };
              } else {
                this.resetActivePlaylist();
              }
            } catch(e) {
              this.resetActivePlaylist();
            }
          }
        }
        this.playlistItems = this.activePlaylist.playlist_items;
        this.selectedChannels = this.activePlaylist.channels[0];
        this.$store.dispatch("SET_TOOLBARLOAD", false);
      },
      resetActivePlaylist() {
        this.activePlaylist = { title: '' };
      },
      action (e) {
        if (e === 'edit') this.before = Object.assign([],this.items)      
        if (e === 'undo') this.items = this.before
        this.editing = !this.editing
      },
      remove (i) {
        this.$delete(this.items, i)
      },
      customFilter (item, queryText) {
        // create a fuse.js instance with some options
        const fuse = new Fuse([item], {
          keys: ['name'], // specify which keys to search
          threshold: 0.3 // specify how fuzzy the search should be
        });
        // return true if the item matches the query text using fuse.js, or false otherwise
        return fuse.search(queryText).length > 0
      },
      handleSave() {
        if (this.isCreate) {
          const list = this.$store.getters.getPlaylists;
          const params = {
            title: this.activePlaylist?.title,
            description: this.activePlaylist.description,
            channelIds: this.selectedChannels.map((o) => o.id),
            platform: this.enableYTLink ? 'youtube' : null,
            externalId: null,
            status: 'draft'
          };
          if (this.videos) {
            params.playlistItems = this.videos.map((video, index) => ({
              video_id: video.id,
              position: index + 1,
            }))
          }
          if (this.enableYTLink) {
            let listId = this.youtubeLink.split('list=')[1].split('&index=')[0];
            params.externalId = listId;
          }
          this.saving = true;
          api.upsertPlaylist(params)
            .then(({ data }) => {
              list.push(...data.map((o) => ({ ...o, channels: [o.channel], channel: undefined })));
              this.$store.dispatch("SET_PLAYLIST", list);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.saving = false;
              if (this.channelId) {
                this.$router.push({ path: `/channels/${this.channelId}/playlist` });
              } else {
                this.$router.push({ path: `/playlist` });
              }
            });
        } else {
          const params = {
            id: this.activePlaylist.id,
            title: this.activePlaylist?.title,
            description: this.activePlaylist.description,
            platform: this.activePlaylist.platform,
            externalId: this.activePlaylist.external_id,
            channelIds: [this.activePlaylist.channel_id],
            new_channel_id: this.selectedChannels.id,
            status: this.activePlaylist.status,
            playlistItems: this.videos.map((video, index) => ({
              id: video.item_id,
              video_id: video.id,
              playlist_id: this.activePlaylist.id,
              position: index + 1,
            })),
          };
          this.saving = true;
          api.upsertPlaylist(params)
            .then(({ data }) => {
              this.saving = false;
              const list = this.$store.getters.getPlaylists;
              const playlistIndex = list.findIndex((o) => o.id === this.activePlaylist.id);
              list[playlistIndex] = {
                ...data,
                channels: [this.selectedChannels],
              };
              this.$store.dispatch("SET_PLAYLIST", list);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.saving = false;
            });
        }
      },
      handleCreateVideo() {
        if (this.channelId) {
          this.$router.push({ path: `/channels/${this.channelId}/video/add` });
        } else {
          this.$router.push({ path: `/library/video/add` });
        }
      },
      openVideoPicker() {
        this.showVideoPicker = true;
      },
      handlePickVideos(videos) {
        if (videos) {
          this.videos = uniqBy([...videos, ...this.videos], (o) => o.id);
        }
        this.showVideoPicker = false;
      },
      handleRemoveVideo(video) {
        this.videos = this.videos.filter((o) => o.id !== video.id)
      },
      fetchChannels(term) {
        this.isLoading = true;
        if (term) {
          api
            .searchChannels(term, this.isCreate)
            .then((res) => {
              if (res.data && res.data.items.length > 0) {
                this.channels = res.data.items;
              } else {
                this.channels = [];
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          const params = { offset: 0, limit: 5, o_n_o: true };
          api
            .getChannelsCatalog(params)
            .then((res) => {
              if (res.data && res.data.channels.length > 0) {
                this.defaultChannels = res.data.channels;
                this.channels = res.data.channels;
              } else {
                this.channels = [];
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
      handleClickChannel() {
        this.$router.push({ path: `/channels/${this.selectedChannels.id}/dashboard` });
      }
    },
    computed: {
      filteredChannels () {
        const combined = uniqBy([...this.channels, ...this.selectedChannels], (o) => o.id);
        // create a fuse.js instance with some options
        const fuse = new Fuse(combined, {
          keys: ['name'], // specify which keys to search
          threshold: 0.3 // specify how fuzzy the search should be
        })
        // if there is no search input, return all channels
        if (!this.search) return combined;
        // otherwise, return the filtered channels using fuse.js
        return uniqBy(fuse.search(this.search).map(result => result.item).concat(this.selectedChannels), (o) => o.id);
      },
      isCreate() {
        return this.playlistId === 'create';
      },
      disabledSave() {
        if (this.isCreate) {
          return !this.activePlaylist?.title || !this.selectedChannels.length || (this.enableYTLink && !this.youtubePlaylistRegEx.test(this.youtubeLink));
        }
        return !this.activePlaylist?.title || !this.selectedChannels;
      },
    },
    data() {
      return {
        channelId: this.$route.params.channel_id,
        playlistId: this.$route.params.playlist_id,
        activePlaylist: { title: '' }, // this.$store.getters.getActivePlaylist,
        videos: [],
        before: null,
        editing: false,
        enableYTLink: false,
        selectedChannels: [],
        search: null,
        channels: [],
        isLoading: false,
        playlistItems: [],
        saving: false,
        timeout: null,
        defaultChannels: [],
        youtubePlaylistRegEx: /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube(-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=|live\/))((\w|-){11})(?:\S+)?([?&]list=([\w\-.]{18}))?$/,
        youtubeRules: [(v) => !!v || "נדרשת כתובת אתר של YouTube",
          (v) => this.youtubePlaylistRegEx.test(v) || "Invalid Youtube URL" 
        ],
        youtubeLink: '',
        showVideoPicker: false,
      };
    },
  };
  </script>
  
  <style scoped>
  @media (max-width:600px) {
    .search-box {
      width: 100%;
      border: 1px solid black
    }
  }
  
  @media (min-width: 600px) and (max-width: 1264px) {
    .search-box {
      width: 40%;
      border: 1px solid black
    }
  }
  
  @media (min-width: 1264px) {
    .search-box {
      width: 30%;
      border: 1px solid black
    }
  }
  
  .playlist-icon-box {
    border: solid 1px #ddd;
    background-color: #fff;
    height: 80px;
    width: 120px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .playlist-icon-box:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  .item-row {
    cursor: pointer;
  }
  .thumbnails {
    border-radius: 5px;
    background-color: #000;
  }
  .grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .mark-img {
    width: 70px;
    justify-content: center;
    display: flex;
    align-items: flex-start;
  }
   /* (Optional) Apply a "closed-hand" cursor during drag operation. */
  .grabbable:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
  }
  .col-container {
    overflow-y: auto;
    max-height: calc(100vh - 240px);
  }
  </style>
  