import Navigation from '../components/core/NavigationDrawer.vue'
import TopToolbar from '../components/core/TopToolbar.vue'
import AddVideoView from '../components/AddVideoView.vue'
import StatusView from '../components/StatusView.vue'
import NewVideoItem from '../components/NewVideoItem.vue'
import VideoItem from '../components/VideoItem.vue'
import PlacesTimePicker from '../components/PlacesTimePicker.vue'
import FeedVideoItem from '../components/FeedVideoItem.vue'
import StoryPreview from '../components/story/StoryPreview.vue'
import MessageItem from '../components/MessageItem.vue'
import PlaylistItem from '../components/PlaylistItem.vue'

function setupComponents(Vue){
    Vue.component('top-toolbar', TopToolbar);
    Vue.component('top-navigation', Navigation);
    Vue.component('add-video-view', AddVideoView);
    Vue.component('status-view', StatusView);
    Vue.component('new-video-item', NewVideoItem);
    Vue.component('video-item', VideoItem);
    Vue.component('places-time-picker', PlacesTimePicker);
    Vue.component('feed-video-item', FeedVideoItem);
    Vue.component('story-preview', StoryPreview);
    Vue.component('message-item', MessageItem);
    Vue.component('message-item', MessageItem);
    Vue.component('playlist-item', PlaylistItem);
}

export {
  setupComponents
}