
import api, {HTTP} from '@/api/api';

const User = {
    state: {
        user: null,
        language: null,
    },

    mutations: {
        setUser: function (state, user) {
            state.user = user
        },
        setLanguage: function (state, language) {
            state.language = language
        }
    },

    getters: {
        getUser: function (state) {
            return state.user
        },
        getName: function (state) {
            if (state.user) {
                return state.user.name
            } else {
                return ''
            }
        },
        getLanguage: function (state) {
            return state.language
        }
    },
    
    actions: {
        LOGIN_SUCCESS: function (context, data) {
            // let user = JSON.stringify(data.user)
            localStorage.setItem('token', data.token)
            // localStorage.setItem('user', user)
            localStorage.setItem('language', 'he')
            HTTP.defaults.headers['token'] = data.token
            // HTTP.defaults.headers['app_language'] = 'he'
            context.commit('setUser', data.user)
        },
        LOGIN_FAILED: function () {
            localStorage.removeItem('token')
            // localStorage.removeItem('user')
        },
        LOGOUT: function (context) {
            localStorage.removeItem('token')
            // localStorage.removeItem('user')
            context.commit('setUser', null)
        },
        SET_LANGUAGE: function (context, data) {
            localStorage.setItem('language', data)
            // HTTP.defaults.headers['app_language'] = data
            context.commit('setLanguage', data)
        },
        GET_CURRENT_USER:  function ({ commit, state }) {
            if (state.user) {
                return state.user;
            }
            return new Promise((resolve) => {
                api.getCurrentUser()
                    .then(res => {
                        if (res.data.user) {
                            commit('setUser', res.data.user)
                            resolve(res.data.user)
                        } else {
                            commit('setUser', null);
                            resolve(null)
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        commit('setUser', null);
                        resolve(null)
                    })
            })
        },
    }
}

export { User as default }