<template>
  <v-container fluid>
    <add-video-view @onPressAdd="navigateAdd" :add-text="`+ ${$t('new video')}`"/>
    <v-row class="d-flex flex-column">
      <v-col
        class="d-flex align-center"
        xl="2"
        lg="2"
        md="3"
        sm="4"
        xs="12"
        cols="12">
        <v-select
          class="lang-select"
          v-model="selectedLang"
          :items="languages"
          item-text="name"
          item-value="val"
          single-line
          outlined
          dense
          hide-details
        />
      </v-col>
    </v-row>
    <v-row class="grey lighten-4 d-flex flex-column">
      <span class="text-subtitle-2 font-weight-bold px-5 pt-2">{{$t('status')}}</span>
      <v-row class="d-flex justify-center">
        <status-view :state="status.followers" />
        <status-view :state="status.videos" />
        <status-view :state="status.views" />
        <status-view :state="status.profileViews" />
      </v-row>
    </v-row>
    <v-row v-if="videos.length > 0" class="pa-4 d-flex flex-column">
      <span class="text-subtitle-2 font-weight-bold"
        >(!) יש לך {{ total }} סרטונים הממתינים לפרסום</span
      >
      <v-row class="d-flex flex-wrap  py-4" no-gutters>
        <v-col
          xl="4"
          lg="4"
          md="6"
          sm="6"
          xs="12"
          cols="12"
          v-for="video in videos"
          :key="video.id"
        >
          <new-video-item
            :video="video"
            @refresh="initLoad"
            @onPublished="onPublished"
          />
        </v-col>
      </v-row>
    </v-row>
    <v-row v-if="showLoadMore" class="justify-center">
      <v-btn text color="red" @click="this.getPendingVideos">
        — {{$t('show more')}} —
      </v-btn>
    </v-row>

    <v-row v-if="mostViewedVideos.length > 0" class="pa-4 d-flex flex-column">
      <span class="text-subtitle-2 font-weight-bold"> {{$t('most watched')}} </span>
      <v-row class="d-flex flex-wrap  py-4" no-gutters>
        <v-col
          xl="4"
          lg="4"
          md="6"
          sm="6"
          xs="12"
          cols="12"
          v-for="video in mostViewedVideos"
          :key="video.id"
        >
          <video-item :video="video" @refresh="initLoad" />
        </v-col>
      </v-row>
    </v-row>
    <v-snackbar v-model="showMessage" :timeout="2000" top>
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  mounted() {
    this.initLoad();
  },

  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.channelId = to.params.channel_id;
        this.initLoad();
      }
    },
    selectedLang: {
      handler: function() {
        this.initLoad();
      }
    }
  },
  methods: {
    navigateAdd() {
      this.$router.push({ path: `/channels/${this.channelId}/video/add` });
    },
    initLoad() {
      this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      this.videos = [];
      this.page = 1;
      this.getPendingVideos();
      this.getData();
    },
    getData() {
      api
        .getStatus(this.channelId, this.selectedLang)
        .then((res) => {
          this.status.followers.value = this.convertStatus(res.data.followers);
          this.status.videos.value = this.convertStatus(res.data.videos);
          this.status.views.value = this.convertStatus(res.data.views);
          this.status.profileViews.value = this.convertStatus(res.data.visits);
        })
        .catch((err) => {
          console.log(err);
        });

      const params = {
        filter: 1,
        limit: 3,
        offset: 0,
        sort_by: "views",
        channel_id: this.channelId,
        show_comments: true,
      };

      api
        .getChannelVideos(params, this.selectedLang)
        .then((res) => {
          this.mostViewedVideos = res.data.videos;
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },

    getPendingVideos() {
      const params = {
        filter: 3,
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        channel_id: this.channelId,
      };

      api
        .getChannelVideos(params)
        .then((res) => {
          this.videos = this.videos.concat(res.data.videos);
          this.page = this.page + 1;
          this.total = res.data.total;
          this.showLoadMore = res.data.videos.length == this.limit;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    convertStatus(num) {
      if (num < 1000) {
        return num;
      } else if (1000 <= num && num < 5000) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return (num / 1000).toFixed(1) + "K";
      }
    },

    onPublished() {
      this.initLoad();
      this.message = "פורסם בהצלחה!";
      this.showMessage = true;
    },
  },
  data() {
    let languages = [
        {name: this.$t("hebrew"), val: "he"},
        {name: this.$t("english"), val: "en"},
    ];
    return {
      status: {
        followers: {
          name: `${this.$t('followers')}`,
          value: "0",
        },
        videos: {
          name: `${this.$t('videos')}`,
          value: "0",
        },
        views: {
          name: `${this.$t('views')}`,
          value: "0",
        },
        profileViews: {
          name: `${this.$t('profile views')}`,
          value: "0",
        },
      },
      videos: [],
      mostViewedVideos: [],
      limit: 6,
      total: 0,
      page: 1,
      channelId: this.$route.params.channel_id,
      message: "",
      showMessage: false,
      showLoadMore: false,
      languages: languages,
      selectedLang: languages[0].val,
    };
  },
};
</script>

<style scoped>
.lang-select {
  border-radius: 0px;
  max-width: 250px;
}
</style>
