<template>
  <div class="d-flex pa-2 playlist-container" no-gutters>
    <div class="justify-right d-flex pl-2" style="margin-right: 20px">
      <div
        class="video-image d-flex justify-space-between align-end"
        :style="
          playlistItems.length === 0
            ? 'background-color: #000'
            : `background-image: url(${thumbnail})`"
         @click="$emit('onPlay', playlistItems.length ? playlist : null)"
      >
        <div class="playlist-icon-box">
          <v-icon size="60" color="#ddd">
            mdi-playlist-play
          </v-icon>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column playlist-desc"
      @click="$emit('onClick', playlist)"
    >
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex flex-column">
          <span 
            v-if="playlist.channels && playlist.channels[0]"
            class="text-caption font-weight-bold"
            @click.stop="handleClickChannel"
          >
            {{ playlist.channels[0].name }}
          </span>
          <span class="text-caption text-title">
            {{ playlist.title }}
          </span>
          <span class="text-caption text-title">
            {{ playlist.description }}
          </span>
          <span v-if="playlist.updated_at" class="text-caption"
            >{{ $t("date") }}
            {{ moment(playlist.updated_at).format("DD/MM/YYYY") }}
          </span>
          <span class="text-caption text-title">
            {{ playlistItems.length }} video(s)
          </span>
          <span class="text-caption text-title" style="font-size: 11px !important;" v-if="showProgress">
            <v-progress-circular indeterminate color="secondary" size="20" />
            {{ $t('please wait for videos uploaded') }}
          </span>
        </div>

        <v-menu top offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="black" v-bind="attrs" v-on="on">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list min-width="200" flat>
            <v-list-item-group class="mx-2">
              <v-list-item
                dense
                class="underline px-0"
                @click="$emit('setStatus', playlist)"
              >
                <v-list-item-title>
                  <span class="text-subtitle-2"> {{ moveTab }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                dense
                class="underline px-0"
                @click="$emit('addPlaylistToChannel', playlist)"
              >
                <v-list-item-title>
                  <span class="text-subtitle-2"> {{ $t("Copy to another channel") }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                dense
                class="underline px-0"
                @click.stop="showDeleteDlg = true"
              >
                <v-list-item-title red--text>
                  <span class="text-subtitle-2 red--text"> {{$t('delete playlist')}} </span>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item class="justify-end px-2">
              <v-btn text>{{ $t("closed") }}</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-dialog v-model="showDeleteDlg" max-width="520">
        <v-card class="pa-10">
          <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
           {{$t('are you sure remove the playlist')}}
          </v-card-title>
          <div class="d-flex">
            <div style="position: relative;">
              <v-img
                :width="100"
                :aspect-ratio="16 / 9"
                contain
                :src="thumbnail"
                style="background-color: #000;"
              />
              <div class="playlist-icon-box" style="position: absolute; top: 0;">
                <v-icon size="30" color="#ddd">
                  mdi-playlist-play
                </v-icon>
              </div>
            </div>
            <span class="mr-5 text-subtitle-2"> {{ playlist.title }} </span>
          </div>

          <v-card-actions class="d-flex justify-space-between px-0 mt-10">
            <v-btn
              class="white--text flex-grow-1"
              color="#616161"
              tile
              x-large
              @click="showDeleteDlg = false"
            >
              <span class="text-h5">{{$t('cancel')}}</span>
            </v-btn>
            <v-btn
              class="white--text flex-grow-1"
              color="#ff0000"
              tile
              x-large
              @click="onDeletePlaylist()"
              :loading="loading"
              :disabled="loading"
            >
              <span class="text-h5">{{$t('delete')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
  
  <script>
import api from '@/api/api';

export default {
  props: {
    playlist: {
      id: Number,
      title: String,
      desciption: String,
      playlist_items: String,
      channels: Array,
      external_id: String,
      updated_at: String,
    },
  },
  data() {
    return {
      init: true,
      playing: false,
      showDeleteDlg: false,
      loading: false,
      showRemoveFromRecommendedDlg: false,
    };
  },
  computed: {
    moveTab() {
      if (this.playlist.status === 'published') {
        return this.$t('move to awaiting');
      }
      return this.$t('publish it')
    },
    thumbnail() {
      return this.playlistItems[0]?.video?.thumb_url || '';
    },
    showProgress() {
      return this.playlist?.external_id && !this.playlistItems.length;
    },
    playlistItems() {
      return this.playlist?.playlist_items?.filter((o) => o.video) || [];
    }
  },
  methods: {
    onDeletePlaylist() {
      this.loading = true;
      api
        .deletePlaylist(this.playlist.id)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.showDeleteDlg = false;
        });
    },
    handleClickChannel() {
      this.$router.push({ path: `/channels/${this.playlist.channel_id}/dashboard` });
    }
  },
};
</script>
  
  <style>
.test {
  border: 1px solid red;
}
.text-title {
  overflow: hidden;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.playlist-image {
  height: 120px;
  aspect-ratio: 16 / 9;
  background: black;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
}
.playlist-desc {
  flex: 1;
  cursor: pointer;
}

.playlist-container {
  flex-direction: row;
}

.nowrap {
  white-space: nowrap;
}

@media (min-width: 200px) {
  .playlist-container {
    flex-direction: column;
  }
}

@media (min-width: 500px) {
  .playlist-container {
    flex-direction: row;
  }
}

@media (min-width: 600px) {
  .playlist-container {
    flex-direction: column;
  }
}

@media (min-width: 960px) {
  .playlist-container {
    flex-direction: row;
  }
}

@media (min-width: 1264px) {
  .playlist-container {
    flex-direction: column;
  }
}
@media (min-width: 1600px) {
  .playlist-container {
    flex-direction: row;
  }
}

.playlist-icon-box {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.playlist-icon-box:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
</style>