import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"d-flex flex-wrap py-4",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"hide-default-footer":"","item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"white--text",attrs:{"small":"","rounded":"","color":"#848484"},on:{"click":function($event){return _vm.openMessage(item)}}},[_vm._v(" "+_vm._s(_vm.$t("open message"))+" ")])]}},{key:"item.last_message",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"last-message"},[_vm._v(_vm._s(_vm.formatMessage(item.last_message)))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }