<template>
  <v-container fluid>
    <v-row class="d-flex flex-wrap py-4" no-gutters>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="data"
          hide-default-footer
          class="elevation-1"
          :item-class="itemRowBackground"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              small
              rounded
              @click="openMessage(item)"
              class="white--text"
              color="#848484"
            >
              {{ $t("open message") }}
            </v-btn>
          </template>
          <template v-slot:[`item.last_message`]="{ item }">
            <div class="last-message">{{ formatMessage(item.last_message) }}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      limit: 25,
      offset: 0,
      headers: [
        { text: `${this.$t("by")}`, value: "user_name", sortable: false },
        { text: `${this.$t("email")}`, value: "user_email", sortable: false },
        { text: `${this.$t("date")}`, value: "date", sortable: false },
        { text: `${this.$t("hour")}`, value: "hour", sortable: false },
        { text: 'Object ID', value: "object_id", sortable: false },
        {
          text: `${this.$t("last message")}`,
          value: "last_message",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false },
      ],
      data: [],
    };
  },
  mounted() {
    this.data = [];
    this.fetchConversations();
  },
  methods: {
    fetchConversations() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api.fetchCommboxMessages()
        .then((res) => {
          this.data = res.data.map((item) => {
            return {
              ...item,
              date: moment(item.closed_time).format('DD/MM/YYYY'),
              hour: moment(item.closed_time).format('HH:mm'),
              last_message: item.payload?.[0]?.message
            }
          });
        })
        .catch((e) => console.log(e))
        .finally(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        })
    },
    openMessage(item) {
      this.$router.push({ path: `/commbox-conversations/${item.id}` });
    },
    formatMessage(msg) {
      return msg
        .replace(/<br>/g, ' ')
        .replace(/<p>/g, ' ')
        .replace(/<\/p>/g, '');
    }
  },
}
</script>

<style scoped>
  .last-message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px !important;
  }
</style>
