<template>
  <v-container fluid>
    <v-row class="d-flex flex-wrap py-4" no-gutters>
      <v-col cols="12">
        <div v-if="hasData">
          <div class="text-subtitle-1 font-weight-bold">
            {{ data.user_name }} - {{ data.user_email }}
          </div>
        </div>
        <v-divider />
        <div v-if="hasData" class="chat-container">
          <message-item
            v-for="(item, index) in messages"
            :key="index"
            :val="item"
          />
        </div>
        <v-divider class="mt-5" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/api/api";
import moment from "moment";
import MessageItem from "./MessageItem.vue";

export default {
  components: { MessageItem },
  data() {
    return {
      id: this.$route.params.id,
      limit: 25,
      offset: 0,
      data: null,
    };
  },
  watch: {
    $route(to) {
      if (to.params.id) {
        this.id = to.params.id;
        this.fetchConversation();
      }
    },
  },
  computed: {
    hasData() {
      return !!this.data;
    },
    messages() {
      if (!this.data?.payload?.[0]?.childs?.length) {
        return [];
      }
      return this.data.payload[0].childs;
    },
  },
  mounted() {
    this.data = [];
    this.fetchConversation();
  },
  methods: {
    fetchConversation() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .showCommboxMessage(this.id)
        .then((res) => {
          if (!res.data) {
            return;
          }
          this.data = {
            ...res.data,
            date: moment(res.data.closed_time).format("DD/MM/YYYY"),
            hour: moment(res.data.closed_time).format("HH:mm"),
            last_message: res.data.payload?.[0]?.message,
          };
          console.log(this.data);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
    formatMessage(msg) {
      return msg
        .replace(/<br>/g, " ")
        .replace(/<p>/g, " ")
        .replace(/<\/p>/g, "");
    },
  },
};
</script>

<style scoped>
.last-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px !important;
}
.chat-container {
  margin-top: 24px;
  max-height: 50vh;
  overflow-y: auto;
}
</style>
