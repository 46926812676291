<template>
  <v-container fluid>
    <h3>פרטי משתמש</h3>
    <v-row class="d-flex align-center justify-space-between" no-gutters>
      <v-col xl="4" lg="6" md="8" sm="12" xs="12" cols="12" no-gutters>
        <div class="text-subtitle-2 font-weight-bold">שם מלא</div>
        <div class="d-flex align-center">
          <div class="text-caption">
            {{ this.user.name }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-space-between" no-gutters>
      <v-col xl="4" lg="6" md="8" sm="12" xs="12" cols="12" no-gutters>
        <div class="text-subtitle-2 font-weight-bold">אימייל</div>
        <div class="d-flex align-center">
          <div class="text-caption">
            {{ this.user.email }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-space-between" no-gutters>
      <v-col xl="4" lg="6" md="8" sm="12" xs="12" cols="12" no-gutters>
        <div class="text-subtitle-2 font-weight-bold">מין</div>
        <div class="d-flex align-center">
          <div class="text-caption">
            {{ this.user.gender }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="pa-1">
      <v-col lg="7" md="10" sm="12" xs="12">
        <h4>הרשאות למשתמש</h4>
        <v-form class="main-row" ref="form">
          <v-autocomplete
            v-model="selectedChannel"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            color=""
            clearable
            hide-no-data
            hide-selected
            item-text="name"
            item-value="id"
            dense
            label="חפש ערוץ להענקת הרשאות"
            outlined
            placeholder="הקלד לחיפוש"
            return-object
          ></v-autocomplete>          
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">ערוץ</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">פעולות</span>
                </td>
              </thead>
              <tbody>
                <tr :key="permission.id" v-for="permission in permissions">
                  <td>
                    <div class="d-flex justify-right">
                      {{ permission.channel.name }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-right">
                      <v-btn
                        outlined
                        small
                        @click="changePermission(permission.channel, false)"
                      >
                        הסר הרשאה
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import api from "@/api/api";

export default {
  data() {
    return {
      user: {},
      permissions: [],
      isLoading: false,
      selectedChannel: null,
      search: null,
      entries: [],
      nameLimit: 50,
    };
  },

  mounted() {
    this.getData();
  },

  computed: {
    items() {
      return this.entries.map((entry) => {
        const name =
          entry.name.length > this.nameLimit
            ? entry.name.slice(0, this.nameLimit) + "..."
            : entry.name;

        return Object.assign({}, entry, { name });
      });
    },
  },

  watch: {
    search(val) {
      if (this.isLoading) return;

      this.isLoading = true;
      api.searchChannels(val)
        .then((res) => {
          const { total_count, items } = res.data;
          this.total_count = total_count;
          this.entries = items;
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error);
        });
    },
    selectedChannel: {
      handler: function () {
        this.changePermission(this.selectedChannel, true);
      },
    },
  },

  methods: {
    changePermission(channel, addOrRemove) {
      api.changePermission(this.user, channel, addOrRemove).then((res) => {
        this.permissions = res.data.permissions;
      });
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .getUser(this.$route.params.id)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          this.user = res.data.user;
          api.getUserPermissions(this.user).then((res) => {
            this.permissions = res.data.channels;
          });
        })
        .catch((err) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.main-row {
  flex: 1;
}
.input {
  border-radius: 0px;
  font-size: 12px;
}
.date-input {
  border-radius: 0px;
  font-size: 12px;
  min-width: 100px;
  max-width: 150px;
  margin-left: 20px !important;
}
.mark-img {
  width: 70px;
  justify-content: center;
  display: flex;
  align-items: flex-start;
}
.custom-radio {
  margin-bottom: 0px !important;
}
.custom-picker {
  max-width: 350px !important;
}
</style>
