<template>
  <div>
    <v-dialog v-model="show" max-width="600" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ $t("new playlist") }}
        </v-card-title>
        <v-card-text>
          <div>
            <span class="label text-caption font-weight-bold">
              {{ $t("title") }} (*)
            </span>
            <v-text-field v-model="title" single-line outlined required dense />
          </div>
          <div>
            <span class="label text-caption font-weight-bold">
              {{ $t("description") }}
            </span>
            <v-textarea v-model="description" outlined dense />
          </div>
          <div class="d-flex align-center">
            <v-autocomplete
              v-model="selectedChannel"
              :items="filteredChannels"
              :label="$t('channels')"
              item-text="name"
              item-value="id"
              chips
              small-chips
              deletable-chips
              outlined
              dense
              clearable
              persistent-hint
              return-object
              :search-input.sync="search"
              :filter="customFilter"
              :loading="isLoading"
              placeholder="Type to search more"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="confirm(true)"
            :disabled="disabled"
            :loading="saving"
            >{{ $t("save") }}</v-btn
          >
          <v-btn color="red darken-1" text @click="confirm(false)">{{
            $t("cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
      
<script>
import Fuse from 'fuse.js';
import api from "@/api/api";

export default {
  data() {
    return {
      title: "",
      description: "",
      saving: false,
      show: true,
      selectedChannel: null,
      search: null,
      channels: [],
      isLoading: false,
      channelId: this.$route.params.channel_id,
      timeout: null,
      defaultChannels: [],
    };
  },
  computed: {
    disabled() {
      return !this.title || !this.selectedChannel;
    },
    filteredChannels () {
      // create a fuse.js instance with some options
      const fuse = new Fuse(this.channels, {
        keys: ['name'], // specify which keys to search
        threshold: 0.3 // specify how fuzzy the search should be
      })
      // if there is no search input, return all channels
      if (!this.search) return this.channels
      // otherwise, return the filtered channels using fuse.js
      return fuse.search(this.search).map(result => result.item)
    }
  },
  mounted() {
    this.initLoad();
  },
  watch: {
    selectedChannel() {
      this.search = null;
    },
    $route(to) {
      this.channelId = to.params.channel_id;
      this.initLoad();
    },
    search(val) {
      if (!val) {
        this.channels = this.defaultChannels;
        if (this.defaultChannels.length < 3) {
          this.fetchChannels(null);
        }
        return;
      }
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.fetchChannels(val);
      }, 500);
    }
  },
  methods: {
    initLoad() {
      if (this.channelId) {
        this.channels = this.$store.getters.getChannels?.map((o) => o.channel);
        this.selectedChannel = this.channels.find((o) => o.id === Number(this.channelId));
        // this.fetchChannels(this.selectedChannel?.name);
        this.search = this.selectedChannel?.name;
      } else {
        this.fetchChannels(null);
      }
    },
    async confirm(isConfirmed) {
      if (isConfirmed) {
        const list = this.$store.getters.getPlaylists;
        const params = {
          title: this.title,
          description: this.description,
          channelIds: [this.selectedChannel.id],
          platform: null,
          externalId: null,
          status: 'draft',
        };
        this.saving = true;
        api.upsertPlaylist(params)
          .then(({ data }) => {
            list.push(...data.map((o) => ({ ...o, channels: [o.channel], channel: undefined })));
            this.$store.dispatch("SET_PLAYLIST", list);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.saving = false;
            this.$store.dispatch("REFRESH_PLAYLIST_AND_PICK", true);
          });
      }
      this.$emit("onDone", isConfirmed);
      this.$store.dispatch("SET_SHOW_PLAYLIST_DIALOG", false);
    },
    customFilter (item, queryText) {
      // create a fuse.js instance with some options
      const fuse = new Fuse([item], {
        keys: ['name'], // specify which keys to search
        threshold: 0.3 // specify how fuzzy the search should be
      });
      // return true if the item matches the query text using fuse.js, or false otherwise
      return fuse.search(queryText).length > 0
    },
    fetchChannels(term) {
      this.isLoading = true;
      if (term) {
        api
          .searchChannels(term, true)
          .then((res) => {
            if (res.data && res.data.items.length > 0) {
              this.channels = res.data.items;
            } else {
              this.channels = [];
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        const params = { offset: 0, limit: 5, o_n_o: true };
        api
          .getChannelsCatalog(params)
          .then((res) => {
            if (res.data && res.data.channels.length > 0) {
              this.defaultChannels = res.data.channels;
              this.channels = res.data.channels;
            } else {
              this.channels = [];
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  },
};
</script>
  