<template>
  <div class="event-box">
    <v-btn
      icon
      large
      class="remove-btn"
      @click="$emit('remove', index)"
      v-if="!hideRemoveBtn"
      :style="{ left: `${$store.getters.getLanguage === 'en' ? undefined : 0}`, right: `${$store.getters.getLanguage === 'en' ? 0 : undefined}` }" 
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="d-flex align-start mt-2">
      <div class="d-flex py-2 align-end">
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex flex-column">
              <span class="text-subtitle-2 font-weight-bold mb-2">
                *{{ $t("event date") }}
              </span>
              <v-text-field
                v-model="dateFormatted"
                class="date-input"
                append-icon="mdi-menu-down"
                persistent-hint
                dense
                outlined
                single-line
                v-bind="attrs"
                @blur="localDate = parseDate(dateFormatted)"
                v-on="on"
              />
            </div>
          </template>
          <v-date-picker
            v-model="localDate"
            no-title
            :allowed-dates="allowedDates"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-select
          v-model="localSelectedTimezone"
          :items="timezoneLits"
          item-text="name"
          item-value="zone"
          :label="$t('choose')"
          dense
          outlined
          single-line
          class="timezone-input mx-2"
          return-object
          :hint="currentTime"
          persistent-hint
        />
      </div>
      <div class="ml-3">
        <div class="d-flex align-end mt-3" v-for="(item, index) in timeItems" :key="index">
          <div class="d-flex flex-column ml-2 mb-2">
            <span class="text-subtitle-2 font-weight-bold mb-2">
              {{ $t('begining time') }}
            </span>
            <vue-timepicker v-model="item.start" format="hh:mm A"></vue-timepicker>
          </div>
          <div class="d-flex flex-column ml-2 mb-2">
            <span class="text-subtitle-2 font-weight-bold mb-2">
              {{ $t('end time') }}
            </span>
            <vue-timepicker v-model="item.end" format="hh:mm A"></vue-timepicker>
          </div>
          <v-btn icon class='ml-3 mb-2' v-if="index !== 0" @click="removeTime(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-btn
          v-if="!isEdit"
          outlined
          color="primary"
          class="d-block ml-2 mt-2"
          @click="handleAddTime"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('add time') }}
        </v-btn>
      </div>
    </div>
    <div class="mt-4">
      <span class="text-subtitle-2 font-weight-bold mt-4">
        {{ $t("one-time or permanent event") }}
      </span>
    </div>
    <div class="d-flex py-2">
      <v-radio-group v-model="localRecurring" row>
        <v-radio color="black" value="0">
          <template v-slot:label>
            <span class="text-caption">{{ $t("one-time") }}</span>
          </template>
        </v-radio>
        <v-radio color="black" value="1">
          <template v-slot:label>
            <span class="text-caption">
              {{ $t("regular every week at the same day and time") }}
            </span>
          </template>
        </v-radio>
        <v-radio color="black" value="2">
          <template v-slot:label>
            <span class="text-caption">
              {{ $t("regular every 2 weeks at the same day and time") }}
            </span>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
  </div>
</template>
<script>
import moment from "moment-timezone";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import timezones from "../../config/timezone.js";

export default {
  components: { VueTimepicker },
  props: ["index", "eventData", "audiences", "hideRemoveBtn", "isEdit"],
  data() {
    return {
      timeItems: [
        { start: null, end: null, event_id: null }
      ],
      localDate: moment(new Date()).tz(timezones[0].zone).format("YYYY-MM-DD"),
      localRecurring: '0',
      dateFormatted: this.formatDate(moment(new Date()).tz(timezones[0].zone).format("YYYY-MM-DD")),
      dateMenu: false,
      timezoneLits: timezones,
      localSelectedTimezone: timezones[0],
      now: Date.now(),
      interval: null
    };
  },
  watch: {
    localDate(val) {
      this.dateFormatted = this.formatDate(val);
      this.$emit("update", { index: this.index, key: "date", value: val });
    },
    timeItems: {
      handler(val) {
        const validTime = (t) => typeof t === 'string' ? t.length === 8 : t?.hh && t?.mm && t?.A;
        const timeToStr = (t) => moment(typeof t === 'string' ? t : `${t.hh}:${t.mm} ${t.A}`, 'hh:mm A').format('HH:mm:ss');

        this.$emit("update", {
          index: this.index,
          key: "timeItems",
          value: val.map((item) => ({
            ...item,
            start: validTime(item?.start) ? timeToStr(item.start) : null,
            end: validTime(item?.end) ? timeToStr(item.end) : null,
          })),
        });
      },
      deep: true,
    },
    localRecurring(val) {
      this.$emit("update", { index: this.index, key: "recurring", value: val });
    },
    localSelectedTimezone(val) {
      this.$emit("update", {
        index: this.index,
        key: "selectedTimezone",
        value: val,
      });
    },
  },

  mounted() {
    this.initLoad();
    this.interval = setInterval(() => {
      this.now = Date.now();
    }, 30000);
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  computed: {
    currentTime() {
      this.now;
      return moment.tz(this.localSelectedTimezone.zone).format('ddd DD MMM YYYY hh:mm A');
    }
  },
  methods: {
    initLoad() {
      this.localDate = this.eventData.date;
      this.timeItems = this.eventData.timeItems.map((item) => ({
        ...item,
        start: !item.start ? null : moment(item.start).tz(this.eventData.selectedTimezone.zone).format('hh:mm A'),
        end: !item.end ? null : moment(item.end).tz(this.eventData.selectedTimezone.zone).format('hh:mm A'),
      }));
      this.localRecurring = this.eventData.recurring;
      this.localSelectedTimezone = this.eventData.selectedTimezone;
    },
    allowedDates(date) {
      const today = new Date(moment.tz(this.localSelectedTimezone.zone).format('YYYY-MM-DD'));
      return new Date(date) >= today;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    handleAddTime() {
      this.timeItems.push({ start: null, end: null, event_id: null });
    },
    removeTime(index) {
      this.timeItems.splice(index, 1);
    }
  },
};
</script>
<style scoped>
.input {
  border-radius: 0px;
  font-size: 12px;
}
.middle-input {
  border-radius: 0px;
  min-width: 250px;
  max-width: 300px;
}
.date-input {
  border-radius: 0px;
  font-size: 12px;
  min-width: 100px;
  max-width: 150px;
  margin-left: 10px !important;
}
.timezone-input {
  border-radius: 0px;
  max-width: 200px;
  font-size: 12px;
}
.event-box {
  position: relative;
  margin-top: 10px;
  padding: 5px 20px 0px;
  margin-bottom: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
.remove-btn {
  position: absolute !important;
  top: 0;
}
</style>
  