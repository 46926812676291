import Vue from 'vue'
import Vuex from 'vuex'
import jwtDecode from 'jwt-decode'
import App from './App.vue'
import Store from './store'
import vuetify from './plugins/vuetify'
import router from './router'
import { setupComponents } from './config/setup-components';
import moment from 'moment-timezone'
import vueDebounce from 'vue-debounce'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { faLock, faLockOpen, faUnlock, faCalendarTimes } from '@fortawesome/free-solid-svg-icons'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import VueI18n from 'vue-i18n'
import messages from './lang'
import helpers from './utils/helpers'

/* add icons to the library */
library.add(faLock, faLockOpen, faUnlock, faCalendarTimes)
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Vue.config.productionTip = false
Vue.prototype.moment = moment

setupComponents(Vue);
Vue.use(Vuex)
Vue.use(vueDebounce, {
  defaultTime: '700ms',
  listenTo: 'input'
})
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})

const helperPlugin = {
  install () {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  }
}
Vue.use(helperPlugin)

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'he',
  fallbackLocale: 'he',
  messages
})

String.prototype.toHHMMSS = function (friendly = false) {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }

  if (friendly)
    return `${hours}h ${minutes}m ${seconds}s`;

  return hours + ':' + minutes + ':' + seconds;
}

router.beforeEach(async(to, from, next) => {
  const token = localStorage.getItem("token");
  const language = localStorage.getItem("language");
  Store.dispatch("SET_LANGUAGE", language || 'he');
  if (to.name === 'SignUpViaLink') {
    next()
  } else if (to.name != 'Login' && !token) {
    next('/login')
  } else if (to.name != 'Login' && token) {
    try {
      let payload = jwtDecode(token);
      if (Date.now() / 1000 > payload.expiry) {
        next('/login')
        return
      } else {
        if (Store.getters.getChannels.length == 0) {
          const res = await Store.dispatch('FETCH_CHANNEL')
          if (to.path === "/") {
            next(`/channels/${res.data.channels[0].channel_id}/dashboard`)
            return
          } 
        } 
        const user = await Store.dispatch('GET_CURRENT_USER');
        // Check if admin 
        if (to.meta.adminAuth) {
          // const user = JSON.parse(localStorage.getItem("user"));
          if (user && user.is_admin) {
            next()
          } else {
            next('/unknown')
          }
        } else {
          next()
        }
      }
    } catch (ex) {
      next('/login')
      return
    }
  } else {
    next()
  }

})


new Vue({
  router,
  i18n,
  vuetify,
  store: Store,
  render: h => h(App)
}).$mount('#app')
