var timezones = [
    {
       "name": "Jerusalem - ירושלים",
       "zone": "Asia/Jerusalem"
    },
    {
        "name": "New York - ניו יורק",
        "zone": "America/New_York"
    },
    {
        "name": "Los Angeles - לוס אנג׳לס",
        "zone": "America/Los_Angeles"
    },
    {
        "name": "London - לונדון",
        "zone": "Europe/London"
    },
    {
        "name": "Paris - פריז",
        "zone": "Europe/Paris"
    },
];

export default timezones;