import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"d-flex flex-wrap py-4",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.conversation)?_c('div',{staticClass:"d-flex align-center"},[_c(VListItemAvatar,{staticClass:"mt-0",attrs:{"size":"40"}},[_c(VImg,{staticClass:"grey lighten-2 justify-center",attrs:{"src":_vm.conversation.opener.avatar_url}})],1),_c('div',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.conversation.opener.name)+" ")])],1):_vm._e(),_c(VDivider),_c('div',{ref:"chatContainer",staticClass:"chat-container",on:{"scroll":_vm.onScroll}},_vm._l((_vm.reverseMessages),function(item,index){return _c('message-item',{key:index,attrs:{"val":item}})}),1),_c(VDivider,{staticClass:"mt-5"}),_c('div',{staticClass:"mt-4 d-flex justify-end"},[_c(VBtn,{attrs:{"small":"","rounded":"","outlined":""},on:{"click":_vm.onPressReply}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-reply ")]),_vm._v(" תשובה ")],1)],1),(_vm.showInput)?_c(VTextarea,{staticClass:"mt-8",attrs:{"outlined":"","hide-details":"","label":"כתוב הודעה","single-line":""},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}}):_vm._e(),(_vm.showInput)?_c('div',{staticClass:"mt-4 d-flex justify-end"},[_c(VBtn,{staticClass:"white--text px-4",attrs:{"width":"90","rounded":"","color":"red","small":"","loading":this.sending,"disabled":!this.newMessage},on:{"click":_vm.sendMessage}},[_vm._v(" שליחה ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }