<template>
  <v-container fluid>
    <v-row class="pa-1">
      <v-col lg="7" md="8" sm="8" xs="12">
        <span class="text-subtitle-2 font-weight-bold red--text">{{$t("uploading a story")}}:</span
        >
        <div class="d-flex py-5">
          <div class="main-row">
            <v-btn
              color="#616161"
              class="white--text"
              tile
              width="140"
              @click="$refs.file.click()"
              :disabled="this.loading">
              {{$t("select a file")}}  
            </v-btn
            >
            <input
              type="file"
              ref="file"
              accept="video/*, image/*"
              @change="selectFile"
              style="display: none"
            />
            <span class="caption px-5">{{ this.fileName || this.media }}</span>
            <v-btn
              v-if="this.selectedFile || this.media"
              icon
              color="pink"
              :disabled="this.loading"
              @click="clearFile()"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
          <div class="mark-img">
            <v-icon v-if="this.selectedFile || this.media" size="30" color="black"
              >mdi-check-bold</v-icon
            >
          </div>
        </div>


        <v-form class="main-row" ref="form" v-model="valid" lazy-validation>
          <span class="text-subtitle-2 font-weight-bold">{{$t("title")}}</span>

          <div class="d-flex py-2">
            <v-text-field
              dense
              class="input"
              v-model="title"
              :label="$t('text')"
              single-line
              outlined
            />
            <div class="mark-img">
              <v-icon v-if="this.title" size="30" color="black"
                >mdi-check-bold</v-icon
              >
            </div>
          </div>
          
          <span class="text-subtitle-2 font-weight-bold">{{$t("עמדת כותרת")}} </span>
          <div class="d-flex py-2">
            <v-radio-group v-model="titlePosition" row>
              <v-radio color="black" value="top">
                <template v-slot:label>
                  <span class="text-caption font-weight-bold">{{$t("up")}}</span>
                </template>
              </v-radio>
              <v-radio color="black" value="middle">
                <template v-slot:label>
                  <span class="text-caption font-weight-bold">{{$t("center")}}</span>
                </template>
              </v-radio>
              <v-radio color="black" value="bottom">
                <template v-slot:label>
                  <span class="text-caption font-weight-bold">{{$t("bottom")}}</span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>

          <span class="text-subtitle-2 font-weight-bold">{{$t("background color")}}</span>
          <div class="d-flex py-2">
            <v-color-picker
              v-model="backgroundColor"
              elevation="2"
              hide-canvas
              hide-sliders
              :swatches="colorSwatches"
              show-swatches
              hide-inputs
              class="custom-picker"
            />
          </div>

          <span class="text-subtitle-2 font-weight-bold">{{$t("posting time")}}</span>

          <div class="d-flex py-2">

            <v-radio-group v-model="postSchedule">
              <div class="d-flex flex-column">
                <div class="d-flex align-center">
                  <v-radio
                    :key="1"
                    :value="1"
                    color="black"
                    class="custom-radio"
                  />
                  <span class="text-caption font-weight-bold">{{$t("now")}} </span>
                </div>
                <div class="d-flex mr-8">
                  <span class="text-caption">{{$t("this video will be published in a few minutes")}}</span>
                </div>
              </div>
              <div class="d-flex flex-column mt-5">
                <div class="d-flex align-center">
                  <v-radio
                    :key="2"
                    :value="2"
                    color="black"
                    class="custom-radio"
                  />
                  <span class="text-caption font-weight-bold">{{$t("timing")}} </span>
                </div>
                <div class="d-flex mr-8">
                  <span class="text-caption">{{$t("determine when the video will be published")}}</span>
                </div>
              </div>
            </v-radio-group>

          </div>

          <div v-if="postSchedule === 2" class="d-flex">
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  class="date-input"
                  append-icon="mdi-menu-down"
                  persistent-hint
                  dense
                  outlined
                  single-line
                  v-bind="attrs"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>

            <v-menu
              ref="menu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  class="date-input"
                  append-icon="mdi-menu-down"
                  readonly
                  dense
                  single-line
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="time"
                full-width
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
            <v-select
              v-model="selectedTimezone"
              :items="timezoneLits"
              item-text="name"
              item-value="zone"
              label="בחר"
              dense
              outlined
              single-line
              class="timezone-input"
              return-object
            />
          </div>

          <span class="text-subtitle-2 font-weight-bold"> {{$t("description")}} ({{$t("it will not appear on the story")}})</span>

          <div class="d-flex py-2">
            <v-text-field
              dense
              class="input"
              v-model="description"
              label=""
              single-line
              outlined
            />
            <div class="mark-img">
              <v-icon v-if="this.description" size="30" color="black"
                >mdi-check-bold</v-icon
              >
            </div>
          </div>

          <div class="d-flex py-2">
            <div class="d-flex justify-end main-row">
              <v-btn
                tile
                color="#388E3C"
                width="140"
                class="white--text"
                @click="this.onPressComplete"
                :loading="this.loading"
                :disabled="this.loading || !this.uploadValid"
                >{{$t("routine")}}!
              </v-btn>
            </div>
            <div class="mark-img" />
          </div>
        </v-form>
      </v-col>
      <v-col lg="5" md="4" sm="4" xs="12">
        <story-preview
          :mediaType="mediaType"
          :media="media"
          :titlePosition="titlePosition"
          :title="title"
          :backgroundColor="backgroundColor"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import api from "@/api/api";
import axios from "axios";
import moment from "moment";
import timezones from "../../config/timezone.js";

export default {
  data() {
    return {
      valid: true,
      fileName: "",
      title: null,
      titlePosition: "middle",
      description: null,
      postSchedule: 1,
      time: null,
      date: moment(new Date()).format('YYYY-MM-DD'),
      dateFormatted: this.formatDate(moment(new Date()).format('YYYY-MM-DD')),
      timeMenu: false,
      dateMenu: false,
      selectedFile: undefined,
      media: undefined,
      mediaType: undefined,
      error: false,
      showResult: false,
      result: "",
      uploadingMedia: false,
      loading: false,
      uploaded: false,
      channelId: this.$route.params.channel_id,
      storyId: this.$route.params.story_id,
      backgroundColor: "#b1d5f5",
      colorSwatches: [
        ["#b1d5f5", "#94bfea", "#75abe3", "#5899d9", "#377dc2", "#1966b4"],
        ["#fbc4a6", "#f4aa85", "#ef8d5c", "#ea7437", "#d75b1d", "#cf4100"],
        ["#8dd2bb", "#66c2a3", "#3fb68e", "#18a978", "#0f8c62", "#03734d"],
        ["#f6b6c9", "#f490ad", "#f16a93", "#ed4a7a", "#e42a63", "#da094a"],
        ["#cfbfec", "#be9de2", "#aa7dd8", "#945ecd", "#8647cc", "#762aca"],
        ["#80d4d6", "#5ec2cc", "#3ab6bf", "#12a4b3", "#0c909c", "#097588"],
        ["#9b9ff6", "#828af6", "#6774f6", "#535df2", "#3946e1", "#2531d3"],
        ["#e398d1", "#d877be", "#d15eb3", "#c0399e", "#a82686", "#911670"],
        ["#d1d9dc", "#adbcc3", "#8ca0a8", "#6c8894", "#55747f", "#3f6974"],
        ["#fec7ca", "#f8a7a7", "#f08887", "#ee6868", "#e14c4e", "#d53130"],
        ["#86a2b6", "#6a8caf", "#4d78a2", "#316490", "#255a8e", "#134b7e"],
      ],
      timezoneLits: timezones,
      selectedTimezone: timezones[0],
    };
  },

  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.channelId = to.params.channel_id;
        this.initLoad();
      }
      if (to.params.story_id) {
        this.storyId = to.params.story_id;
        this.initLoad();
      }
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  mounted() {
    this.initLoad();
  },

  computed: {
    uploadValid() {
      if (this.uploadingMedia) return false
      if (this.media) {
        return this.vailidateScheduledDate();
      } else {
        return (
          this.vailidateScheduledDate() && this.backgroundColor && this.title
        );
      }
    },
  },

  methods: {
    initLoad() {
      this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      this.selectedFile = undefined;
      this.title = "";
      this.fileName = "";
      this.description = "";
      if (this.storyId) {
        this.$store.dispatch("SET_TOOLBARLOAD", true);
        api
          .getStory(this.storyId)
          .then((res) => {
            this.$store.dispatch("SET_TOOLBARLOAD", false);
            if (res.data) {
              this.media = res.data.media;
              this.mediaType = res.data.media_type;
              this.title = res.data.title;
              this.description = res.data.description;
              this.titlePosition = res.data.title_position;
              const utcDate = moment(res.data.publish_time).tz(this.selectedTimezone.zone);
              this.date = utcDate.format("YYYY-MM-DD");
              this.time = utcDate.format("HH:mm");
              if ((new Date(res.data.publish_time) - new Date()) / 1000 > 0)
                this.postSchedule = 2;

              this.backgroundColor = res.data.background_color;
            }
          })
          .catch((error) => {
            this.$store.dispatch("SET_TOOLBARLOAD", false);
            console.log(error);
          });
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    vailidateScheduledDate() {
      if (this.postSchedule === 1) return true;
      if (!this.date || !this.time) return false;
      if (new Date() > this.getScheduleDate()) return false;

      return true;
    },
    clearFile() {
      if (this.uploadingMedia) return;
      this.selectedFile = undefined;
      this.fileName = "";
      this.media = undefined;
      this.mediaType = undefined;
      this.uploadingMedia = false;
    },
    async selectFile() {
      this.uploadingMedia = true;
      this.selectedFile = this.$refs.file.files[0];
      this.fileName = this.$refs.file.files[0].name;
      const result =  await api.getVideoStorageSignedUrl(this.channelId, this.selectedFile.type);
      if (result && result.data.url) {
        axios
          .put(result.data.url, this.selectedFile, {
            headers: { "Content-Type": this.selectedFile.type },
          })
          .then(() => {
            this.media = result.data.url.split("?")[0];
            this.mediaType = this.getFileType();
            this.uploadingMedia = false;
          })
          .catch((err) => {
            console.log(err);
            this.uploadingMedia = false;
          });
      }
    },
    getScheduleDate() {
      const strISO8061 =  moment.tz(`${this.date} ${this.time}`, this.selectedTimezone.zone).toISOString();
      return new Date(strISO8061);
    },
    onPressComplete() {
      this.loading = true;
      const params = {
        story: {
          title: this.title,
          description: this.description,
          channel_id: this.channelId,
          title_position: this.titlePosition,
          background_color: this.backgroundColor,
          media_type: this.mediaType,
          media: this.media,
        },
      };
      if (this.postSchedule === 2) {
        params.story["publish_time"] = this.getScheduleDate().toISOString();
      }
      if (this.storyId) {
        api
        .editStory(this.storyId, params)
        .then(() => {
          this.loading = false;
          this.uploaded = true;
          this.$router.push({ path: `/channels/${this.channelId}/story` });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      } else {
        api
        .postStory(params)
        .then(() => {
          this.loading = false;
          this.uploaded = true;
          this.$router.push({ path: `/channels/${this.channelId}/story` });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      }
      
    },
    getFileType() {
      if (!this.selectedFile) return undefined;
      if (this.selectedFile.type.match("image.*")) return "image";

      if (this.selectedFile.type.match("video.*")) return "video";

      return undefined;
    },
  },
};
</script>

<style scoped>
.main-row {
  flex: 1;
}
.input {
  border-radius: 0px;
  font-size: 12px;
}
.date-input {
  border-radius: 0px;
  font-size: 12px;
  min-width: 100px;
  max-width: 150px;
  margin-left: 20px !important;
}
.mark-img {
  width: 70px;
  justify-content: center;
  display: flex;
  align-items: flex-start;
}
.custom-radio {
  margin-bottom: 0px !important;
}
.custom-picker {
  max-width: 350px !important;
}
.timezone-input {
  border-radius: 0px;
  max-width: 200px;
  font-size: 12px;
}
</style>
