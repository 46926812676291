<template>
  <div>
    <template v-if="!$route.meta.allowAnonymous">
      <v-app>
        <div class="app-container">
          <top-toolbar />
          <top-navigation />
          <v-main>
            <v-container fluid>
              <router-view></router-view>
            </v-container>
          </v-main>
        </div>
      </v-app>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
    <add-play-list-dlg v-if="$store.getters.getShowPlaylistDialog" />
  </div>
  
</template>

<script>
import AddPlayListDlg from './pages/playlist/AddPlayListDlg.vue';

export default {
  name: 'App',
  methods: {
    changeRTL (isRTL) {
      this.$vuetify.rtl = isRTL
    }
  },
  computed: {
    lang () {
      return this.$store.getters.getLanguage
    }
  },
  watch: {
    lang (val) {
      this.changeRTL(val === 'he')
    }
  },
  mounted() {
    this.changeRTL(this.$store.getters.getLanguage == 'he')
  },
  components: {
    AddPlayListDlg
  }
   
};
</script>
