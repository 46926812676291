<template>
  <v-app id="signup">
    <v-main>
      <v-container fluid fill-height v-if="!!userInfo">
        <v-row class="justify-center align-center">
          <v-col xs="12" sm="8" md="4" lg="4" xl="4">
            <v-card class="pa-3" outlined tile elevation="2">
              <v-card-text>
                <div>
                  <v-img
                    src="@/assets/logo.png"
                    aspect-ratio="1"
                    height="100"
                    contain
                  />
                </div>
                <v-form>
                  <h3>שלום וברכה,</h3>
                  <br />
                  <p>
                    קיבלת הזמנה להרשאת מנהל בערוץ: <span class="font-weight-bold">{{ userInfo.channel.name }}</span>
                  </p>
                  <p>
                    (You have received an invitation for administrator permission on the channel: <span class="font-weight-bold">{{ userInfo.channel.name }}</span>)
                  </p>
                  <br />
                  <p>
                    נא לרשום חשבון כדי להמשיך!
                  </p>
                  <p>
                    (Please register an account to continue!)
                  </p>
                  <br />
                  <v-text-field
                    append-icon="email"
                    label="Email"
                    type="email"
                    disabled
                    single-line
                    :value="userInfo.email"
                  />
                  <v-text-field
                    append-icon="person"
                    label="Name"
                    single-line
                    v-model="name"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'visibility_off' : 'visibility'
                    "
                    autocomplete="new-password"
                    name="password"
                    label="Password"
                    id="password"
                    single-line
                    v-model="password"
                    @click:append="hidePassword = !hidePassword"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'visibility_off' : 'visibility'
                    "
                    name="repeat-password"
                    label="Repeat Password"
                    id="repeat-password"
                    single-line
                    v-model="repeatPassword"
                    @click:append="hidePassword = !hidePassword"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions class="d-flex flex-column pb-5">
                <v-btn
                  block
                  x-large
                  color="#EE220C"
                  class="white--text font-weight-bold"
                  @click="signup(true)"
                  :loading="loading"
                >
                  Sign Up
                </v-btn>
                <!--
                <br />
                <v-btn
                  block
                  x-large
                  color="#666"
                  class="white--text font-weight-bold"
                  @click="signup(false)"
                  :loading="loading"
                >
                  Reject
                </v-btn>
                -->
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="showResult" :timeout="2000" top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import api from "@/api/api";

export default {
  data() {
    return {
      password: "",
      repeatPassword: "",
      name: "",
      hidePassword: true,
      loading: false,
      showResult: false,
      result: '',
      userInfo: null,
      shortcode: '',
    };
  },

  mounted() {
    this.$store.dispatch("LOGOUT");
    const { shortcode } = this.$route.params;
    if (!shortcode) return;
    this.shortcode = shortcode;
    api
      .getInvitationByShortcode(shortcode)
      .then((res) => {
        if (res.data) {
          this.userInfo = res.data;
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err)
        this.userInfo = null;
        this.showResult = true;
        this.result = 'Invitation expired!'
      })
  },

  methods: {
    signup(accept) {
      if (this.repeatPassword !== this.password) {
        this.showResult = true;
        this.result = 'Passwords do not match!';
        return;
      }
      this.loading = true;
      api
        .acceptOrRejectInvitation({
          email: this.userInfo.email,
          name: this.name,
          password: this.password,
          shortcode: this.shortcode,
          accept
        })
        .then((res) => {
          if (res.data.status === 'success') {
            this.showResult = true;
            if (accept) {
              this.result = 'Thanks for your acceptance! We are sending you a confirmation via email.'
            } else {
              this.result = 'Thanks for your feedback!'
            }
            setTimeout(() => {
              this.$router.push({ path: '/login' });
            }, 3000);
          } else {
            this.showResult = true;
            this.result = res.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => this.loading = false)
    },
  },
};
</script>

<style>
#signup {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.test {
  border: 1px solid red;
}
.btn {
  min-width: 70px;
  min-height: 70px;
  padding: 0;
}
</style>
