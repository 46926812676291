<template>
  <div class="d-flex pa-2 video-container" no-gutters>
    <div class="justify-right d-flex pl-2">
      <div
        v-if="this.init"
        class="video-image d-flex justify-space-between align-end"
        :style="`background-image: url(${video.thumb_url})`"
      >
        <v-chip color="#00000055" class="mb-3 mr-1 white--text" small label>
        {{this.$helpers.lengthInMinutes(video.duration)}}
        </v-chip>
        <v-btn
            icon
            color="gray"
            class="mb-2 ml-1"
            @click="onPlayVideo()"
        >
          <v-icon size="20"  color="white">mdi-play</v-icon>
        </v-btn>
      </div>
      <video
        ref="video"
        class="video-image"
        v-bind:src="video.video_url"
        preload="none"
        controls
        :hidden="this.init"
        @click="onPlayVideo()"
      />
    </div>
    <v-flex class="d-flex flex-column">
      <span class="text-caption font-weight-bold">{{
        video.channel.name
      }}</span>
      <span class="text-caption video-title">{{ video.title }}</span>
      <span class="text-caption">צפיות: {{ video.views }}</span>
      <span v-if="video.publish_time" class="text-caption">תאריך {{ moment(video.publish_time).format("DD/MM/YYYY") }}</span>
      <div class="d-flex flex-row align-center justify-space-between">
        <v-menu
          v-model="showLockMenu"
          :close-on-content-click="false"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
            icon
            color="black"
            v-bind="attrs"
            v-on="on">
              <font-awesome-layers full-width class="fa-2x">
                <font-awesome-icon
                  :icon="video.video_position.is_locked ? 'lock' : 'lock-open'"
                  :style="{ color: video.video_position.is_locked ? 'red' : 'black' }"
                />
                <font-awesome-layers-text
                  :style="{ color: 'white' }"
                  :transform="
                    video.video_position.is_locked
                      ? 'down-3 shrink-10'
                      : 'left-3 down-3 shrink-10'
                  "
                  :value="video.video_position.position"
                />
              </font-awesome-layers>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item >
                <v-list-item-title class="text-subtitle-2">נעל מיקום</v-list-item-title>
                <v-list-item-action>
                  <v-text-field
                      v-model="newPosition"
                      dense
                      class="input"
                      hide-details
                      no-gutters
                      single-line
                      outlined
                      @keypress="filterNumber"
                    />
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-btn
                text
                :disabled="!this.newPosition || this.newPosition < 0 || this.newPosition > 25"
                @click="onLockPosition()"
              >
                נעל
              </v-btn>
              <v-btn
                color="primary"
                text
                :disabled="!video.video_position.is_locked"
                @click="onUnlockPosition()"
              >
                שחרר נעילה
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        
        <v-menu top offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="black" v-bind="attrs" v-on="on">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list min-width="200" flat>
            <v-list-item-group class="mx-2">
              <v-list-item dense class="underline px-0" @click="$emit('addVideoToSection', video)">
                <v-list-item-title>
                  <span class="text-subtitle-2"> הוסף לסקשן</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="video.video_position.is_locked"
                dense
                class="underline px-0"
                @click="$emit('removePosition', video)"
              >
                <v-list-item-title>
                  <span class="text-subtitle-2"> שחרר</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!video.video_position.is_locked"
                dense
                class="underline px-0"
                @click.stop="showDeleteDlg = true"
              >
                <v-list-item-title>
                  <span class="text-subtitle-2 red--text"> הסר</span>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item class="justify-end px-2">
              <v-btn text>סגור</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog v-model="showDeleteDlg" max-width="290">
            <v-card>
                <v-card-title class="text-subtitle-2" > האם אתה בטוח שברצונך להסיר וידאו זה מהפיד? </v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="showDeleteDlg = false">
                    חזור
                </v-btn>

                <v-btn color="green darken-1" text @click="onRemoveFeed()">
                    בסדר
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 
      </div>
    </v-flex>
  </div>
</template>

<script>
export default {
  props: ["video","index"],
  data() {
    return {
        init: true,
        playing: false,
        newPosition: this.video.video_position.position,
        showDeleteDlg: false,
        showLockMenu: false,
    };
  },
  computed: {
    videoElement() {
        return this.$refs.video;
    },
    activeVideoId() {
        return this.$store.getters.getActiveVideoId;
    },
  },
  watch: {
    activeVideoId: {
        handler: function(activeVideoId) {
            if (this.video.id !== activeVideoId) {
                this.playing = false;
                this.videoElement.pause()
            }
        }
    },
    'video.video_position.position'(val) {
      this.newPosition = val;
    }
  },
  methods: {
    onPlayVideo() {
        this.init = false
        this.playing = !this.playing
        this.playing ? this.videoElement.play() : this.videoElement.pause()
        this.$store.dispatch("SET_ACTIVE_VIDEO_ID", this.video.id);
    },
    onRemoveFeed() {
        this.showDeleteDlg = false;
        this.$emit('removeFeed', this.video)
    },
    onLockPosition() {
      this.$emit('addPosition', this.video, this.newPosition);
      this.showLockMenu = false;
    },
    onUnlockPosition() {
      this.$emit('removePosition', this.video);
      this.showLockMenu = false;
    },
     filterNumber(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style>
.input {
  border-radius: 0px;
}
</style>
