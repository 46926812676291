<template>
    <v-container fluid>
        <v-alert
        v-model="alert"
        text
        dense
        type="success"
        max-width="300"
        dismissible
        >
        {{$t('sent')}}!
        </v-alert>
        <v-row class="pa-1">
            <v-col lg="7" md="10" sm="12" xs="12">
                <v-form class="main-row" ref="form" v-model="valid" lazy-validation>
                    <span class="text-subtitle-2 font-weight-bold">*{{$t('title')}}</span>
                    <div class="d-flex py-2">
                        <v-text-field
                        dense
                        class="input"
                        v-model="title"
                        single-line
                        outlined
                        />

                        <div class="mx-5 mark-img">
                        <v-icon v-if="this.title" size="30" color="black"
                            >mdi-check-bold</v-icon
                        >
                        </div>
                    </div>

                    <span class="text-subtitle-2 font-weight-bold">{{$t('content')}}</span>
                    <div class="d-flex py-2">
                        <v-textarea
                            class="input"
                            outlined
                            v-model="body"
                            rows="2"
                        />

                        <div class="mx-5 mark-img">
                        <v-icon v-if="this.body" size="30" color="black"
                            >mdi-check-bold</v-icon
                        >
                        </div>
                    </div>

                    <span class="text-subtitle-2 font-weight-bold">{{$t('channel')}}</span>
                    <div class="d-flex py-2">
                        <v-autocomplete
                            class="input"
                            v-model="selectedChannel"
                            :items="channelEntries"
                            :loading="isChannelLoading"
                            :search-input.sync="channelSearch"
                            color=""
                            clearable
                            hide-no-data
                            hide-selected
                            item-text="name"
                            item-value="id"
                            dense
                            :label="$t('search by name')"
                            outlined
                            :placeholder="$t('type to search')"
                            return-object
                        ></v-autocomplete>

                        <div class="mx-5 mark-img">
                        <v-icon v-if="this.selectedChannel" size="30" color="black"
                            >mdi-check-bold</v-icon
                        >
                        </div>
                    </div>
                    <div class="d-flex py-2">
                        <div class="d-flex justify-end main-row ">
                        <v-btn
                            tile
                            color="#388E3C"
                            width="140"
                            class="white--text"
                            @click="this.onPressComplete"
                            :loading="this.loading"
                            :disabled="this.loading || !this.title"
                        >
                            {{$t('bringup')}}
                        </v-btn>
                        </div>
                        <div class="mx-5 mark-img" />
                    </div>
                </v-form>
            </v-col>
        </v-row>
        
    </v-container>
</template>

<script>
import api from "@/api/api";

export default {
    data() {
        return {
            loading: false,
            valid: true,
            title: '',
            body: '',
            alert: false,
            isChannelLoading: false,
            selectedChannel: null,
            channelSearch: null,
            channelEntries: [],
            debounce: null,
        }
    },
    mounted() {
    },
    watch: {
        channelSearch(val) {
            if (val === this.selectedChannel?.name) {
                return;
            }
            if (this.debounce) {
                clearTimeout(this.debounce);
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.queryChannels(val);
            }, 1000);

            
        }
    },
    methods: {
        queryChannels(strChannel) {
            if (this.isChannelLoading) return;
      
            this.isChannelLoading = true;
            api.searchChannels(strChannel)
                .then((res) => {
                    this.isChannelLoading = false
                    this.channelEntries = res.data.items;
                })
                .catch((error) => {
                    this.isChannelLoading = false
                    console.log(error);
                });
        },
        async onPressComplete() {
            this.loading = true;
            const body = {
                notification: {
                    title: this.title,
                    body: this.body,
                    video_id: this.selectedChannel?.id
                }
            };
            api.createNotification(body)
                .then(() => {
                    this.loading = false;
                    this.alert = true;
                    setTimeout(()=>{
                        this.alert=false
                    }, 2000)
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                })
        }
    }
}

</script>

<style>

</style>
