
<template>
  <v-container fluid>
    <v-row class="px-10">
      <v-col class="align-center" lg="12" md="12">
        <h5>Total Users: {{ totalUserCount.toLocaleString() }}</h5>
        <apexchart
          v-if="totalUserCount > 0"
          height="100px"
          type="bar"
          :options="totalUserOptions"
          :series="seriesTotalUsers"
          class="chart"
        ></apexchart>
        <div class="mb-8" v-else />
        <h5>Montly Active Users: {{ montlyActiveUsers.toLocaleString() }}</h5>
        <apexchart
          v-if="montlyActiveUsers > 0"
          height="100px"
          type="bar"
          :options="totalUserOptions"
          :series="seriesTotalActiveUsers"
          class="chart"
        ></apexchart>
        <div class="mb-8" v-else />
        <h5 class="mb-8">Average Follows Per User: {{ avgFollowsPerUser.toLocaleString() }}</h5>
        <apexchart
          height="300px"
          type="line"
          :options="userOptions"
          :series="seriesUser"
          class="chart mb-15 mt-15"
        ></apexchart>
        <apexchart
          height="300px"
          type="line"
          :options="monthlyActiveUserOptions"
          :series="seriesMonthlyActiveUsers"
          class="chart mb-15"
        ></apexchart>
        <apexchart
          height="300px"
          type="bar"
          :options="followDataOptions"
          :series="seriesFollowData"
          class="chart mb-15"
        ></apexchart>
        <apexchart
          height="300px"
          type="line"
          :options="viewsOptions"
          :series="seriesViews"
          class="chart  mb-15"
        ></apexchart>
        <apexchart
          height="300px"
          type="line"
          :options="durationOptions"
          :series="seriesDuration"
          class="chart"
        ></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/api/api";
import VueApexCharts from "vue-apexcharts";
import moment from 'moment-timezone';

export default {
  data() {
    return {
      seriesUser: [],
      seriesViews: [
        {
          name: "a",
          data: [],
        },
      ],
      seriesDuration: [],
      userOptions: {
        title: {
          text: `${this.$t('new users')}`,
          align: "center",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "MMM dd",
          },
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: (value) => {
              return value.toLocaleString();
            },
          },
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return val.toLocaleString()
            }
          }
        },
      },
      viewsOptions: {
        title: {
          text: `${this.$t('views')}`,
          align: "center",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "MMM dd",
          },
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: (value) => {
              return value.toLocaleString();
            },
          },
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return val.toLocaleString()
            }
          }
        },
      },
      durationOptions: {
        title: {
          text: `${this.$t('viewing length')}`,
          align: "center",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "MMM dd",
          },
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: (value) => {
              return `${this.$t('subtlety')} ${value.toLocaleString()}`;
            },
          },
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return val.toLocaleString()
            }
          }
        },
      },
      seriesTotalUsers: [
        {
          name: 'Registered Users',
          data: [0]
        },
        {
          name: 'Anonymous Users',
          data: [0]
        },
      ],
      seriesTotalActiveUsers: [
        {
          name: 'Registered Users',
          data: [0]
        },
        {
          name: 'Anonymous Users',
          data: [0]
        },
      ],
      totalUserOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          offsetX: -24,
          toolbar: {
            show: false,
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        // dataLabels: {
        //   formatter: (val) => {
        //     return val / 1000 + 'K'
        //   }
        // },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 10,
          }
        },
        xaxis: {
          categories: [
            '',
          ],
          labels: {
            show: false,
          }
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: (value) => {
              return value.toLocaleString();
            },
          },
        },
        fill: {
          opacity: 0.8,
        },
        // colors: ['#80c7fd', '#008FFB'],
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: -8,
          offsetY: 16,
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return val.toLocaleString()
            }
          }
        },
      },
      totalUserCount: 0,
      montlyActiveUsers: 0,
      avgFollowsPerUser: 0,
      seriesMonthlyActiveUsers: [],
      monthlyActiveUserOptions: {
        title: {
          text: 'Monthly Active Users',
          align: "center",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "MMM",
          },
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: (value) => {
              return value.toLocaleString();
            },
          },
        },
        tooltip: {
          x: {
            formatter: (val) => {
              return moment(val).format('MMMM')
            }
          },
          y: {
            formatter: (val) => {
              return val.toLocaleString()
            }
          }
        },
      },
      seriesFollowData: [],
      followDataOptions: {
        title: {
          text: 'Follows/Unfollows',
          align: "center",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "MMM dd",
          },
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: (value) => {
              return value.toLocaleString();
            },
          },
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return val.toLocaleString()
            }
          }
        },
      }
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  watch: {},
  mounted() {
    this.getData();
  },
  methods: {
      updateUserData(res) {
      this.seriesUser.push({
        name: this.seriesUser.length ? "Anonymous Users" : "New Users",
        data: res.data.yaxis,
      });
      this.userOptions = {
        xaxis: {
          categories: res.data.xaxis,
        },
      };
    },
    updateViewsData(res) {
      this.seriesViews = [
        {
          name: "Video Views - Web",
          data: res.data.web.yaxis,
        },
        {
          name: "Video Views - Mobile",
          data: res.data.mobile.yaxis,
        },
      ];
      this.viewsOptions = {
        xaxis: {
          categories: res.data.web.xaxis,
        },
      };
    },
    updateDurationData(res) {
      this.seriesDuration = [
        {
          name: "Video Duration - Web",
          data: res.data.web.yaxis,
        },
        {
          name: "Video Duration - Mobile",
          data: res.data.mobile.yaxis,
        },
      ];
      this.durationOptions = {
        xaxis: {
          categories: res.data.web.xaxis,
        },
      };
    },
    updateTotalUsers(res) {
      this.totalUserCount = res.data?.total_users?.total || 0;
      this.montlyActiveUsers = res.data?.monthly_active_users?.total || 0;
      this.avgFollowsPerUser = res.data?.avg_follows_per_user || 0;
      this.seriesTotalUsers = [
        {
          name: 'Registered Users',
          data: [res.data?.total_users?.registered_users || 0]
        },
        {
          name: 'Anonymous Users',
          data: [res.data?.total_users?.anonymous_users || 0]
        },
      ];
      this.seriesTotalActiveUsers = [
        {
          name: 'Registered Users',
          data: [res.data?.monthly_active_users?.registered_users || 0]
        },
        {
          name: 'Anonymous Users',
          data: [res.data?.monthly_active_users?.anonymous_users || 0]
        },
      ];
    },
    updateMonthlyActiveUsers(res) {
      this.seriesMonthlyActiveUsers.push({
        name: "Active Users - Web",
        data: res.data.y1axis,
      });
      this.seriesMonthlyActiveUsers.push({
        name: "Active Users - Mobile",
        data: res.data.y2axis,
      });
      this.monthlyActiveUserOptions = {
        xaxis: {
          categories: res.data.xaxis,
        },
      };
    },
    updateFollowData(res) {
      this.seriesFollowData = [
        {
          name: 'Follows',
          data: res.data.y1axis,
        },
        {
          name: 'Unfollows',
          data: res.data.y2axis,
        }
      ];
      this.followDataOptions = {
        xaxis: {
          categories: res.data.xaxis,
        },
      };
    },
    async getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      let currentTime = this.moment();
      let start_of_the_lastest_30_days = currentTime
          .startOf("day")
          .subtract(30, "days")
          .unix(),
        utc_offset = currentTime.utcOffset() * 60,
        params_new_users = {
          start_of_the_lastest_30_days: start_of_the_lastest_30_days,
          utc_offset: utc_offset,
          is_anonymous: false,
        },
        params_anonymous = {
          start_of_the_lastest_30_days: start_of_the_lastest_30_days,
          utc_offset: utc_offset,
          is_anonymous: true,
        },
        params_video_data = {
          start_of_the_lastest_30_days: start_of_the_lastest_30_days,
          utc_offset: utc_offset,
        },
        params_monthly_active_users = {
          start_of_the_lastest_30_days: start_of_the_lastest_30_days,
          utc_offset: utc_offset,
        };
      
      await api.getTotalUserCount().then(this.updateTotalUsers).catch((err) => console.log(err));

      await api.getUserAnalytics(params_new_users).then(this.updateUserData).catch((err) => console.log(err));
      await api.getUserAnalytics(params_anonymous).then(this.updateUserData).catch((err) => console.log(err));

      await api.getMonthlyActiveUsers(params_monthly_active_users).then(this.updateMonthlyActiveUsers).catch((err) => console.log(err));
      await api.getFollowData({ start_of_the_lastest_30_days: start_of_the_lastest_30_days }).then(this.updateFollowData).catch((err) => console.log(err));

      await api.getVideoViewsAnalytics(params_video_data).then(this.updateViewsData).catch((err) => console.log(err));
      await api.getVideoDurationsAnalytics(params_video_data).then(this.updateDurationData).catch((err) => console.log(err));

      this.$store.dispatch("SET_TOOLBARLOAD", false);
    },
  },
};
</script>