<template>
  <v-container fluid>
    <add-video-view @onPressAdd="navigateAdd" :add-text="`+ ${$t('new video')}`" />
    <v-row class="pa-4 d-flex" v-if="this.videoDetails">
      <div class="ml-5">
        <v-img
          :width="210"
          :aspect-ratio="16 / 9"
          contain
          :src="this.videoDetails.video.thumb_url"
        ></v-img>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <span class="subtitle-2 font-weight-bold">
            {{ this.videoDetails.video.title }}
          </span>
          <v-btn class="mr-3" icon @click="onEditTitle()">
            <v-icon color="#000000" size="20"> mdi-square-edit-outline </v-icon>
          </v-btn>
        </div>
        <div class="mt-3">
          <span class="text-caption"
            >{{ this.videoDetails.video.views }} צפיות &nbsp;•
          </span>
          <span class="text-caption mr-1">
            {{ this.videoDetails.comments }} תגובות</span
          >
        </div>
        <span
          v-if="
            this.videoDetails.video.status === 'unpublished' &&
              this.videoDetails.video.scheduled_at
          "
          class="text-caption"
          >יפורסם ב
          {{
            moment(this.videoDetails.video.scheduled_at).format(
              "DD/MM/YYYY HH:mm"
            )
          }}</span
        >
        <span
          v-if="
            this.videoDetails.video.status === 'published' &&
              this.videoDetails.video.publish_time
          "
          class="text-caption"
          >תאריך
          {{
            moment(this.videoDetails.video.publish_time).format("DD/MM/YYYY")
          }}</span
        >
      </div>
    </v-row>
    <v-row class="pa-1 justify-space-between">
      <v-col
        xl="5"
        lg="5"
        md="12"
        sm="12"
        xs="12"
        cols="12"
        v-if="this.videoDetails"
      >
        <video
          class="video-preview"
          v-bind:src="this.videoDetails.video.video_url"
          controls
          preload="none"
        />
      </v-col>

      <v-col xl="5" lg="4" md="12" sm="12" xs="12" cols="12">
        <div class="mb-5">
          <span>תגובות:</span>
        </div>
        <div class="d-flex py-2" v-for="comment in comments" :key="comment.id">
          <span class="font-weight-bold">{{ comment.user.name }} :</span>
          <span class="mr-2">{{ comment.text }}</span>
        </div>
      </v-col>
    </v-row>

    <v-btn
      color="#000000"
      class="white--text mt-8 mr-1"
      tile
      width="140"
      @click.stop="showDeleteDlg = true"
      >מחק וידאו</v-btn
    >

    <v-dialog v-if="this.videoDetails" v-model="showDeleteDlg" max-width="480">
      <v-card class="pa-10">
        <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
          האם הינך בטוח שאתה רוצה להסיר את הוידאו?
        </v-card-title>
        <div class="d-flex">
          <div>
            <v-img
              :width="100"
              :aspect-ratio="16 / 9"
              contain
              :src="this.videoDetails.video.thumb_url"
            />
          </div>
          <span class="mr-5 text-subtitle-2">
            {{ this.videoDetails.video.title }}
          </span>
        </div>

        <v-card-actions class="d-flex  justify-space-between px-0 mt-10">
          <v-btn
            class="white--text flex-grow-1"
            color="#616161"
            tile
            x-large
            @click="showDeleteDlg = false"
          >
            <span class="text-h5">חזור</span>
          </v-btn>
          <v-btn
            class="white--text flex-grow-1"
            color="#ff0000"
            tile
            x-large
            @click="onDeleteVideo()"
            :loading="loading"
            :disabled="loading"
          >
            <span class="text-h5">מחק</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  mounted() {
    this.initLoad();
  },
  data() {
    return {
      videoDetails: null,
      comments: [],
      channelId: this.$route.params.channel_id,
      videoId: this.$route.params.video_id,
      showDeleteDlg: false,
      loading: false,
    };
  },
  watch: {
    $route(to) {
      this.$router.push({ path: `/channels/${to.params.channel_id}` });
    },
  },
  methods: {
    navigateAdd() {
      this.$router.push({ path: `/channels/${this.channelId}/video/add` });
    },
    initLoad() {
      this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      this.videoDetails = null;
      this.comments = [];
      this.getData();
    },
    async getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      await api
        .getVideo(this.videoId)
        .then((res) => {
          this.videoDetails = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      api
        .getVideoComments(this.videoId)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          this.comments = res.data.comments;
        })
        .catch((err) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(err);
        });
    },
    onEditTitle() {
      return this.$router.push({
        path: `/channels/${this.channelId}/videos/${this.videoId}/edit`,
      });
    },
    onDeleteVideo() {
      this.loading = true;
      const params = {
        hidden: true,
      };
      this.loading = true;
      api
        .editVideo(this.videoId, params)
        .then(() => {
          this.loading = false;
          this.showDeleteDlg = false;
          this.$router.push({ path: `/channels/${this.channelId}/` });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.showDeleteDlg = false;
        });
    },
  },
};
</script>

<style>
.video-preview {
  width: 100%;
  max-height: 400px;
  background: black;
}
.test {
  border: 1px solid red;
}
</style>
