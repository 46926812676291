<template>
  <div class="d-flex">
    <div class="d-flex align-center justify-center ml-4 btn-day">
      <span>א</span>
    </div>

    <v-menu
      ref="menu"
      v-model="toTimeMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="toTime"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="toTime"
          class="date-input"
          append-icon="mdi-menu-down"
          readonly
          dense
          single-line
          outlined
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="toTimeMenu"
        v-model="toTime"
        full-width
        @click:minute="$refs.menu.save(toTime)"
      ></v-time-picker>
    </v-menu>

    <v-menu
      ref="menu"
      v-model="fromTimeMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="fromTime"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="fromTime"
          class="date-input"
          append-icon="mdi-menu-down"
          readonly
          dense
          single-line
          outlined
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="fromTimeMenu"
        v-model="fromTime"
        full-width
        @click:minute="$refs.menu.save(fromTime)"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    strDay: { String, default: "AA" },
  },
  data() {
    return {
      fromTime: null,
      fromTimeMenu: false,
      toTime: null,
      toTimeMenu: false,
    };
  },
};
</script>

<style>
.btn-day {
  width: 40px;
  height: 40px;
  border: 1px solid black;
}
.date-input {
  border-radius: 0px;
  font-size: 12px;
  min-width: 100px;
  max-width: 150px;
  margin-left: 20px !important;
}
.dot {
    width: 10px;
    height: 5px;
    background-color: black;
}
</style>
