import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"login"}},[_c(VMain,[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,{staticClass:"justify-center align-center"},[_c(VCol,{attrs:{"xs":"12","sm":"8","md":"4","lg":"4","xl":"4"}},[_c(VCard,{staticClass:"pa-3",attrs:{"outlined":"","tile":"","elevation":"2"}},[_c(VCardText,[_c('div',[_c(VImg,{attrs:{"src":require("@/assets/logo.png"),"aspect-ratio":"1","height":"100","contain":""}})],1),_c(VForm,[_c(VTextField,{attrs:{"append-icon":"person","name":"login","label":"Login","type":"text","single-line":"","error":_vm.error,"rules":[_vm.rules.required]},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c(VTextField,{attrs:{"type":_vm.hidePassword ? 'password' : 'text',"append-icon":_vm.hidePassword ? 'visibility_off' : 'visibility',"name":"password","label":"Password","id":"password","single-line":"","rules":[_vm.rules.required],"error":_vm.error},on:{"click:append":function($event){_vm.hidePassword = !_vm.hidePassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VCardActions,{staticClass:"d-flex flex-column"},[_c(VBtn,{staticClass:"white--text font-weight-bold",attrs:{"block":"","x-large":"","color":"#EE220C","loading":_vm.loading},on:{"click":_vm.login}},[_vm._v("התחבר")]),_c('span',{staticClass:"text-subtitle-1 font-weight-bold py-5"},[_vm._v("כניסה מהירה ")]),_c(VBtn,{staticClass:"btn ma-0",attrs:{"icon":""},on:{"click":_vm.onClickGoogleLogin}},[_c(VImg,{attrs:{"src":require("@/assets/social_google.png"),"aspect-ratio":"1","contain":"","width":"40","height":"40"}})],1)],1)],1)],1)],1)],1),_c(VSnackbar,{attrs:{"timeout":2000,"top":""},model:{value:(_vm.showResult),callback:function ($$v) {_vm.showResult=$$v},expression:"showResult"}},[_vm._v(" "+_vm._s(_vm.result)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }