<template>
    <div v-if="state" class="d-flex flex-column align-center px-16 py-10">
        <span class="text-h3 font-weight-bold">{{state.value}}</span>
        <span class="text-subtitle-1 font-weight-bold">{{state.name}}</span>
    </div>
</template>

<script>
export default {
    props: {
         state: {},
     }
}
</script>

<style>
   
</style>