import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex pa-2 video-container",attrs:{"no-gutters":""}},[_c('div',{staticClass:"justify-right d-flex pl-2"},[(this.init)?_c('div',{staticClass:"video-image d-flex justify-space-between align-end",style:(("background-image: url(" + (_vm.video.thumb_url) + ")"))},[_c(VChip,{staticClass:"mb-3 mr-1 white--text",attrs:{"color":"#00000055","small":"","label":""}},[_vm._v(" "+_vm._s(this.$helpers.lengthInMinutes(_vm.video.duration))+" ")]),_c(VBtn,{staticClass:"mb-2 ml-1",attrs:{"icon":"","color":"gray"},on:{"click":function($event){return _vm.onPlayVideo()}}},[_c(VIcon,{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-play")])],1)],1):_vm._e(),_c('video',{ref:"video",staticClass:"video-image",attrs:{"src":_vm.video.video_url,"preload":"none","controls":"","hidden":this.init},on:{"click":function($event){return _vm.onPlayVideo()}}})]),_c(VFlex,{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(_vm._s(_vm.video.channel.name))]),_c('span',{staticClass:"text-caption video-title"},[_vm._v(_vm._s(_vm.video.title))]),_c('span',{staticClass:"text-caption"},[_vm._v("צפיות: "+_vm._s(_vm.video.views))]),(_vm.video.publish_time)?_c('span',{staticClass:"text-caption"},[_vm._v("תאריך "+_vm._s(_vm.moment(_vm.video.publish_time).format("DD/MM/YYYY")))]):_vm._e(),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c(VMenu,{attrs:{"close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black"}},'v-btn',attrs,false),on),[_c('font-awesome-layers',{staticClass:"fa-2x",attrs:{"full-width":""}},[_c('font-awesome-icon',{style:({ color: _vm.video.video_position.is_locked ? 'red' : 'black' }),attrs:{"icon":_vm.video.video_position.is_locked ? 'lock' : 'lock-open'}}),_c('font-awesome-layers-text',{style:({ color: 'white' }),attrs:{"transform":_vm.video.video_position.is_locked
                    ? 'down-3 shrink-10'
                    : 'left-3 down-3 shrink-10',"value":_vm.video.video_position.position}})],1)],1)]}}]),model:{value:(_vm.showLockMenu),callback:function ($$v) {_vm.showLockMenu=$$v},expression:"showLockMenu"}},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemTitle,{staticClass:"text-subtitle-2"},[_vm._v("נעל מיקום")]),_c(VListItemAction,[_c(VTextField,{staticClass:"input",attrs:{"dense":"","hide-details":"","no-gutters":"","single-line":"","outlined":""},on:{"keypress":_vm.filterNumber},model:{value:(_vm.newPosition),callback:function ($$v) {_vm.newPosition=$$v},expression:"newPosition"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","disabled":!this.newPosition || this.newPosition < 0 || this.newPosition > 25},on:{"click":function($event){return _vm.onLockPosition()}}},[_vm._v(" נעל ")]),_c(VBtn,{attrs:{"color":"primary","text":"","disabled":!_vm.video.video_position.is_locked},on:{"click":function($event){return _vm.onUnlockPosition()}}},[_vm._v(" שחרר נעילה ")])],1)],1)],1),_c(VMenu,{attrs:{"top":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"min-width":"200","flat":""}},[_c(VListItemGroup,{staticClass:"mx-2"},[_c(VListItem,{staticClass:"underline px-0",attrs:{"dense":""},on:{"click":function($event){return _vm.$emit('addVideoToSection', _vm.video)}}},[_c(VListItemTitle,[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" הוסף לסקשן")])])],1),(_vm.video.video_position.is_locked)?_c(VListItem,{staticClass:"underline px-0",attrs:{"dense":""},on:{"click":function($event){return _vm.$emit('removePosition', _vm.video)}}},[_c(VListItemTitle,[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" שחרר")])])],1):_vm._e(),(!_vm.video.video_position.is_locked)?_c(VListItem,{staticClass:"underline px-0",attrs:{"dense":""},on:{"click":function($event){$event.stopPropagation();_vm.showDeleteDlg = true}}},[_c(VListItemTitle,[_c('span',{staticClass:"text-subtitle-2 red--text"},[_vm._v(" הסר")])])],1):_vm._e()],1),_c(VListItem,{staticClass:"justify-end px-2"},[_c(VBtn,{attrs:{"text":""}},[_vm._v("סגור")])],1)],1)],1),_c(VDialog,{attrs:{"max-width":"290"},model:{value:(_vm.showDeleteDlg),callback:function ($$v) {_vm.showDeleteDlg=$$v},expression:"showDeleteDlg"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-subtitle-2"},[_vm._v(" האם אתה בטוח שברצונך להסיר וידאו זה מהפיד? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showDeleteDlg = false}}},[_vm._v(" חזור ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.onRemoveFeed()}}},[_vm._v(" בסדר ")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }