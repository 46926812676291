
<template>
  <v-container fluid>
    <v-row class="d-flex justify-end p-0 pt-2">
      <v-col cols="12" sm="6" md="3" class="d-flex p-0">
        <v-select
          v-model="selectedFilter"
          :items="filterOptions"
          outlined
          dense
          label="Filter Reports"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="px-5 flex-column p-0">
      <span v-if="rpts && !rpts.length" class="text-subtitle-2">
      {{$t('there are no reports')}}</span>
      <v-col cols="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <td :class="headerText">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('video name')}}</span>
                </td>
                <td :class="headerText">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('channel name')}} </span>
                </td>
                <td :class="headerText">
                  <span class="text-subtitle-2 font-weight-bold"> 
                  {{$t('reported by')}}
                  </span>
                </td>
                <td :class="headerText">
                  <span class="text-subtitle-2 font-weight-bold">
                    {{$t('reporting time')}}
                  </span
                  >
                </td>
                <td :class="headerText">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('cause')}}</span>
                </td>
                <td :class="headerText">
                  <span class="text-subtitle-2 font-weight-bold">Resolved</span>
                </td>
                <td :class="headerText">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('actions')}}</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="report in rpts" :key="report.id">
                <td>
                  <div class="d-flex justify-start">
                    {{ report.video.title }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-start">
                    {{ report.channel.name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-start">
                    {{ report.user.name }}
                  </div>
                  <div class="d-flex justify-start">
                    {{ report.user.email }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-start">
                    {{ moment(report.created_at).format("M/d/Y") }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-start">
                    {{ getReason(report.reason) }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <v-simple-checkbox
                      :value="report.ignore"
                      @input="ignore(report, $event)"
                    ></v-simple-checkbox>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right align-center">
                    <a
                      class="black--text font-weight-bold"
                      target="_blank"
                      :href="`https://kolel.org/videos/${report.video.id}`"
                      >{{$t('watch')}}
                    </a>
                    <v-btn class="mr-2" text @click="askRemove(report)">
                      <span class="text-subtitle-2 red--text font-weight-bold">
                        <u>{{$t('remove report')}}</u>
                      </span>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-dialog v-model="showDeleteDlg" max-width="290">
            <v-card>
                <v-card-title class="text-subtitle-2" > {{$t('are you sure remove this video from feed')}} </v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="showDeleteDlg = false">
                    {{$t('cancel')}}
                </v-btn>

                <v-btn color="green darken-1" text @click="onRemoveFeed()">
                    {{$t('ok')}}
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 
      </v-col>
    </v-row>
    <div class="text-center mt-5">
      <v-pagination color="red" v-model="page" total-visible="6" @input="getData"
        :length="Math.ceil(total / limit)"></v-pagination>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import api from "@/api/api";

export default {
  data() {
    return {
      rpts: null,
      moment: moment,
      channelId: this.$route.params.channel_id,
      showDeleteDlg: false,
      selectedReport: null,
      selectedFilter: 'Show all',
      filterOptions: ['Show all', 'Active reports', 'Resolved reports'],
      page: 1,
      limit: 17,
      total: 0,
    };
  },
  computed: {
    headerText() {
      return this.$vuetify.rtl ? "text-right" : "text-left";
    },
  },
  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.channelId = to.params.channel_id;
        this.initLoad();
      }
    },
    selectedFilter() {
      this.page = 1;
      this.getData();
    },
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      if (this.channelId) {
        this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      }
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      let isIgnored = undefined;
      switch (this.selectedFilter) {
        case 'Active reports':
          isIgnored = false;
          break;
        case 'Ignored reports':
          isIgnored = true;
          break;
        default:
          break;
      }
      const params = { channel_id: this.channelId, is_ignore: isIgnored, limit: this.limit, offset: (this.page - 1) * this.limit };
      api
        .getChannelReports(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.reports.length > 0) {
            this.rpts = res.data.reports;
            this.total = res.data.total;
          } else {
            this.rpts = [];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
    ignore(report, value) {
      api.ignoreReport(report, value)        
        .then(() => {
          this.getData();
        });
    },
    askRemove(report) {
      this.showDeleteDlg = true;
      this.selectedReport = report;
      
    },
    onRemoveFeed() {
      if (!this.selectedReport) return;
      this.showDeleteDlg = false;
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api.deleteVideo(this.selectedReport.video.id)
          .then(() => {
              this.$store.dispatch("SET_TOOLBARLOAD", false);
              this.getData();
          })
          .catch((err) => {
              this.$store.dispatch("SET_TOOLBARLOAD", false);
              console.log(err);
              this.getData();
          })
    },
    getReason(reason) {
      switch (reason) {
        case 1:
          return "הפרת זכויות יוצרים";
        case 2:
          return "מקדם אלימות או שנאה";
        case 3:
          return "לא מתאים לאפליקציה";
        case 4:
          return "תוכן ספאם";
        default:
          return "לא ידוע";
      }
    },
  },
};
</script>
<style scoped>
.video-image {
  width: 200px;
  height: 120px;
}
</style>