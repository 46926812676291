<template>
    <v-container fluid>
        <v-row class="d-flex flex-wrap py-4" no-gutters>
            <v-col cols="12">
                <div v-if="conversation" class="d-flex align-center">
                     <v-list-item-avatar size="40" class="mt-0">
                        <v-img  :src="conversation.opener.avatar_url" class="grey lighten-2 justify-center"></v-img>
                    </v-list-item-avatar>
                    <div class="text-subtitle-1 font-weight-bold">
                        {{conversation.opener.name}}
                    </div>
                </div>
                <v-divider/>
                <div class="chat-container" ref="chatContainer" @scroll="onScroll">
                    <message-item
                    v-for="(item, index) in reverseMessages"
                    :key="index"
                    :val="item"
                    />
                </div>

                <v-divider class="mt-5" />
                <div class="mt-4 d-flex justify-end">
                    <v-btn  small rounded outlined @click="onPressReply">
                        <v-icon left>
                            mdi-reply
                        </v-icon>
                        תשובה   
                    </v-btn>
                </div>

                <v-textarea
                    v-if="showInput"
                    v-model="newMessage"
                    outlined
                    hide-details
                    label="כתוב הודעה"
                    single-line
                    class="mt-8"
                />
                <div class="mt-4 d-flex justify-end" v-if="showInput">
                    <v-btn
                        width="90"
                        rounded
                        color="red"
                        class="white--text px-4"
                        small
                        :loading="this.sending"
                        :disabled="!this.newMessage"
                        @click="sendMessage"
                    >
                    שליחה
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  components: {  },
    data() {
        return {
            channelId: this.$route.params.channel_id,
            conversationId: this.$route.params.contact_id,
            conversation: null,
            newMessage: null,
            messages: [],
            sending: false,
            showInput: false,
            loadingMore: false,
            canLoadMore: true,
            loadLimit: 30
        }
    },
    mounted() {
        this.initLoad();
    },
    computed: {
        reverseMessages() {
            return this.messages.slice().reverse();
        },
    },
    watch: {
        $route(to) {
            if (to.params.channel_id) {
                this.channelId = to.params.channel_id;
                // Need to redirect to contact list page
                this.$router.push({ path: `/channels/${this.channelId}/contact` });
            }
        },
    },
    methods: {
        initLoad() {
            this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            this.messages = [];
            this.loadingMore = false;
            this.canLoadMore = true;
            this.sending = false;
            this.getData();
        },
        getData() {
            this.newMessage = "";
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            this.loadingMore = true;
            api.getConversationMessages(this.channelId, this.conversationId, this.loadLimit, this.messages.length)
                .then((res) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    this.conversation = res.data.conversation;
                    const newMessages = res.data.messages.map((e) => {
                        return {
                            ...e,
                            isOpener: e.user_id === this.$store.getters.getUser.id,
                        };
                    });
                    this.messages = [].concat(this.messages, newMessages);
                    this.canLoadMore = (res.data.messages.length === this.loadLimit);
                })
                .catch((err) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    console.log(err);
                })
                .finally(() => {
                    this.loadingMore = false;
                    if (this.messages.length <= this.loadLimit) {
                        this.setAllSeen();
                        this.scrollToEnd();
                    }
                });
        },
        onScroll(e) {
            const { scrollTop } = e.target
            if (scrollTop === 0) {
                if (!this.loadingMore && this.canLoadMore) {
                    this.getData();
                }
            }
        },
        onPressReply() {
            this.showInput = true;
        },
        sendMessage() {
            this.sending = true;
            api.createConversationMessage(this.channelId, this.conversationId, {message: {body: this.newMessage}})
                .then((res) => {
                    this.newMessage = "";
                    this.sending = false;
                    let newMsg = {
                        ...res.data,
                        isOpener: res.data.user_id === this.$store.getters.getUser.id,
                        message_reads: []
                    };
                    this.messages.unshift(newMsg);
                    this.scrollToEnd();
                })
                .catch((err) => {
                    this.sending = false;
                    console.log(err);
                })
        },
        setAllSeen() {
            api.markAllSeenConversation(this.channelId, this.conversationId)
        },
        scrollToEnd() {
            this.$nextTick(() => {
                var container = this.$el.querySelector(".chat-container");
                container.scrollTop = container.scrollHeight;
            });
        },
    }
}

</script>

<style scoped lang="scss">
.chat-container {
    margin-top: 24px;
    max-height: 50vh;
    overflow-y: auto;
}
</style>
