export default {
    lengthInMinutes(durationInSeconds) {
      if (durationInSeconds < 3600) {
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds - minutes * 60;
    
        return ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);
      } else {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor(durationInSeconds / 60) - hours * 60;
        const seconds = durationInSeconds - minutes * 60 - hours * 3600;
    
        return ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);
      }
    },
    removeExtension(filename) {
      return filename.substring(0, filename.lastIndexOf('.')) || filename;
    },
    guessCountryCode(phone) {
      if (!phone || phone === "0") return "";
      if (phone.charAt(0) === "0") return "+972";
      return ""
    }
}