const Video = {
    state: {
        activeVideoId: null,
        tab: 0,
        publishedVideos: {
            he: [],
            en: [],
        },
        unPublishedVideos: {
            he: [],
            en: [],
        },
        totalPublished: { he: 0, en: 0 },
        totalUnpublished: { he: 0, en: 0 },
        publishedPage: { he: 1, en: 1 },
        unPublishedPage: { he: 1, en: 1 },
        activeSort: { name: "תאריך העלאה", key: "date" },
        selectedLang: 'he',
        processingVideos: [],
        processingVideoOptions: {
            itemsPerPage: 10,
            page: 1,
            sortBy: ["updated_at"],
            sortDesc: [true],
        },
        totalPocessingVideos: 0,
    },

    mutations: {
        setActiveVideoId: function (state, id) {
            state.activeVideoId = id
        },
        setLang: function (state, lang) {
            state.selectedLang = lang
        },
        setActiveSort: function (state, sort) {
            state.activeSort = sort
        },
        setTab: function (state, tab) {
            state.tab = tab
        },
        setPublishedVideoData: function (state, data) {
            state.publishedVideos = {
                ...state.publishedVideos,
                ...data.publishedVideos,
            };
            state.totalPublished = {
                ...state.totalPublished,
                ...data.totalPublished,
            };
            state.publishedPage = {
                ...state.publishedPage,
                ...data.publishedPage,
            };
        },
        setUnPublishedVideoData: function (state, data) {
            state.unPublishedVideos = {
                ...state.unPublishedVideos,
                ...data.unPublishedVideos,
            };
            state.totalUnpublished = {
                ...state.totalUnpublished,
                ...data.totalUnpublished,
            };
            state.unPublishedPage = {
                ...state.unPublishedPage,
                ...data.unPublishedPage,
            };
        },
        setProcessingVideoData: function (state, data) {
            state.processingVideos = data.videos;
            state.processingVideoOptions = data.options;
            state.totalPocessingVideos = data.total;
        },
        resetVideoData: function (state) {
            state.activeVideoId = null;
            state.tab = 0;
            state.publishedVideos = {
                he: [],
                en: [],
            };
            state.unPublishedVideos = {
                he: [],
                en: [],
            };
            state.totalPublished = { he: 0, en: 0 };
            state.totalUnpublished = { he: 0, en: 0 };
            state.publishedPage = { he: 1, en: 1 };
            state.unPublishedPage = { he: 1, en: 1 };
            state.activeSort = { name: "תאריך העלאה", key: "date" };
            state.processingVideos = [];
            state.processingVideoOptions = {
                itemsPerPage: 10,
                page: 1,
                sortBy: ["updated_at"],
                sortDesc: [true],
            };
            state.totalPocessingVideos = 0;
        }
    },

    getters: {
        getActiveVideoId: function (state) {
            return state.activeVideoId
        },

        getLastVideoData: function (state) {
            const copyState = Object.assign ({}, state);
            delete copyState.activeVideoId;
            return copyState;
        },

        getVideoProcessingData: function (state) {
            return {
                videos: state.processingVideos,
                options: state.processingVideoOptions,
                total: state.totalPocessingVideos,
            }
        }
    },

    actions: {
        SET_ACTIVE_VIDEO_ID: function (context, id) {
            context.commit('setActiveVideoId', id)
        },
        SET_LANGUAGE_VIDEO: function (context, lang) {
            context.commit('setLang', lang)
        },
        SET_ACTIVE_SORT: function (context, sort) {
            context.commit('setActiveSort', sort)
        },
        SET_TAB: function (context, tab) {
            context.commit('setTab', tab)
        },
        SET_PUBLISHED_VIDEO_DATA: function (context, data) {
            context.commit('setPublishedVideoData', data)
        },
        SET_UNPUBLISHED_VIDEO_DATA: function (context, data) {
            context.commit('setUnPublishedVideoData', data)
        },
        SET_PROCESSING_VIDEO_DATA: function (context, data) {
            context.commit('setProcessingVideoData', data)
        },
        RESET_VIDEO_DATA: function (context) {
            context.commit('resetVideoData');
        }
    }
}

export { Video as default }
