const Navigation = {
    state: {
        drawer: true,
        toolbarLoad: false,
    },

    mutations: {
        setDrawer: function(state, drawer) {
            state.drawer = drawer
        },
        toggleDrawer: function(state) {
            state.drawer = !state.drawer
        },
        setToolbarLoad: function(state, toolbarLoad) {
            state.toolbarLoad = toolbarLoad;
        }
    },

    getters: {
        getDrawer: function(state) {
            return state.drawer;
        },
        getToolbarLoad: function(state) {
            return state.toolbarLoad;
        }

    },

    actions: {
        SET_DRAWER: function (context, data) {
            context.commit('setDrawer', data)
        },
        TOGGLE_DRAWER: function(context) {
            context.commit('toggleDrawer')
        },
        SET_TOOLBARLOAD: function (context, data) {
            context.commit('setToolbarLoad', data)
        }
    }
}

export {Navigation as default}