<template>
  <v-container fluid>
    <add-video-view :add-text="`+ ${$t('new story')}`"  @onPressAdd="navigateAdd" />
    <v-row class="px-5 flex-column">
      <span class="text-subtitle-2 font-weight-bold ">{{
        this.stories.length > 0
          ? `${$t('your story')}`
          : `${$t('you have not upload any stories')}`
      }}</span>
      <v-row class="d-flex flex-wrap py-4" no-gutters>
        <v-col xl="8" lg="8" md="10" sm="12" xs="12" cols="12">
          <v-simple-table v-if="stories.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold">{{$t('story')}}</span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold"> {{$t('scheduled date')}} </span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold">{{$t('hour')}} </span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold">{{$t('upload time')}}</span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold">{{$t('description')}}</span>
                  </td>
                  <td class="text-center">
                    <span class="text-subtitle-2 font-weight-bold">{{$t('operations')}}</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="story in stories" :key="story.id">
                  <td>
                    <story-preview
                      :mediaType="story.media_type"
                      :media="story.media"
                      :titlePosition="story.title_position"
                      :title="story.title"
                      :backgroundColor="story.background_color"
                    />
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <span >
                        {{moment(story.publish_time).format("DD/MM/YYYY")}}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <span >
                        {{moment(story.publish_time).format("HH:mm")}}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <span>{{ timeDiffCalc(story.publish_time) }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <span>{{ story.description }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <v-btn icon color="pink" @click="onEdit(story)">
                        <v-icon>edit</v-icon>
                      </v-btn>
                      <v-btn icon color="pink" @click="onDelete(story)">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  methods: {
    navigateAdd() {
      this.$router.push({ path: `/channels/${this.channelId}/story/add` });
    },
    initLoad() {
      this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = { channel_id: this.channelId, show_future: true };
      api
        .getChannelStories(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.stories.length > 0) {
            this.stories = res.data.stories[0].stories;
          } else {
            this.stories = [];
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
    onDelete(story) {
      api
        .deleteChannelStory(story.id)
        .then((res) => {
          if (res.data) {
            this.initLoad();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onEdit(story) {
        this.$router.push({ path: `/channels/${this.channelId}/stories/${story.id}/edit` });
    },
    timeDiffCalc(publishDate) {
      if (!publishDate) return "";
      const isSchedule = (new Date(publishDate) - new Date()) / 1000;
      let diffInMilliSeconds =
        Math.abs(new Date(publishDate) - new Date()) / 1000;
      // calculate days
      const days = Math.floor(diffInMilliSeconds / 86400);
      diffInMilliSeconds -= days * 86400;

      // calculate hours
      const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
      diffInMilliSeconds -= hours * 3600;

      // calculate minutes
      const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
      diffInMilliSeconds -= minutes * 60;

      let difference = "";
      // if (days > 0) {
      //     difference += (days === 1) ? `${days} day, ` : `${days} days, `;
      // }
      // difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;
      // difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;
      if (days > 0) {
        difference +=
            isSchedule < 0 ? ` לפני ${days} ימים` : ` בעוד  ${days} ימים`;
      } else {
        if (hours < 1) {
          difference +=
            isSchedule < 0 ? `לפני ${minutes} דקות` : `בעוד  ${minutes} דקות`;
        } else {
          difference +=
            isSchedule < 0 ? ` לפני ${hours} שעות` : ` בעוד  ${hours} שעות`;
        }
      }
      return difference;
    },
  },
  data() {
    return {
      channelId: this.$route.params.channel_id,
      stories: [],
    };
  },
  mounted() {
    this.initLoad();
  },

  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.channelId = to.params.channel_id;
        this.initLoad();
      }
    },
  },
};
</script>

<style scoped>
</style>
