<template>
  <v-container fluid>
    <v-row class="d-flex justify-end pa-5">
      <v-btn color="#ff0000" class="white--text" tile width="200" @click="handleCreate">
        + {{$t('new playlist')}} 
      </v-btn>
    </v-row>
    <v-row class="pa-4 d-flex flex-column">
      <div class="d-flex search-box align-center pl-2 mb-2">
        <v-text-field
          v-model="searchKeyword" 
          v-on:keyup="searchKeydown"
          :label="`${$t('search')}`"
          hide-no-data 
          hide-details 
          dense
          flat
          solo
        />
        <v-btn icon  @click="handleSearch">
          <v-icon color="black">mdi-magnify</v-icon>
        </v-btn>
      </div>
      <v-row no-gutters class="justify-space-between">
        <v-col cols="auto" no-gutters>
          <v-btn text @click="toggleSelectPublished(true)">
            <span
              :class="`text-subtitle-2${selectPublished ? ' font-weight-bold' : ''}`">
              {{ $t('published content') }}
            </span>
            <span class="text-subtitle-2 mr-1"> ({{ list.length }} / {{ total }}) </span>
          </v-btn>
          <v-btn text @click="toggleSelectPublished(false)">
            <span
              :class="`text-subtitle-2${selectPublished ? '' : ' font-weight-bold'}`"
            >
              {{ $t('awaiting publication') }}
            </span>
            <span class="text-subtitle-2 red--text mr-1">
              ({{ draftList.length }} / {{ draftTotal }})
            </span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          class="d-flex align-center"
          xl="2"
          lg="2"
          md="3"
          sm="4"
          xs="12"
          cols="12">
          <v-select
            class="lang-select mx-2 my-2"
            v-model="selectedLang"
            :items="languages"
            item-text="name"
            item-value="val"
            single-line
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="auto" class="d-flex align-center ml-1">
          <span class="text-caption mx-2">  {{$t('sort by')}} :</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                tile
                class="pa-2"
                min-width="120"
                min-height="40"
                v-bind="attrs"
                v-on="on"
              >
                <span class="text-caption mx-2"> {{ activeSort.name }} </span>
                <v-icon class="mx-2">mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, index) in this.sortOptions"
                  :key="index"
                  @click="updateSortby(item)"
                >
                  <v-list-item-title class="subtitle-2">{{
                    item.name
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-wrap  py-4" no-gutters>
        <v-col
          xl="4"
          lg="4"
          md="6"
          sm="12"
          xs="12"
          cols="12"
          v-for="playlist in selectPublished ? list : draftList"
          :key="playlist.id"
        >
          <playlist-item
            :playlist="playlist"
            @addPlaylistToChannel="addPlaylistToChannel"
            @setStatus="changeStatus"
            @onClick="handleClick"
            @onPlay="handlePlay"
            @refresh="handleFetch(true)"
          />
        </v-col>
      </v-row>
      <v-row class="justify-center" v-if="showLoadMore">
        <v-btn :loading="isLoading" text color="red" @click="handleFetch(false)"> — {{$t('show more')}} — </v-btn>
      </v-row>
    </v-row>
    <v-snackbar v-model="showMessage" :timeout="2000" top>
      {{ message }}
    </v-snackbar>
    <add-to-channel v-if="openAddToChannel" :exceptChannelId="selectedPlaylist.channel_id" @onDone="handleAdd" />
    <v-dialog v-model="showVideoPlayer" width="800" class="player" @click:outside="closePlayer">
      <div style="position: relative;">
        <video
          v-if="currentVideo && currentVideo.video_url"
          :src="currentVideo.video_url"
          controls
          autoplay
          style="width: 100%; height: auto;"
        ></video>
        <div class="close-btn">
          <v-btn color="rgba(0,0,0,0.2)" @click="closePlayer">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <v-list style="max-height: 400px; overflow-y: auto;">
        <v-list-item-group>
          <v-list-item
            v-for="(item, index) in getPlaylistItems(selectedPlaylist)"
            :key="index"
            @click="currentVideo = item.video"
          >
            <v-list-item-icon large class="v-img__placeholder">
              <img :src="item && item.video && item.video.thumb_url || ''" height="56" width="86" class="thumbnails" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item && item.video && item.video.title }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-chip color="#00000055" class="mb-3 mr-1 white--text" small label>
                  {{ $helpers.lengthInMinutes(item && item.video && item.video.duration) }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-dialog>
  </v-container>
</template>

<script>
import { uniqBy } from 'lodash';
import AddToChannel from './AddToChannel.vue';
import api from '../../api/api';

export default {
  components: { AddToChannel },
  mounted() {
    this.selectedLang = localStorage.getItem('plang') || this.languages[0].val;
    this.initLoad();
  },
  watch: {
    $route(to) {
      this.channelId = to.params.channel_id;
      this.initLoad(true);
    },
    // '$store.getters.getPlaylists'() {
    //   this.list = this.$store.getters.getPlaylists.filter((o) => this.channelId ? o.channel_id === Number(this.channelId) : true);
    // },
    selectedLang: {
      handler: function(val) {
        this.initLoad(true);
        localStorage.setItem('plang', val);
      }
    }
  },
  computed: {
    showLoadMore() {
      return this.selectPublished ? this.list.length < this.total : this.draftList.length < this.draftTotal;
    },
  },
  methods: {
    async fetchPublishedPlaylist(reset = false) {
      this.isLoading = true;
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      if (reset) {
        this.offset = 0;
      }
      const params = {
        offset: this.offset,
        limit: this.limit,
        with_video: true,
        status: 'published',
        language: this.selectedLang,
        sort_by: this.activeSort.key
      }
      if (this.channelId) {
        params.channel_id = Number(this.channelId);
      }
      if (this.searchKeyword) {
        params.term = this.searchKeyword;
      }
      const res = await this.$store.dispatch('FETCH_ALL_PLAYLISTS', params);
      const results = reset ? res.playlists : [...this.list, ...res.playlists];
      this.list = uniqBy(results, ({ id }) => id);
      this.offset = this.list.length;
      this.total = res.total;

      this.isLoading = false;
      this.$store.dispatch("SET_TOOLBARLOAD", false);
    },
    async fetchDraftPlaylist(reset = false) {
      this.isLoading = true;
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      if (reset) {
        this.draftOffset = 0;
      }
      const params = {
        offset: this.draftOffset,
        limit: this.limit,
        with_video: true,
        status: 'draft',
        language: this.selectedLang,
        sort_by: this.activeSort.key
      }
      if (this.channelId) {
        params.channel_id = Number(this.channelId);
      }
      if (this.searchKeyword) {
        params.term = this.searchKeyword;
      }
      const res = await this.$store.dispatch('FETCH_ALL_PLAYLISTS', params);
      const results = reset ? res.playlists : [...this.draftList, ...res.playlists];
      this.draftList = uniqBy(results, ({ id }) => id);
      this.draftOffset = this.draftList.length;
      this.draftTotal = res.total;

      this.isLoading = false;
      this.$store.dispatch("SET_TOOLBARLOAD", false);
    },
    async initLoad(reset = false) {
      await this.fetchPublishedPlaylist(reset);
      await this.fetchDraftPlaylist(reset);
    },
    searchKeydown(e) {
      if (e.keyCode === 13) {
        this.handleSearch();
      }
    },
    handleSearch() {
      this.initLoad(true);
    },
    updateSortby(data) {
      this.activeSort = data;
      this.initLoad(true);
    },
    handleCreate() {
      // this.$store.dispatch('SET_SHOW_PLAYLIST_DIALOG', true);
      if (this.channelId) {
        this.$router.push({ path: `/channels/${this.channelId}/playlist/create` });
      } else {
        this.$router.push({ path: `/playlist/create` });
      }
    },
    handleClick(item) {
      this.$store.dispatch("SET_ACTIVE_PLAYLIST_ID", item.id);
      if (this.channelId) {
        this.$router.push({ path: `/channels/${this.channelId}/playlist/${item.id}` });
      } else {
        this.$router.push({ path: `/playlist/${item.id}` });
      }
    },
    handleFetch(reset) {
      if (this.selectPublished) {
        return this.fetchPublishedPlaylist(reset);
      }
      return this.fetchDraftPlaylist(reset);
    },
    otherItems(item) {
      let text = `+${item.channels.length - 1} other`;
      if (item.channels.length > 2) {
        text = `${text}s`;
      }
      return `(${text})`;
    },
    toggleSelectPublished(status) {
      this.selectPublished = status;
    },
    addPlaylistToChannel(playlist) {
      this.openAddToChannel = true;
      this.selectedPlaylist = playlist;
    },
    async handleAdd(channel) {
      if (channel) {
        try {
          await api.copyPlaylistToChannel({ channel_id: channel.id, playlist_id: this.selectedPlaylist.id })
          this.initLoad(true);
        } catch (e) {
          console.log(e);
        }
      }
      this.openAddToChannel = false;
      this.selectedPlaylist = {};
    },
    handlePlay(playlist) {
      if (playlist) {
        this.currentVideo = this.getPlaylistItems(playlist)[0]?.video;
        this.selectedPlaylist = playlist;
        this.showVideoPlayer = true;
      }
    },
    closePlayer() {
      this.showVideoPlayer = false;
      this.selectedPlaylist = {};
      this.currentVideo = null;
    },
    async changeStatus(playlist) {
      const params = {
        id: playlist.id,
        title: playlist.title,
        description: playlist.description,
        platform: playlist.platform,
        status: playlist.status === 'draft' ? 'published' : 'draft',
        external_id: playlist.external_id,
        channelIds: [playlist.channel_id],
      };
      try {
        await api.upsertPlaylist(params);
      } catch (e) {
        console.log(e);
      } finally {
        this.initLoad(true);
      }
    },
    getPlaylistItems(playlist) {
      return playlist?.playlist_items?.filter((o) => o.video) || [];
    }
  },

  data() {
    const languages = [
        {name: this.$t("hebrew"), val: "he"},
        {name: this.$t("english"), val: "en"},
    ];
    return {
      channelId: this.$route.params.channel_id,
      searchKeyword: null,
      list: [],
      draftList: [],
      message: "",
      showMessage: false,
      selectPublished: true,
      offset: 0,
      draftOffset: 0,
      limit: 24,
      isLoading: false,
      total: 0,
      draftTotal: 0,
      languages: languages,
      selectedLang: languages[0].val,
      sortOptions: [
        { name: this.$t("date"), key: "updated_at" },
        { name: this.$t("title"), key: "title" },
      ],
      activeSort: { name: this.$t("date"), key: "updated_at" },
      openAddToChannel: false,
      selectedPlaylist: {},
      showVideoPlayer: false,
      currentVideo: null,
    };
  },
};
</script>

<style scoped>
@media (max-width:600px) {
  .search-box {
    width: 100%;
    border: 1px solid black
  }
}

@media (min-width: 600px) and (max-width: 1264px) {
  .search-box {
    width: 40%;
    border: 1px solid black
  }
}

@media (min-width: 1264px) {
  .search-box {
    width: 30%;
    border: 1px solid black
  }
}

.playlist-icon-box {
  border: solid 1px #ddd;
  background-color: #fff;
  height: 80px;
  width: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
}
.playlist-icon-box:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.item-row {
  cursor: pointer;
}

.thumbnails {
  border-radius: 5px;
  background-color: #000;
}
.player {
  overflow-y: hidden;
}
.close-btn {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 10000;
}
.lang-select {
  border-radius: 0px;
  max-width: 250px;
}
</style>
