<template>
    <v-container fluid>
        <v-row class="d-flex flex-wrap py-4" no-gutters>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="data"
                    hide-default-footer
                    class="elevation-1"
                    :item-class="itemRowBackground"
                >
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                        small
                        rounded
                        @click="openContact(item)"
                        class="white--text"
                        color="#848484"
                        >
                        {{$t('open message')}}
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from "@/api/api";
import moment from "moment";

export default {
    data() {
        return {
            channelId: this.$route.params.channel_id,
            limit: 25,
            offset: 0,
            headers: [
                { text: `${this.$t('by')}`, value: 'opener_name', sortable: false },
                { text: `${this.$t('date')}`, value: 'date', sortable: false },
                { text: `${this.$t('hour')}`, value: 'hour', sortable: false },
                { text: `${this.$t('last message')}`, value: 'last_message', sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            data: [],
        }
    },
    watch: {
        $route(to) {
            if (to.params.channel_id) {
                this.channelId = to.params.channel_id;
                this.initLoad();
            }
        },
    },
    methods: {
        itemRowBackground (item) {
            return item.unread_messages_count === 0 ? 'read-row' : 'sent-row'
        },
        initLoad() {
            this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
            this.data = [];
            this.getData();
        },
        getData() {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            
            api.getChannelConversations(this.channelId, this.limit, this.offset)
                .then((res) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    this.data = res.data.conversations.map((e) => {
                        return {
                            ...e,
                            opener_name: e.opener.name,
                            last_message: e.last_message ? e.last_message.body : '',
                            date: moment(e.updated_at).format("DD/MM/YYYY"),
                            hour: moment(e.updated_at).format("HH:mm"),
                        }
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    console.log(err);
                })
        },
        openContact(item) {
            this.$router.push({ path: `/channels/${this.channelId}/contacts/${item.id}` });
        },
    },
    mounted() {
        this.initLoad();
    },
}

</script>

<style lang="scss">

.read-row {
    background-color: #EDFFEC;
}

.sent-row {
    background-color: #FFEEEE;
}

</style>
