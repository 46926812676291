
<template>
  <v-container fluid>
    <v-row class="px-5 flex-column">
      <v-col cols="12">
        <v-row align="center" class="pr-12 pt-2 pb-5">
          <v-col cols="12" sm="6" md="3" class="d-flex">
            <v-autocomplete v-model="selectedChannel" :items="items" :loading="isLoading" :search-input.sync="search"
              color="" clearable hide-no-data hide-selected item-text="name" item-value="id" dense :label="$t('search by name')"
              outlined :placeholder="$t('type to search')" return-object hide-details></v-autocomplete>
          </v-col>
<!-- 
          <v-spacer></v-spacer>
          <v-simple-checkbox
            v-model="showHasAdmin"
          ></v-simple-checkbox> -->
        </v-row>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <td class="row-pointer" @click="changeSort('title')">
                  <span class="text-subtitle-2 font-weight-bold">
                    {{$t('channel name')}}
                  </span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">Admin Name</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">Admin Email</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">Phone</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">Editors</span>
                </td>
                <td>
                  <span class="text-subtitle-2 font-weight-bold">Synch</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr :key="channel.id" v-for="channel in channels">
                <td>
                  <div class="d-flex justify-right">
                    {{ channel.name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ channel.admin_name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ channel.admin_email }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    <v-text-field
                      :value="channel.phone"
                      outlined
                      dense
                      hide-details
                      @input="updateAdminInfo(channel.id, 'phone_number', $event)"
                      class="smaller-height"
                    ></v-text-field>
                  </div>
                </td>
                <td>
                  <div class="text-truncate" style="max-width: 300px;">
                    {{ showEditors(channel.editors) }}
                  </div>
                </td>
                <td>
                   <div :class="['d-flex justify-right', { 'red--text': channel.yt_enabled }]">
                    {{ channel.yt_enabled ? 'ON' : 'OFF' }}
                   </div>
                  <!-- <div class="d-flex justify-right">
                    <v-switch
                      @change="toggleYtSetting(channel)"
                      v-model="channel.yt_enabled"
                    />
                  </div> -->
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="text-center mt-5">
      <v-pagination color="red" v-model="page" total-visible="6" @input="getData"
        :length="Math.ceil(total / limit)"></v-pagination>
    </div>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  data() {
    return {
      isLoading: false,
      selectedChannel: null,
      search: null,
      entries: [],
      nameLimit: 50,
      channels: null,
      limit: 20,
      total: 0,
      page: 1,
      debounceSearchTimeout: null,
      loading: false,
      debounceSaveTimeout: null,
      showHasAdmin: false,
    };
  },
  computed: {
    items() {
      return this.entries.map((entry) => {
        const name =
          entry.name.length > this.nameLimit
            ? entry.name.slice(0, this.nameLimit) + "..."
            : entry.name;

        return Object.assign({}, entry, { name });
      });
    },
  },
  watch: {
    search(val) {
      if (this.debounceSearchTimeout) {
        clearTimeout(this.debounceSearchTimeout);
        this.debounceSearchTimeout = null;
      }
      this.debounceSearchTimeout = setTimeout(() => {
        this.queryChannels(val);
      }, 1000);
    },
    selectedChannel: {
      handler: function () {
        this.getData();
      },
    },
    showHasAdmin: {
      handler: function () {
        this.getData();
      },
    },
  },
  mounted() {
    this.initLoad();
  },
  beforeRouteLeave (to, from, next) {
    if (to.path.startsWith('/channels/') && to.path.endsWith('/library')) {
      this.$store.dispatch("RESET_VIDEO_DATA");
    }
    next();
  },
  methods: {
    queryChannels(val) {
      if (this.isLoading) return;
      if (!val) {
        this.getData();
        return;
      }
      this.isLoading = true;
      this.page = 1
      api.searchChannels(val)
        .then((res) => {
          this.isLoading = false
          this.entries = res.data.items;
          if (res.data.items.length === 0) {
            this.channels = [];
            this.total = 0;
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error);
        });
    },
    initLoad() {
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        channel_id: this.selectedChannel?.id,
        has_admin: this.showHasAdmin ? true : undefined,
      };
      api
        .getRegisteredChannels(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.channels.length > 0) {
            this.channels = res.data.channels;
            this.total = res.data.total;
          } else {
            this.channels = [];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
    updateChannel(params, channel_id, showLoading = true) {
      if (showLoading) {
        this.$store.dispatch("SET_TOOLBARLOAD", true);
      }
      api
        .editChannel(channel_id, params)
        .then(() => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("SET_TOOLBARLOAD", false);
        });
    },
    toggleYtSetting(channel) {
      const params = {
        yt_enabled: channel.yt_enabled,
      };
      this.updateChannel(params, channel.id);
    },
    updateAdminInfo(channelId, field, newValue) {
      clearTimeout(this.debounceSaveTimeout);
      this.debounceSaveTimeout = setTimeout(() => {
        this.updateChannel({ [field]: newValue }, channelId, false);
      }, 500);
    },
    showEditors(users) {
      let editors = users.map((u) => u.name).join(", ");
      if (!editors) {
        editors = users.map((u) => u.email).join(", ");
      }
      return editors;
    },
  },
};
</script>
<style scoped>
.row-pointer {
  cursor: pointer;
}
</style>
<style>
.smaller-height .v-input__slot {
  min-height: 0 !important;
  padding: 0px 8px !important;
}
</style>
