var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-center preview-container",style:({ 'background-color': '#000000' }),attrs:{"no-gutters":""}},[(_vm.mediaType === 'image')?_c('img',{staticClass:"story-img",attrs:{"src":_vm.media,"alt":""}}):_vm._e(),(_vm.mediaType === 'video')?_c('video',{staticClass:"story-video",attrs:{"src":_vm.media,"preload":"metadata"}}):_vm._e(),_c('div',{staticClass:"story-title-container",style:({
        top:
          _vm.titlePosition === 'top'
            ? '20%'
            : _vm.titlePosition === 'middle'
            ? '50%'
            : '80%',
      })},[_c('span',{staticClass:"text-subtitle-2 font-weight-bold white--text",style:({ 'text-align': 'center' })},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),(!_vm.mediaType)?_c('div',{staticClass:"story-background",style:({ 'background-color': _vm.backgroundColor })}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }