import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"480","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"pa-8"},[_c(VCardTitle,{staticClass:"text-h6 font-weight-bold pa-0 mb-5"},[_vm._v(" "+_vm._s(_vm.$t("add to channel"))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c(VAutocomplete,{staticClass:"select-input",attrs:{"items":_vm.channels,"label":_vm.$t('channels'),"item-text":"name","item-value":"id","chips":"","small-chips":"","deletable-chips":"","outlined":"","dense":"","clearable":"","persistent-hint":"","return-object":"","search-input":_vm.search,"loading":_vm.isLoading,"placeholder":"Type to search more"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c(VCardActions,{staticClass:"d-flex justify-space-between px-0 mt-4"},[_c(VBtn,{staticClass:"white--text flex-grow-1",attrs:{"color":"#616161","tile":"","x-large":""},on:{"click":function($event){return _vm.handleAction(false)}}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("cancel")))])]),_c(VBtn,{staticClass:"white--text flex-grow-1",attrs:{"color":"#ff0000","tile":"","x-large":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.handleAction(true)}}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("add")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }