import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":""}},[_c('div',{staticClass:"d-flex justify-space-between flex-grow-1"},[_c('div',{staticClass:"d-flex"},[_c(VAppBarNavIcon,{on:{"click":_vm.toggleNavigationBar}}),_c('div',{staticClass:"d-none d-sm-flex"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-caption mx-2"},[_vm._v(_vm._s(_vm.$t('language'))+":")]),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"switch-btn",attrs:{"outlined":"","tile":"","min-width":"100"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"text-caption mx-2"},[_vm._v(" "+_vm._s(_vm.activeLanguage.name)+" ")]),_c(VIcon,{},[_vm._v("mdi-menu-down ")])],1)]}}])},[_c(VList,{staticClass:"overflow-y-auto",attrs:{"max-height":"300","min-width":"100"}},[_c(VListItemGroup,_vm._l((this.languages),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.onChangeLanguage(item.val)}}},[_c(VListItemTitle,{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1)],1)])],1),_c('div',{staticClass:"logo d-none d-md-flex"},[_c(VImg,{attrs:{"src":require("@/assets/logo.png"),"aspect-ratio":"1","width":"50","height":"50","contain":""}})],1),(this.$route.params.channel_id)?_c('div',{staticClass:"d-flex align-center justify-end pl-4"},[_c('span',{staticClass:"text-caption mx-2"},[_vm._v(_vm._s(_vm.$t('edit channel'))+":")]),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"switch-btn",attrs:{"outlined":"","tile":"","min-width":"120"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"text-caption mx-2"},[_vm._v(" "+_vm._s(_vm.activeChannelName)+" ")]),_c(VIcon,{staticClass:"mx-2"},[_vm._v("mdi-menu-down ")])],1)]}}],null,false,1764338339)},[_c(VList,{staticClass:"overflow-y-auto",attrs:{"max-height":"800","min-width":"300"}},[_c(VListItemGroup,_vm._l((this.$store.getters.getChannels),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.onChangeChannel(index)}}},[_c(VListItemAvatar,[_c(VImg,{staticClass:"bg-avatar",attrs:{"src":item.channel.image}})],1),_c(VListItemTitle,{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.channel.name))])],1)}),1),_c(VDivider),_c(VListItem,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"text":""}},[_vm._v(_vm._s(_vm.$t('closed')))])],1)],1)],1)],1):_vm._e()]),_c(VProgressLinear,{attrs:{"active":this.$store.getters.getToolbarLoad,"indeterminate":this.$store.getters.getToolbarLoad,"absolute":"","bottom":"","color":"red"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }