<template>
    <v-container fluid>
        <v-row>
            <v-col
            class="d-flex align-center"
            xl="2"
            lg="2"
            md="3"
            sm="4"
            xs="12"
            cols="12">
                <v-select
                    class="lang-select mx-2"
                    v-model="selectedLang"
                    :items="languages"
                    item-text="name"
                    item-value="val"
                    single-line
                    outlined
                    dense
                    hide-details
                />
            </v-col>
            <v-col
                xl="4"
                lg="4"
                md="6"
                sm="6"
                xs="12"
                cols="12">
                <div class="d-flex align-center">
                    <v-avatar >
                        <v-img v-if="categories && categoryImage" :src="categoryImage" ></v-img>
                    </v-avatar>
                    <v-select
                        class="category-select mx-2"
                        v-model="category_id"
                        :items="categories"
                        item-text="name"
                        item-value="id"
                        single-line
                        outlined
                        dense
                        hide-details
                    />
                </div>                
            </v-col>
        </v-row>
        <v-row class="d-flex flex-wrap py-4" no-gutters>
            <draggable
                v-model="videos"
                @end="onEnd"
                class="row wrap"
                :delayOnTouchOnly="true"
                :delay="500"
                :touchStartThreshold="5"
            >
                <v-col
                    xl="4"
                    lg="4"
                    md="6"
                    sm="6"
                    xs="12"
                    cols="12"
                    v-for="(video, index) in videos"
                    :key="video.id"
                >
                    <feed-video-item
                        :video="video"
                        :index="index"
                        @removeFeed="onRemoveVideoFromFeed"
                        @removePosition="onUnlockPosition"
                        @addPosition="onLockPosition"
                        @addVideoToSection="onShowVideoSectionDlg"
                    />
                    
                </v-col>
            </draggable>
        </v-row>
        <v-dialog
            v-model="showVideoSectionDlg"
            max-width="480"
        >
        <v-card class="pa-10">
            <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
            {{$t('add to session')}}
            </v-card-title>
            <div class="d-flex align-center">
            <span class="text-caption ml-4">{{$t('category')}}</span>
            <v-select
                class="category-select"
                v-model="videoPosition['category_id']"
                :items="categories"
                item-text="name"
                item-value="id"
                single-line
                outlined
                dense
                hide-details
            />
            </div>
            <div class="d-flex align-center mt-4">
            <span class="text-caption ml-3">{{$t('location')}}</span>
            <v-select
                class="category-select"
                v-model="videoPosition['position']"
                :items="positions"
                single-line
                outlined
                dense
                hide-details
            />
            </div>

            <v-card-actions class="d-flex justify-space-between px-0 mt-10">
            <v-btn
                class="white--text flex-grow-1"
                color="#616161"
                tile
                x-large
                @click="showVideoSectionDlg = false"
            >
                <span class="text-h5">{{$t('cancel')}}</span>
            </v-btn>
            <v-btn
                class="white--text flex-grow-1"
                color="#ff0000"
                tile
                x-large
                @click="onAddVideoToSection()"
            >
                <span class="text-h5">הוסף</span>
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import api from "@/api/api";
import draggable from "vuedraggable";

export default {
    data() {
        let languages = [
            {name: this.$t("hebrew"), val: "he"},
            {name: this.$t("english"), val: "en"},
        ];
        return {
            limit: 25,
            offset: 0,
            category_id: null,
            videos: [],
            res: [],
            selectedPost: null,
            categories: null,
            updatePosition: null,
            showVideoSectionDlg: false,
            selectedVideo: null,
            videoPosition: {},
            positions: [...Array(25).keys()].map(p => p + 1),
            languages: languages,
            selectedLang: languages[0].val,
        };
    },
    components: {
        draggable
    },
    mounted() {
        this.getCategories();
    },
    watch: {
        category_id: {
            handler: function () {
                this.getData();
            }
        },
        selectedLang: {
            handler: function () {
                this.getCategories();
            }
        }
    },
    computed: {
        categoryImage() {
            let categoryItem = this.categories.find(c => c.id === this.category_id);
            if (categoryItem) return categoryItem.image
            return null;
        }
    },
    methods: {
        getCategories() {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            this.categories = [];
            this.category_id = null;
            api.getCategories(this.selectedLang)
                .then((res) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    this.categories = res.data.categories;
                    if (this.categories.length > 0 && !this.category_id)
                        this.category_id = this.categories[0].id;
                })
                .catch((err) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    console.log(err);
                })
        },
        getData() {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            if (!this.category_id) {
                this.videos = [];
                return;
            }
            api.getVideoPositions(this.category_id, this.limit, this.offset, this.selectedLang)
                .then((res) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    this.videos = res.data.videos;
                })
                .catch((err) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    this.videos = [];
                    console.log(err);
                })
        },
        onEnd(evt) {
            if (evt.oldIndex === evt.newIndex) return;
            const currentVideo = this.videos[evt.newIndex];
            this.onUpdatePosition(currentVideo, evt.newIndex + 1);
        },
        onUpdatePosition(video, newPosition) {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            if (video.video_position) {
                let params = {
                    new_position: newPosition,
                };
                api.editVideoPosition(video.video_position.id, params)
                    .then(() => {
                        this.$store.dispatch("SET_TOOLBARLOAD", false);
                        this.getData();
                    })
                    .catch((err) => {
                        this.$store.dispatch("SET_TOOLBARLOAD", false);
                        console.log(err);
                        this.getData();
                    })
            } else {
                const data = {
                    video_id: video.id,
                    category_id: this.category_id,
                    position: newPosition
                };
                api.createVideoPosition(data)
                .then(() => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    this.getData();
                })
                .catch((err) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    console.log(err);
                    this.getData();
                })
            }
        },
        onLockPosition(video, newPosition) {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            const data = {
                video_id: video.id,
                category_id: this.category_id,
                position: newPosition
            };
            api.createVideoPosition(data)
            .then(() => {
                this.$store.dispatch("SET_TOOLBARLOAD", false);
                this.getData();
            })
            .catch((err) => {
                this.$store.dispatch("SET_TOOLBARLOAD", false);
                console.log(err);
                this.getData();
            })
        },
        onUnlockPosition(video) {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            api.deleteVideoPosition(video.video_position.id)
                .then(() => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    this.getData();
                })
                .catch((err) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    console.log(err);
                    this.getData();
                })
        },
        onRemoveVideoFromFeed(video) {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            api.deleteVideoFromFeed(video.id, this.category_id)
                .then(() => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    this.getData();
                })
                .catch((err) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    console.log(err);
                    this.getData();
                })
        },
        onShowVideoSectionDlg(video) {
            this.showVideoSectionDlg = true;
            this.selectedVideo = video;
        },
        onAddVideoToSection() {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            const data = {
                video_id: this.selectedVideo.id,
                category_id: this.videoPosition.category_id,
                position: this.videoPosition.position
            };
            api.createVideoPosition(data)
                .then(() => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    this.selectedVideo = null;
                    this.showVideoSectionDlg = false;
                    this.getData();
                })
                .catch((err) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    console.log(err);
                })
        }
    }
}
</script>

<style scoped>
    .category-select {
        border-radius: 0px;
        min-width: 200px;
        max-width: 250px;
        align-items: center;
    }
    .video-title {
        overflow: hidden;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .lang-select {
        border-radius: 0px;
        max-width: 250px;
    }
</style>