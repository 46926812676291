import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"width":"600","persistent":""}},[_c(VCard,{staticClass:"container"},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Video Picker")])]),_c(VCardText,[_c('div',{staticClass:"chip-box"},_vm._l((_vm.selected),function(item){return _c(VChip,{key:item.id,staticClass:"chip",attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.handleRemoveChip(item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1),_c(VTextField,{attrs:{"label":"Search video","clearable":"","loading":_vm.isLoading,"placeholder":"Type to search more"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"scrollable"},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.videos),function(video){return _c(VListItem,{key:video.id,attrs:{"avatar":"","three-line":""}},[_c(VListItemIcon,{staticClass:"v-img__placeholder",attrs:{"large":""}},[_c('img',{staticClass:"thumbnails",attrs:{"src":video.thumb_url,"height":"56","width":"86"}})]),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(video.channel.name))]),_c(VListItemTitle,[_vm._v(_vm._s(video.title))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.$helpers.lengthInMinutes(video.duration)))])],1),_c(VCheckbox,{attrs:{"value":video},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}),1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addVideos(false)}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.addVideos(true)}}},[_vm._v("Add")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }