<template>
    <v-container fluid >
        <v-row class="pa-1">
            <v-col xl=6 lg=8 md=10 sm=12 xs=12>
                <div class="d-flex py-2">
                    <v-form
                    class="main-row"
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                        <span class="text-subtitle-2 font-weight-bold">{{ $t("the title of the video") }}</span>
                        <div class="d-flex py-2">
                            <v-text-field
                            dense
                            class="input"
                            v-model="title"
                            :rules="titleRules"
                            label="מה יקרה באחרית הימים? פרק א׳"
                            single-line
                            outlined
                            required
                            />
                            <div class="mx-5 mark-img">
                                <v-icon v-if="this.title" size="30" color="black">mdi-check-bold</v-icon>
                            </div>
                        </div>
                       
                        <span v-if="isPendingSchedule" class="text-subtitle-2 font-weight-bold">{{ $t("posting time") }}</span>
                        <v-radio-group v-if="isPendingSchedule" v-model="postSchedule">
                            <div class="d-flex flex-column">
                                <div class="d-flex align-center">
                                <v-radio
                                    :key="1"
                                    :value="1"
                                    color="black"
                                    class="custom-radio"
                                />
                                <span class="text-caption font-weight-bold">עכשיו </span>
                                </div>
                                <div class="d-flex mr-8">
                                    <span class="text-caption">הוידאו יפורסם תוך מספר דקות בערוץ </span>
                                </div>
                            </div>
                            <div class="d-flex flex-column mt-5">
                                <div class="d-flex align-center">
                                <v-radio
                                    :key="2"
                                    :value="2"
                                    color="black"
                                    class="custom-radio"
                                />
                                <span class="text-caption font-weight-bold">תזמון </span>
                                </div>
                                <div class="d-flex mr-8">
                                    <span class="text-caption">קבע מתי יפורסם הוידאו בערוץ</span>
                                </div>
                            </div>
                        </v-radio-group>
                        <div v-if="isPendingSchedule && postSchedule === 2" class="d-flex py-2">
                            <v-menu
                                ref="dateMenu"
                                v-model="dateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateFormatted"
                                        class="date-input"
                                        append-icon="mdi-menu-down"
                                        persistent-hint
                                        dense
                                        outlined
                                        single-line
                                        v-bind="attrs"
                                        @blur="date = parseDate(dateFormatted)"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                v-model="date"
                                no-title
                                @input="dateMenu = false"
                                />
                            </v-menu>
                            <v-menu
                                ref="menu"
                                v-model="timeMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="time"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="time"
                                        class="date-input"
                                        append-icon="mdi-menu-down"
                                        readonly
                                        dense
                                        single-line
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <v-time-picker
                                v-if="timeMenu"
                                v-model="time"
                                full-width
                                @click:minute="$refs.menu.save(time)"
                                />
                            </v-menu>
                            <v-select
                                v-model="selectedTimezone"
                                :items="timezoneLits"
                                item-text="name"
                                item-value="zone"
                                label="בחר"
                                dense
                                outlined
                                single-line
                                return-object
                                class="timezone-input"
                            />
                        </div>

                        <div class="d-flex">
                            <v-btn tile color="#388E3C" width="140" class="white--text" @click="this.onUpdateVideo" :loading="this.loading" :disabled="this.loading || !this.uploadValid" >{{ $t("routine") }}!</v-btn>
                        </div>

                        <div v-if="isYoutube" class="text-subtitle-2 font-weight-bold mb-2 mt-8">{{ $t("if the duration is wrong") }}</div>
                        <div v-if="isYoutube">
                            <v-btn color="primary" outlined @click="handleFixVideo" :loading="fixLoading" :disabled="fixLoading">
                                <v-icon>mdi-auto-fix</v-icon>
                                <span class="mx-2">{{ $t("fix") }}</span>
                            </v-btn>
                        </div>
                    </v-form>
                    
                </div>
                
            </v-col>
        </v-row>
        <v-snackbar
            v-model="showResult"
            :timeout="2000"
            top>
            {{ result }}
        </v-snackbar>
    </v-container>
</template>

<script>
import api from '@/api/api'
import moment from 'moment';
import timezones from "../../config/timezone.js";

export default {
    data() {
        return {
            valid: true,
            fileName: '',
            title: '',
            isYoutube: false,
            titleRules: [
                v => !!v || 'Title is required',
            ],
            error: false,
            showResult: false,
            result: '',
            loading: false,
            fixLoading: false,
            uploaded: false,
            channelId: this.$route.params.channel_id,
            videoId: this.$route.params.video_id,
            timeMenu: false,
            dateMenu: false,
            time: null,
            date: moment(new Date()).format('YYYY-MM-DD'),
            dateFormatted: this.formatDate(moment(new Date()).format('YYYY-MM-DD')),
            isPendingSchedule: false,
            postSchedule: 1,
            timezoneLits: timezones,
            selectedTimezone: timezones[0],
        }
    },
    mounted() {
        this.initLoad();
    },
    computed: {
        uploadValid () {
            return this.title != "" && this.vailidateScheduledDate();
        }
    },
    
    watch: {
        $route(to) {
            this.$router.push({ path: `/channels/${to.params.channel_id}` });
        },
        date () {
            this.dateFormatted = this.formatDate(this.date)
        },
    },
    methods: {
        initLoad(){
            this.$store.dispatch('SET_ACTIVE_CHANNEL_ID', this.channelId)
            this.title = ''
            this.getVideo()
        },
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        vailidateScheduledDate () {
            if (!this.isPendingSchedule) return true;
            if (this.postSchedule === 1) return true;
            if (!this.date || !this.time) return false;
            if (new Date() > this.getScheduleDate()) return false
            return true;
        },
        getScheduleDate() {
            const strISO8061 =  moment.tz(`${this.date} ${this.time}`, this.selectedTimezone.zone).toISOString();
            return new Date(strISO8061);
        },
        validate() {
            if (!this.$store.getters.getActiveChannelId) return
        },
        getVideo() {
            this.$store.dispatch("SET_TOOLBARLOAD", true);
            api.getVideo(this.videoId)
                .then((res) => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    if (res.data) {
                        this.title = res.data.video.title
                        this.isYoutube = res.data.video.platform === 'youtube'
                        if (res.data.video.scheduled_at) {
                            this.postSchedule = 2;
                            this.date = moment(res.data.video.scheduled_at).tz(this.selectedTimezone.zone).format('YYYY-MM-DD');
                            this.time = moment(res.data.video.scheduled_at).tz(this.selectedTimezone.zone).format('HH:mm');
                        }
                        if (res.data.video.status === 'unpublished') {
                            this.isPendingSchedule = true
                        }
                    }
                })
                .catch(err => {
                    this.$store.dispatch("SET_TOOLBARLOAD", false);
                    console.log(err)
                })
        },
        onUpdateVideo() {
            this.loading = true
            const params = {
                'title': this.title
            }
            if (this.isPendingSchedule && this.postSchedule === 2) {
                params['scheduled_at'] = this.getScheduleDate().toISOString();
            }
            api.editVideo(this.videoId, params)
                .then(() => {
                    this.loading = false
                    this.result = "עודכן בהצלחה!"
                    this.showResult = true
                }).catch(err => {
                    console.log(err)
                    this.loading = false
                    this.result = "לא יכול לעדכן!"
                    this.showResult = true
                })
        },
        handleFixVideo() {
            this.fixLoading = true
            api.redownloadVideo(this.videoId)
                .then(() => {
                    this.fixLoading = false
                    this.result = "הוידאו שלך יתוקן בקרוב!"
                    this.showResult = true
                }).catch(err => {
                    console.log(err)
                    this.fixLoading = false
                    this.result = "לא יכול לתקן!"
                    this.showResult = true
                })
        },
    }
}
</script>

<style scoped>
    .test {
        border: 1px solid red;
    }
    .main-row {
        flex: 1
    }
    .input {
        border-radius: 0px;
        font-size: 12px;
    }
    .mark-img {
        width: 70px;
        justify-content: center;
        display: flex;
        align-items: flex-start;
    }
    .timezone-input {
        max-width: 200px;
        font-size: 12px;
    }
    .date-input {
        font-size: 12px;
        min-width: 100px;
        max-width: 150px;
        margin-left: 20px !important;
    }
</style>