<template>
    <div>
        <div v-if="val.isOpener" class="d-flex py-4 ">
            <div>
                <div class="opener-wrapper pa-4">
                    <span class="text-caption white--text">{{val.body}}</span>
                </div>
                <div class="d-flex mt-2">
                    <v-icon v-if="val.message_reads.findIndex((e) => e.user_id !== val.user_id) > -1" size="14" color="grey--text">mdi-check-all</v-icon>
                    <v-icon v-if="val.message_reads.findIndex((e) => e.user_id !== val.user_id) == -1" size="14" color="grey--text">mdi-check</v-icon>
                    <span class="text-caption grey--text">{{ moment(val.created_at).format("HH:mm") }}</span>
                </div>
            </div>
        </div>
        <div v-if="!val.isOpener" class="d-flex py-4 justify-end">
            <div>
                <div class="channel-wrapper pa-4 grey lighten-1">
                    <span class="text-caption">{{val.body}}</span>
                </div>
                <div class="d-flex mt-2">
                    <span class="text-caption grey--text">{{ moment(val.created_at).format("HH:mm") }}</span>
                </div>
            </div>
            <v-list-item-avatar size="35" class="mt-0">
                <v-img  :src="val.user.avatar_url" class="grey lighten-2 justify-center"></v-img>
            </v-list-item-avatar>
        </div>
    </div>
    
</template>

<script>
export default {
    props: {
        val: Object
    },
    data: () => ({
    }),
    mounted() {
       
    }, 
    computed: {
    },
    watch: {
    },
    methods: {
    }
    
}
</script>


<style scoped lang="scss">
    
    .opener-wrapper {
        background: #EE220C;
        border-radius: 10px;
    }
    .channel-wrapper {
        background: #EFEFEF;
        border-radius: 10px;
    }
</style>