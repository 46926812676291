<template>
  <v-container fluid>
    <v-row class="pa-2">
      <v-col lg="7" md="12">
        <div class="d-flex flex-column">
          <span class="subtitle-1 font-weight-bold">{{
            !isEdit ? $t("add the channel") : $t("edit the channel")
          }}</span>
          <div class="py-2">
            <v-avatar>
              <v-img :src="previewImgUrl" class="bg-avatar"> </v-img>
            </v-avatar>
            <input
              type="file"
              ref="file"
              accept="image/*"
              @change="selectFile"
              style="display: none"
            />
            <v-btn
              tile
              color="#616161"
              width="140"
              class="white--text mx-5"
              @click="$refs.file.click()"
              > {{$t("change picture")}}</v-btn
            >
          </div>
        </div>
        <v-form class="mt-4" ref="form" v-model="valid" lazy-validation>
          <span>{{$t("channel name")}}</span>
          <v-text-field
            dense
            class="input"
            v-model="channel.name"
            :rules="nameRules"
            :label="$t('new channel name')"
            single-line
            outlined
            required
          ></v-text-field>
          <div v-if="this.$store.getters.getUser.is_admin">
            <span>{{$t('email')}}</span>
            <v-text-field
              dense
              class="input"
              v-model="channel.email"
              :label="$t('email')"
              single-line
              outlined
            ></v-text-field>
          </div>
          <span>{{$t("Youtube channel link")}}</span>
          <v-text-field
            dense
            class="input"
            v-model="ytChannelLink"
            label="https://www.youtube.com/channel/x9fdjfidXs"
            single-line
            outlined
            :hint="channel.yt_id === '' ? '' : `${$t('Youtube channel code')}: ${channel.yt_id || 'Invalid URL!'}`"
            persistent-hint
          />
          <div class="mt-0" v-if="this.$store.getters.getUser.is_admin">
            <span>{{$t("auto pull videos?")}}</span>
            <v-switch
              dense
              color="success"
              inset
              v-model="channel.yt_enabled"
              :label="channel.yt_enabled ? $t('active') : $t('inactive')"
            ></v-switch>
          </div>
          <span> {{$t('website')}}</span>
          <v-text-field
            dense
            class="input"
            v-model="channel.website"
            single-line
            outlined
            required
          ></v-text-field>

          <span>{{$t("director")}}</span>
          <v-text-field
            dense
            class="input"
            v-model="channel.director"
            single-line
            outlined
          ></v-text-field>

          <span>{{$t("phone number")}}</span>
          <v-text-field
            dense
            class="input"
            v-model="channel.phone_number"
            single-line
            outlined
            required
          ></v-text-field>

          <span>{{$t("address")}}</span>
          <v-autocomplete
            v-model="channel.address"
            :loading="locationLoading"
            :items="locations"
            :search-input.sync="searchLocation"
            class="input"
            hide-no-data
            hide-details
            :label="$t('address example')"
            single-line
            outlined
            dense
          />
          <!-- <div v-if="this.$store.getters.getUser.is_admin">
            <span>להוסיף לחמים?</span>
            <v-switch
              dense
              color="success"
              inset
              v-model="channel.is_hot"
              :label="channel.is_hot ? 'פעיל' : 'כבוי'"
            ></v-switch>
          </div> -->
          <div class="mt-4" v-if="this.$store.getters.getUser.is_admin">
            <span>O & O</span>
            <v-switch
              dense
              color="success"
              inset
              v-model="channel.o_n_o"
              :label="channel.o_n_o ? $t('yes') : $t('no')"
            ></v-switch>
          </div>
          <div class="mt-4" v-if="this.$store.getters.getUser.is_admin">
            <span>{{$t("call activation")}}</span>
            <v-switch
              dense
              color="success"
              inset
              v-model="channel.conversation_enabled"
              :label="channel.conversation_enabled ? $t('active') : $t('inactive')"
            ></v-switch>
          </div>
          <div>
            <span>{{$t("biography")}}</span>
            <v-textarea
              class="input"
              outlined
              v-model="biography.bio"
              rows="4"
              :placeholder="$t('type the text here')"
            />
            <span>{{$t("books")}}</span>
            <v-textarea
              class="input"
              outlined
              v-model="biography.books"
              rows="2"
              :placeholder="$t('type the text here')"
            />
            <span>{{$t("personal life")}}</span>
            <v-textarea
              class="input"
              outlined
              v-model="biography.personal_life"
              rows="3"
              :placeholder="$t('type the text here')"
            />
            <span>{{$t("custom link")}}</span>
            <v-text-field
              dense
              class="input"
              v-model="channel.custom_link"
              single-line
              outlined              
            ></v-text-field>
            <p class="hint" v-if="custom_link_hint">{{ custom_link_hint }}</p>
            <a v-else-if="channel.custom_link" class="hint" style="color: #555" :href="`https://kolel.org/channel/${channel.custom_link}`" target="_blank" rel="noreferrer">
              https://kolel.org/channel/{{ channel.custom_link }}
            </a>
          </div>
        </v-form>
        <div class="d-flex justify-end mt-6">
          <v-btn
            tile
            color="#388E3C"
            width="140"
            class="white--text"
            @click="validate()"
            :loading="this.loading"
            :disabled="this.loading"
            >{{$t("save")}}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="showResult" :timeout="2000" top>
      {{ result }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "@/api/api";
import axios from "axios";

export default {
  data() {
    return {
      valid: true,
      channel: { yt_enabled: false, o_n_o: false, conversation_enabled: false },
      nameRules: [(v) => !!v || "Name is required"],
      error: false,
      showResult: false,
      result: "",
      loading: false,
      selectedFile: undefined,
      signedUrl: undefined,
      isEdit: false,
      biography: {
        bio: null,
        books: null,
        personal_life: null,
        photo_url: null
      },
      previewImgUrl: null,
      locationLoading: false,
      locations: [],
      debounce: null,
      searchLocation: null,
      custom_link_hint: '',
      ytChannelLink: '',
    };
  },

  mounted() {
    if (this.$route.params.channel_id) {
      this.isEdit = true
    }
    this.initLoad();
  },

  watch: {
    $route(to, from) {
      if (to != from) this.initLoad();
    },
    searchLocation(val) {
      if (val === this.channel.address) {
        return;
      }
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.queryLocations(val);
      }, 1000);
    },
    'channel.custom_link'() {
      this.custom_link_hint = '';
    },
    async ytChannelLink(val) {
      this.channel.yt_id = await this.extractYtId(val);
    }
  },
  methods: {
    initLoad() {
      const channelId = this.$route.params.channel_id;
      if (this.isEdit) {
        this.getData(channelId);
      }
    },

    selectFile() {
      this.selectedFile = this.$refs.file.files[0];
      this.previewImgUrl = URL.createObjectURL(this.selectedFile);
    },

    uploadFileToStorage() {
      this.loading = true;
      api.getImageStorageSignedUrl(this.channel.id || 0, this.selectedFile.type).then((res) => {
        axios
          .put(res.data.url, this.selectedFile)
          .then(() => {
            this.channel.image = res.data.url.split("?")[0];
            this.onEditChannel();
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      });
    },

    queryLocations(strLocation) {
      this.locationLoading = true;
      const params = { location: strLocation };
      api
        .getEventLocationSearch(params)
        .then((res) => {
          this.locationLoading = false;
          if (res.data && res.data.locations) {
            this.locations = res.data.locations;
          } else {
            this.locations = [];
          }
        })
        .catch((err) => {
          console.log(err);
          this.locations = [];
          this.locationLoading = false;
        });
    },

    getData(channelId) {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api
        .getChannelInfo(channelId)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data) {
            this.channel = res.data.channels;
            this.previewImgUrl = res.data.channels.image;
            if (res.data.channels.address) {
              this.locations = [res.data.channels.address]
            }
            if (res.data.channels.biography) {
              this.biography.bio = res.data.channels.biography.bio;
              this.biography.books = res.data.channels.biography.books;
              this.biography.personal_life = res.data.channels.biography.personal_life;
            }
            if (res.data.channels.yt_id) {
              this.ytChannelLink = `https://www.youtube.com/channel/${res.data.channels.yt_id}`;
            }
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },

    validate() {
      const vm = this;

      const valid = vm.$refs.form.validate();
      if (valid) {
        if (vm.selectedFile) {
          this.uploadFileToStorage();
        } else {
          this.onEditChannel();
        }
      }
    },

    async onEditChannel() {
      const vm = this;
      let params = { ...this.channel };
      params.biography = this.biography;
      vm.loading = true;

      const response = await api.checkCustomLink({
        custom_link: this.channel.custom_link || null,
        channel_id: this.isEdit === false ? undefined : vm.channel.id,
      });
      if (!response?.data?.is_available) {
        vm.loading = false;
        this.custom_link_hint = this.$t('custom link was chosen');
        return;
      }
      if (this.isEdit) {
        delete params.created_at
        delete params.updated_at
        delete params.id
        delete params.views
        delete params.videos_count
        delete params.followers
        delete params.notifications_counter
        delete params.og
        delete params.followers_count
        delete params.last_published
      }
      const operation =
        this.isEdit == false
          ? api.createChannel({channel: params})
          : api.editChannel(vm.channel.id, {channel: params});

      operation
        .then((res) => {
          vm.loading = false;
          this.result = this.isEdit ? "עודכן בהצלחה!" : "נוצר בהצלחה!";
          this.showResult = true;

          if (!this.isEdit){
            this.isEdit = true
            this.$router.push({
              path: `/channels/${res.data.channel.id}/edit`,
            });
          }
           
        })
        .catch((error) => {
          console.log(error);
          vm.error = true;
          vm.result = "לא יכול לעדכן!";
          vm.showResult = true;
          vm.loading = false;
        });
    },

    async extractYtId(url) {
      if (url === '') return '';
      try {
        if (url.includes('youtube.com/channel/')) {
          const supposedId = url.split('youtube.com/channel/')[1]?.split('?')[0]?.split('/')[0];
          if (!supposedId) return undefined;
          return supposedId;
          // const res = await axios.get(`https://www.googleapis.com/youtube/v3/search`, {
          //   params: {
          //     key: process.env.VUE_APP_YOUTUBE_KEY,
          //     channelId: supposedId,
          //   }
          // });
          // if (res?.data?.items?.length > 0) {
          //   return supposedId;
          // }
          // return undefined
        }
        const params = {
          key: process.env.VUE_APP_YOUTUBE_KEY,
        };
        if (url.includes('youtube.com/user/')) {
          params.forUsername = url.split('youtube.com/user/')[1]?.split('?')[0]?.split('/')[0];
        } else if (url.includes('youtube.com/c/')) {
          params.forHandle = url.split('youtube.com/c/')[1]?.split('?')[0]?.split('/')[0];
        } else if (url.includes('youtube.com/@')){
          params.forHandle = url.split('youtube.com/@')[1]?.split('?')[0]?.split('/')[0];
        } else {
          return undefined;
        }
        const response = await axios.get(`https://www.googleapis.com/youtube/v3/channels`, { params });
        const id = response?.data?.items?.find((item) => item.kind === 'youtube#channel')?.id;
        if (!id) {
          return undefined;
        }
        return id;
      } catch (error) {
        console.log(error);
        return undefined;
      }
    }
  },
};
</script>

<style scoped>
.input {
  border-radius: 0px;
  font-size: 12px;
}
.bg-avatar {
  background-color: darkgrey;
}
.hint {
  color: red;
  font-size: 12px;
  position: relative;
  top: -22px
}
</style>