<template>
    <div>
        <div v-if="val.user.isManager" class="d-flex py-4 ">
            <div>
                <div class="opener-wrapper pa-4">
                    <span class="text-caption white--text" v-html="val.message"></span>
                </div>
                <div class="d-flex mt-2">
                    <span class="text-caption grey--text">{{ moment(val.createdTime).format("HH:mm") }}</span>
                </div>
            </div>
        </div>
        <div v-else class="d-flex py-4 justify-end">
            <div>
                <div class="channel-wrapper pa-4 grey lighten-1">
                    <span class="text-caption" v-html="val.message"></span>
                </div>
                <div class="d-flex mt-2">
                    <span class="text-caption grey--text">{{ moment(val.createdTime).format("HH:mm") }}</span>
                </div>
            </div>
            <v-list-item-avatar size="35" class="mt-0">
                <v-img  :src="val.user.identity.thumbnailUrl" class="grey lighten-2 justify-center"></v-img>
            </v-list-item-avatar>
        </div>
    </div>
    
</template>

<script>
export default {
    props: {
        val: Object
    },
}
</script>

<style scoped lang="scss">
    .opener-wrapper {
        background: #EE220C;
        border-radius: 10px;
    }
    .channel-wrapper {
        background: #EFEFEF;
        border-radius: 10px;
    }
</style>
