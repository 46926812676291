import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/User'
import Channel from './modules/Channel'
import Navigation from './modules/Navigation'
import Video from './modules/Video'
import Playlist from './modules/Playlist'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        Channel: Channel,
        User: User,
        Navigation: Navigation,
        Video: Video,
        Playlist: Playlist,
    }
  })
