import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"pa-2"},[_c(VCol,{attrs:{"lg":"5","md":"5","sm":"5","xs":"12"}},[_c(VForm,{staticClass:"mt-4"},[_c('span',[_vm._v("גרסא")]),_c(VTextField,{staticClass:"input",attrs:{"dense":"","rules":_vm.nameRules,"label":"שם הגרסא","single-line":"","outlined":"","required":""},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}}),_c('div',[_c('span',[_vm._v("הצג עדכון?")]),_c(VSwitch,{staticClass:"mt-0",attrs:{"dense":"","color":"success","inset":"","label":_vm.force_update ? 'פעיל' : 'כבוי'},model:{value:(_vm.force_update),callback:function ($$v) {_vm.force_update=$$v},expression:"force_update"}})],1)],1),_c('div',{staticClass:"d-flex justify-end mt-6"},[_c(VBtn,{staticClass:"white--text",attrs:{"tile":"","color":"#388E3C","width":"140","loading":this.loading,"disabled":this.loading || !this.version},on:{"click":function($event){return _vm.addVersion()}}},[_vm._v("שמור")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }