
<template>
  <v-container fluid>    
    <v-row class="px-5 flex-column">
      <span
        v-if="subscriptions && !subscriptions.length"
        class="text-subtitle-2"
        >{{$t('no comments')}}</span
      >
      <v-col v-else xl="10" lg="10" md="12" sm="12" xs="12" cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('name')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('email')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('phone')}}</span>
                </td>
                <td class="text-right">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('gender')}}</span>
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('date')}} </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr :key="sub.id" v-for="sub in subscriptions">
                <td>
                  <div class="d-flex justify-right">
                    {{ sub.name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ sub.email }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ sub.phone }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right">
                    {{ sub.gender }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    {{ moment(sub.created_at).format("DD/MM/YYYY") }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-pagination
        color="red"
        v-model="page"
        total-visible="6"
        @input="getData"
        :length="Math.ceil(total / limit)"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  data() {
    return {
      subscriptions: null,
      total: 0,
      limit: 10,
      page: 1,
    };
  },
  watch: {},
  mounted() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
      };
      api
        .getHevruta(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.subscriptions.length > 0) {
            this.subscriptions = res.data.subscriptions;
            this.total = res.data.total;
          } else {
            this.subscriptions = [];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
  },
};
</script>